import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

export const UserReviewsDataList = ({ offset, limit, setOffset }) => {
  const { id: userId } = useParams();
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/users/${userId}/reviews?${stringify({
      offset,
      limit,
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching brands, please try again"
        onClose={revalidate}
      />
    );
  }

  if (!data?.length) {
    return (
      <div className="text-center my-8 block">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No reviews</h3>
        <p className="mt-1 text-sm text-gray-500">
          No reviews created by this user
        </p>
      </div>
    );
  }

  return (
    <div>
      <Table striped bordered hover>
        <tbody>
          {data.map((review) => (
            <tr
              key={review.id}
              onClick={() => history.push(`/reviews/${review.id}`)}
              className="cursor-pointer"
            >
              <td>{review.createdAt}</td>
              <td>{review.user?.name}</td>
              <td>{review.title}</td>
              <td>{review.body}</td>
              <td>{review.starRating}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </div>
  );
};
