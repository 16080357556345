import React, { useState } from 'react';
import './TypeAheadInput.css';

export const TypeAheadInput = ({
  name,
  placeholder,
  options,
  onSelectOption,
  selectedOptions,
}) => {
  const [query, setQuery] = useState('');

  const handleChangeText = ({ target: { value } }) => {
    setQuery(value);
  };

  const handleSelectOption = (option) => {
    onSelectOption(option);
    setQuery('');
  };

  const filteredOptions = query
    ? options
        .filter((option) => !selectedOptions.includes(option.value))
        .filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        )
        .slice(0, 3)
    : [];

  return (
    <div className="relative">
      <input
        type="text"
        name={name}
        value={query}
        onChange={handleChangeText}
        className="rounded-full border-0 bg-gray-light text-black w-full px-3 py-1.5 outline-none text-sm"
        placeholder={placeholder}
      />
      {filteredOptions.length ? (
        <div className="absolute left-0 shadow-md border border-gray-700 z-30 w-full bg-white rounded overflow-hidden typeahead-list">
          {filteredOptions.map((option) => (
            <div
              tabIndex="0"
              className="focus:bg-orange-br focus:text-white hover:text-white hover:bg-orange-br cursor-pointer p-2"
              onClick={() => handleSelectOption(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
