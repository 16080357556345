import React, { useState } from 'react';
import { useQuery } from '../../common/hooks/useQuery';

const FilterContext = React.createContext();

export const FilterProvider = ({ children }) => {
  const { get, set } = useQuery();

  let initialFilters = [];

  try {
    const base64Filters = get('f');
    if (base64Filters) {
      const filtersString = atob(base64Filters);
      initialFilters = JSON.parse(filtersString);
    }
  } catch (error) {
    console.error('Invalid Base64 or JSON in filters query string:', error);
    initialFilters = [];
  }

  const [filterBarOpen, setFilterBarOpen] = useState(get('fbo') === 'true');
  const [activeFilter, setActiveFilter] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const updateQueryString = (filters) => {
    const filtersString = JSON.stringify(filters);
    const base64Filters = btoa(filtersString);
    set('f', base64Filters);
  };

  const toggleFilterBar = () => {
    setFilterBarOpen((prevOpen) => {
      const newOpenState = !prevOpen;
      set('fbo', newOpenState);
      setActiveFilter(null);
      return newOpenState;
    });
  };

  const setFilters = (filters) => {
    setAppliedFilters(filters);
    updateQueryString(filters);
  };

  const removeFilter = (filterToRemove) => {
    setAppliedFilters((prevFilters) => {
      // get index of the filter to remove
      const index = prevFilters.findIndex(
        (filter) =>
          filter.value === filterToRemove.value &&
          filter.property === filterToRemove.property
      );

      const newFilters = prevFilters.filter((filter, i) => i !== index);
      updateQueryString(newFilters);
      return newFilters;
    });
  };

  const clearFilters = () => {
    setAppliedFilters([]);
    updateQueryString([]);
  };

  const value = {
    filterBarOpen,
    toggleFilterBar,
    activeFilter,
    setActiveFilter,
    appliedFilters,
    setFilters,
    removeFilter,
    clearFilters,
  };

  return (
    <FilterContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = React.useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};
