import React, { Suspense, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { GiveawaysDataList } from '../components/GiveawaysDataList';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { PageFrame } from '../../common/components/PageFrame';
import { AddButton } from '../../common/components/AddButton';
import { SearchBar } from '../../common/components/SearchBar';

export const GiveawaysListPage = () => {
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame
      title="Giveaways"
      topBarContent={
        <>
          <SearchBar placeholder="Search giveaways" onChange={setQuery} />
          <AddButton text="Add Giveaway" to="/giveaways/add" />
        </>
      }
    >
      <ListSearchAndLimit
        setLimit={setLimit}
        limit={limit}
        onSearch={setQuery}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <GiveawaysDataList offset={offset} limit={limit} query={query} />
      </Suspense>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </PageFrame>
  );
};
