import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function DataInput(props) {
  return (
    <Form.Group as={Row}>
      <Form.Label column sm="2">
        {props.label}
      </Form.Label>
      <Col sm="10">
        <Form.Control
          plaintext={props.plaintext}
          readOnly={props.readOnly}
          as={props.as}
          type={props.inputType}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          value={props.value}
          onKeyDown={blockEnter}
          onChange={props.onChange}
          autoComplete={props.autoComplete}
          rows={props.rows}
          max={props.max}
          min={props.min}
          {...props}
        />
        {props.msg && <div style={{ color: '#ff0000' }}>{props.msg}</div>}
      </Col>
    </Form.Group>
  );
  function blockEnter(e) {
    if (props.as === 'textarea') return;
    var key = e.charCode || e.keyCode || 0;
    if (key === 13) {
      e.preventDefault();
    }
  }
}
DataInput.defaultProps = {
  placeholder: '',
  as: 'input',
  inputType: 'text',
  plaintext: null,
  readOnly: null,
};
