import { client } from '../../common/api/client';
import { formValuesToUpdateReviewDto } from './reviews.dto';

export const updateReview = (id, values) => {
  const updateReviewDto = formValuesToUpdateReviewDto(values);
  return client.patch(`/portal/reviews/${id}`, updateReviewDto);
};

export const deleteReview = (id) => {
  return client.delete(`/portal/reviews/${id}`);
};
