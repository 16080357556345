import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { formatFormAddress } from '../../places/api/sites.dto';

export const brandsFormValuesToCreateBrandDto = async (formValues) => {
  const [address] = await geocodeByAddress(
    formValues.address.value.description
  );
  const location = await getLatLng(address);
  return {
    location: {
      lat: location.lat.toString(),
      lng: location.lng.toString(),
    },
    address: address.formatted_address,
    name: formValues.name,
    email: formValues.email,
    bio: formValues.bio,
    foundedYear: formValues.foundedYear,
    founder: formValues.founder,
    phoneNumber: formValues.phoneNumber,
    url: formValues.url,
    imageUrl: formValues.imageUrl,
    companyId: formValues.company.id,
  };
};

export const brandsApiResponseToFormValues = async (brand) => {
  const address = await formatFormAddress(
    brand.address.replace(/\r?\n|\r/g, ' ')
  );
  return {
    ...brand,
    address,
  };
};
