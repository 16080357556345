import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export const ConfirmationModal = ({
  title,
  body,
  onConfirm,
  onCancel,
  loading,
  confirmLabel = 'Confirm',
}) => {
  return (
    <div
      className="Modal h-full w-full fixed top-0 left-0 flex justify-center items-center"
      role="document"
    >
      <div className="rounded-3xl max-w-md bg-white px-6 py-4 space-y-6">
        <div className="relative flex items-center justify-center">
          <p className="font-semibold text-lg mt-2 mb-0">{title}</p>
          <button
            type="button"
            className="absolute -top-2 right-0 text-md"
            onClick={onCancel}
          >
            <span aria-hidden="true" className="text-3xl">
              &times;
            </span>
          </button>
        </div>
        <div className="px-8 text-center">
          <p>{body}</p>
        </div>
        <div className="flex justify-center items-center">
          <button
            type="button"
            className="underline block text-black text-xs mr-3"
            onClick={onCancel}
          >
            Cancel
          </button>
          <Button
            variant="primary"
            type="button"
            className="border-orange-br bg-orange-br"
            onClick={onConfirm}
          >
            {loading ? <Spinner animation="border" size="sm" /> : confirmLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
