import Form from 'react-bootstrap/Form';
import { Field, useFormikContext } from 'formik';
import DataInput from '../../common/components/DataInput';
import React from 'react';
import { DataSearch } from '../../common/components/DataSearch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountrySelector from '../../news/components/CountrySelector';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ReactDatePicker from 'react-datepicker';
import ImageSelector from '../../common/components/ImageSelector';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';

export const validateForm = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'You must provide a title';
  }

  if (!values.body) {
    errors.body = 'You must provide a body';
  }

  if (!values.startDate) {
    errors.startDate = 'You must provide a start date';
  }

  if (!values.endDate) {
    errors.endDate = 'You must provide a end date';
  }

  return errors;
};

export const GiveawayForm = ({ onDelete }) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } =
    useFormikContext();
  const history = useHistory();
  const { data: countries } = useSWR('/countries', fetcher, {
    suspense: true,
  });

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      <Field name="title">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Title"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="createdBy">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Created by"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="startDate">
        {({ field, meta }) => {
          const { name, onChange, ...rest } = field;
          return (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Start Date
                </Form.Label>
                <Col sm="10">
                  <ReactDatePicker
                    className="rounded-full border border-gray-300 text-black w-full px-3 py-2 text-sm"
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={(val) => {
                      setFieldValue(field.name, val);
                    }}
                    wrapperClassName="w-full"
                    showYearDropdown
                    {...rest}
                  />
                </Col>
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3 ml-3">
                    {meta.error}
                  </div>
                )}
              </Form.Group>
            </>
          );
        }}
      </Field>

      <Field name="endDate">
        {({ field, meta }) => {
          const { name, onChange, ...rest } = field;
          return (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  End Date
                </Form.Label>
                <Col sm="10">
                  <ReactDatePicker
                    className="rounded-full border border-gray-300 text-black w-full px-3 py-2 text-sm"
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={(val) => {
                      setFieldValue(field.name, val);
                    }}
                    wrapperClassName="w-full"
                    showYearDropdown
                    {...rest}
                  />
                </Col>
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3 ml-3">
                    {meta.error}
                  </div>
                )}
              </Form.Group>
            </>
          );
        }}
      </Field>

      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Thumbnail"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>

      <Field name="introduction">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Introduction"
                as="textarea"
                placeholder=""
                row={10}
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="body">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Body"
                as="textarea"
                placeholder=""
                row={30}
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="countryIds">
        {({ field }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Select countries:
              </Form.Label>
              <Col sm="10">
                <CountrySelector
                  options={countries.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={field.value}
                  onChange={(countryIds) => {
                    setFieldValue('countryIds', countryIds);
                  }}
                />
              </Col>
            </Form.Group>
          );
        }}
      </Field>

      <Field name="productIds">
        {({ field }) => (
          <DataSearch
            label="Linked products"
            url="/portal/products"
            {...field}
            onChange={(productIds) => setFieldValue('productIds', productIds)}
          />
        )}
      </Field>

      {values.createdAt && (
        <Field name="createdAt">
          {({ field }) => {
            return (
              <DataInput
                id="name"
                label="Created At"
                defaultValue={field.value}
                plaintext
                readonly
              />
            );
          }}
        </Field>
      )}

      <Button variant="primary" type="submit" className="mr-3 bg-orange-br border-orange-br">
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 bg-orange-br border-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button variant="primary" type="button" className="bg-orange-br border-orange-br" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Form>
  );
};
