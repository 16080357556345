import axios from 'axios';
import { appConfig } from '../app.config';
import { getFirebaseApp } from '../../../lib/firebaseApp';
import { getAuth } from 'firebase/auth';

export const client = axios.create({
  baseURL: appConfig.api.endpoint,
});

client.interceptors.request.use(
  async (config) => {
    const auth = getAuth(getFirebaseApp());
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
