import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { getFirebaseApp } from './lib/firebaseApp';
import Routes from './Routes';
import { FirebaseAppProvider } from 'reactfire';
import { ImgixProvider } from 'react-imgix';
import { appConfig } from './modules/common/app.config';
import { ErrorBoundary } from './modules/common/components/ErrorBoundary';
import { Firebase } from './Firebase';
import 'typeface-montserrat';
import '@fontsource/signika/500.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import './index.css';
import './custom.scss';
import { SkeletonAppFrame } from './modules/common/components/SkeletonAppFrame';

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseApp={getFirebaseApp()}>
      <Firebase>
        <ImgixProvider domain={appConfig.imgix.domain}>
          <ErrorBoundary
            key={window.location.href}
            fallback={
              <p>
                Something went wrong... please refresh the page and try again
              </p>
            }
          >
            <Suspense fallback={<SkeletonAppFrame />}>
              <Routes />
            </Suspense>
          </ErrorBoundary>
        </ImgixProvider>
      </Firebase>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
