import styled from 'styled-components';
import { ReactComponent as CompanyIcon } from '../FilterBar/company.svg';
import { ReactComponent as CountryIcon } from '../FilterBar/country.svg';
import { ReactComponent as TypeIcon } from '../FilterBar/type.svg';
import { ReactComponent as ProductIcon } from '../FilterBar/product.svg';
import { ReactComponent as MenuIcon } from '../FilterBar/menu.svg';
import { ReactComponent as Close } from './close.svg';

const filterTypeToIcon = {
  company: CompanyIcon,
  country: CountryIcon,
  type: TypeIcon,
  product: ProductIcon,
  menu: MenuIcon,
};

const Container = styled.div`
  height: 32px;
  background: #fdf2ec;
  border-radius: 15px;
  padding: 8px 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ed6921;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > svg {
    stroke: #ed6921;
    margin-right: 3px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  background: #ed6921;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    stroke: #ffffff;
    position: relative;
    z-index: 1;
  }
`;

export const AppliedFilterChip = ({ filter, removeFilter }) => {
  const Icon = filterTypeToIcon[filter.property];
  return (
    <Container key={`${filter.property}-${filter.value}`}>
      {Icon && <Icon />}
      {filter.label}
      <CloseButton type="button" onClick={() => removeFilter(filter)}>
        <Close />
      </CloseButton>
    </Container>
  );
};
