import { AddressFinder } from './AddressFinder';
import { AddressForm } from './AddressForm';
import { CompanyForm } from './CompanyForm';
import { PlaceTypesForm } from './PlaceTypesForm';
import { Overview } from './Overview';
import { Reminder } from './Reminder';
import { createPlaceV2, updatePlaceV2 } from '../../api/sites.api';

export const FormStepsMap = {
  ADDRESS_FINDER: 'addressFinder',
  ADDRESS: 'address',
  COMPANY: 'company',
  PLACE_TYPES: 'placeTypes',
  OVERVIEW: 'overview',
  REMINDER: 'reminder',
};

export const editFormSteps = [
  {
    id: FormStepsMap.ADDRESS,
    stepTitle: 'Edit Place',
    backButtonText: 'Cancel',
    nextButtonText: 'Next',
    component: AddressForm,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.COMPANY,
    stepTitle: 'Edit Place',
    backButtonText: 'Back to details',
    nextButtonText: 'Next',
    component: CompanyForm,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.PLACE_TYPES,
    stepTitle: 'Edit Place',
    backButtonText: 'Back to drinks menu',
    nextButtonText: 'Next',
    component: PlaceTypesForm,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.OVERVIEW,
    stepTitle: 'Overview',
    backButtonText: 'Back to types',
    nextButtonText: 'Save Changes',
    component: Overview,
    handleSubmit: async ({
      placeId,
      setError,
      values,
      onClose,
      setSavedPlace,
      goToNextStep,
    }) => {
      try {
        setError(null);
        const place = await updatePlaceV2(placeId, values);
        setSavedPlace(place);
        if (!values.drinksMenu.id) {
          goToNextStep();
        } else {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        setError('Something went wrong, please try again later.');
        console.log(error);
      }
    },
  },
  {
    id: FormStepsMap.REMINDER,
    stepTitle: 'Reminder',
    nextButtonText: 'Add Products',
    component: Reminder,
    handleSubmit: async ({ savedPlace, history }) => {
      history.push(`/drinks-menus/edit/${savedPlace.drinksMenu.id}`);
    },
  },
];
export const addFormSteps = [
  {
    id: FormStepsMap.ADDRESS_FINDER,
    stepTitle: 'Add Place',
    nextButtonText: 'Next',
    component: AddressFinder,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.ADDRESS,
    stepTitle: 'Add Place',
    backButtonText: 'Back to address finder',
    nextButtonText: 'Next',
    component: AddressForm,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.COMPANY,
    stepTitle: 'Add Place',
    backButtonText: 'Back to details',
    nextButtonText: 'Next',
    component: CompanyForm,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.PLACE_TYPES,
    stepTitle: 'Add Place',
    backButtonText: 'Back to drinks menu',
    nextButtonText: 'Next',
    component: PlaceTypesForm,
    handleSubmit: async ({ goToNextStep }) => {
      goToNextStep();
    },
  },
  {
    id: FormStepsMap.OVERVIEW,
    stepTitle: 'Overview',
    backButtonText: 'Back to types',
    nextButtonText: 'Add Place',
    component: Overview,
    handleSubmit: async ({
      setError,
      values,
      onClose,
      setSavedPlace,
      goToNextStep,
    }) => {
      try {
        setError(null);
        const place = await createPlaceV2(values);
        setSavedPlace(place);
        if (!values.drinksMenu.id) {
          goToNextStep();
        } else {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        setError('Something went wrong, please try again later.');
        console.log(error);
      }
    },
  },
  {
    id: FormStepsMap.REMINDER,
    stepTitle: 'Reminder',
    nextButtonText: 'Add Products',
    component: Reminder,
    handleSubmit: async ({ savedPlace, history }) => {
      history.push(`/drinks-menus/edit/${savedPlace.drinksMenu.id}`);
    },
  },
];
