import styled from 'styled-components';
import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../common/api/fetcher';

const Container = styled.div`
  width: 224px;
  background: #ffffff;
  border: 1px solid #ecedee;
  box-shadow: 0.2px 0.2px 2px rgba(69, 74, 92, 0.15);
  border-radius: 5px;
  overflow: hidden;
`;

const ResultsList = styled.ul`
  width: 100%;
  height: 100%;
  padding: 0;
  background: #ffffff;
  border-radius: 5px;
  margin: 0;
`;

const ResultItem = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #454a5c;
  padding: 0;
  width: 100%;

  &:hover {
    background: #fdf2ec;
  }
`;

const Checkbox = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: ${({ isChecked }) =>
    isChecked ? '#ED6921' : 'transparent'};
  border: 1px solid #ed6921;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  font-size: 8px;
  margin-right: 6px;
  flex-shrink: 0;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    transform: rotate(45deg);
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  }
`;

const ResultTruncated = styled.span`
  display: inline-block;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResultLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  padding: 8px 10px;

  input {
    margin-right: 8px;
  }
`;

const NoResultsItem = styled(ResultItem)`
  color: #a4a6af;
  text-align: center;
  padding: 8px 0;

  &:hover {
    background: transparent;
    cursor: default;
  }
`;

export const ListSelectFilter = ({
  appliedFilters,
  onChange,
  property,
  url,
  noResultsText = 'No results found',
}) => {
  const { data: listResults, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const handleCheckboxChange = (filter) => {
    const index = appliedFilters.findIndex(
      (item) => item.value === filter.value && item.property === filter.property
    );

    if (index === -1) {
      onChange([...appliedFilters, filter]);
    } else {
      onChange(appliedFilters.filter((_, i) => i !== index));
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const hasResponse = Boolean(listResults);
  const hasResults = hasResponse && listResults.length > 0;

  return (
    <Container>
      {hasResponse && (
        <ResultsList>
          {hasResults ? (
            listResults.map((result) => {
              const isChecked = appliedFilters.some(
                (filter) =>
                  filter.value === result.id && filter.property === property
              );

              return (
                <ResultItem key={result.id}>
                  <ResultLabel>
                    <Checkbox isChecked={isChecked} />
                    <input
                      type="checkbox"
                      style={{ display: 'none' }}
                      checked={isChecked}
                      onChange={() =>
                        handleCheckboxChange({
                          value: result.id,
                          label: result.name,
                          property,
                        })
                      }
                    />
                    <ResultTruncated>{result.name}</ResultTruncated>
                  </ResultLabel>
                </ResultItem>
              );
            })
          ) : (
            <NoResultsItem>{noResultsText}</NoResultsItem>
          )}
        </ResultsList>
      )}
    </Container>
  );
};
