import { Dialog } from '@headlessui/react';
import { noop } from 'lodash/util';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from './close.svg';
import { deletePlace } from '../../api/sites.api';
import { useState } from 'react';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 64px;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 417px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(69, 74, 92, 0.25);
  border-radius: 10px;
  padding: 36px;
  position: relative;
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #454a5c;
  display: block;
  margin-bottom: 24px;
`;

const Description = styled.span`
  max-width: 225px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #454a5c;
  display: block;
  margin-bottom: 6px;
  text-align: center;
`;

const Warning = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #7d808d;
  margin-bottom: 24px;
  display: block;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8.5px 32px;
  gap: 8px;
  width: 113px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;

  &:hover {
    background: #f7f8f9;
  }

  ${({ primary }) =>
    primary &&
    css`
      background: #e44e47;
      border: 1px solid #e44e47;
      color: #ffffff;

      &:hover {
        background: rgba(228, 78, 71, 0.85);
      }
    `}
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 24px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: #f7f8f9;
  }
`;

const ErrorText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #e44e47;
  margin-top: -16px;
  margin-bottom: 24px;
  display: block;
`;

export const DeletePlaceModal = ({ isOpen, onClose, place }) => {
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    try {
      setError(null);
      await deletePlace(place.id);
      onClose();
      // refresh the page
      window.location.reload();
    } catch (error) {
      setError('Something went wrong, please try again later');
    }
  };

  return (
    <Dialog as="div" open={isOpen} onClose={noop}>
      <Overlay aria-hidden="true" />
      <Wrapper>
        <Container>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <Content>
            <Dialog.Title as="div">
              <Title>Delete Place?</Title>
            </Dialog.Title>
            <Description>
              Are you sure you want to delete {place.name}?
            </Description>
            <Warning>You can’t undo this action.</Warning>
            {error && <ErrorText>{error}</ErrorText>}
            <ButtonContainer>
              <Button onClick={onClose}>Cancel</Button>
              <Button primary onClick={handleDelete}>
                Delete
              </Button>
            </ButtonContainer>
          </Content>
        </Container>
      </Wrapper>
    </Dialog>
  );
};
