export const addressFinderInitialState = {
  addressFinderState: null,
  searchTerm: '',
  searchResults: [],
};

export function addressFinderReducer(state, action) {
  switch (action.type) {
    case 'setAddressFinderState':
      return { ...state, addressFinderState: action.payload };
    case 'setSearchTerm':
      return { ...state, searchTerm: action.payload };
    case 'setSearchResults':
      return { ...state, searchResults: action.payload };
    case 'reset':
      return addressFinderInitialState;
    default:
      throw new Error();
  }
}
