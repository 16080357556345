import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 10px 8.5px 8px;
  height: 34px;
  background: #ed6921;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(237, 105, 33, 0.85);
    color: white;
    text-decoration: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 2px;
`;

const Icon = styled.div``;

const ButtonText = styled.span`
  height: 17px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 2px;
  // text should always be on a single line
  white-space: nowrap;
`;

export const PrimaryButton = ({ text, onClick, icon: IconSvg }) => {
  return (
    <ButtonContainer onClick={onClick}>
      {IconSvg && (
        <IconContainer>
          <Icon as={IconSvg} />
        </IconContainer>
      )}
      <ButtonText>{text}</ButtonText>
    </ButtonContainer>
  );
};
