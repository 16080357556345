import { Field, useFormikContext } from 'formik';
import DataInput from '../../common/components/DataInput';
import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DataSelect from '../../common/components/DataSelect';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ImageSelector from '../../common/components/ImageSelector';
import { DataSearch } from '../../common/components/DataSearch';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../common/context/userContext';
import Spinner from 'react-bootstrap/Spinner';
import inRange from 'lodash/inRange';
import { ProductsDrinksMenuList } from './ProductsDrinksMenuList';
import ComboBox from '../../common/components/ComboBox';
import { ConfirmationModal } from '../../common/components/ConfirmationModal';

const draughtTypes = ['Beer', 'Cider', 'Alternative Brews'];

export const validateForm = (values) => {
  const errors = {};

  if (!values.company?.id) {
    errors.company = 'You must select a company';
  }

  if (!values.name) {
    errors.name = 'You must provide a name';
  }

  if (!values.brandId) {
    errors.brandId = 'You must select a brand';
  }

  if (!values.productTypeId) {
    errors.productTypeId = 'You must select a product type';
  }

  if (!values.countryId) {
    errors.countryId = 'You must select a country';
  }

  if (!values.abv) {
    errors.abv = 'You must provide an ABV';
  } else if (!inRange(values.abv, 0.51)) {
    errors.abv = 'You must provide an ABV between 0% and 0.5%';
  }

  if (!values.servingMl) {
    errors.servingMl = 'You must provide a serving size';
  }

  if (!values.description) {
    errors.description = 'You must provide a description';
  }

  return errors;
};

export const ProductForm = ({ loading, onArchive }) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } =
    useFormikContext();
  const { isAdmin } = useContext(UserContext);
  const [companyQuery, setCompanyQuery] = useState('');
  const { data: productTypeVariants } = useSWR(
    values.productTypeId
      ? `/product-type-variants?productTypeId=${values.productTypeId}`
      : null,
    fetcher
  );
  const { data: companies } = useSWR(
    isAdmin ? `/portal/companies?query=${companyQuery}` : null,
    fetcher
  );
  const { data: productTypes } = useSWR('/product-types', fetcher);
  const { data: brands } = useSWR(
    isAdmin
      ? values.company?.id
        ? `/portal/companies/${values.company?.id}/brands`
        : null
      : '/portal/brands?limit=100',
    fetcher
  );
  const { data: dietTypes } = useSWR('/diet-types', fetcher);
  const { data: countries } = useSWR('/countries', fetcher);
  const { data: foodTypes } = useSWR('/food-types', fetcher);
  const history = useHistory();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      {isAdmin && (
        <Field name="company">
          {({ field, meta }) => (
            <>
              <ComboBox
                {...field}
                id={field.name}
                label="Company"
                options={companies}
                query={companyQuery}
                onQueryChange={setCompanyQuery}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}
      <Field name="name">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Name"
                placeholder="Product name"
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="brandId">
        {({ field, meta }) => (
          <>
            <DataSelect
              id={field.name}
              label="Brand"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a brand' },
                ...(brands || [])?.map((brand) => ({
                  value: brand.id,
                  label: brand.name,
                })),
              ]}
              {...field}
            />
            {meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>
      <Field name="productTypeId">
        {({ field, meta }) => (
          <>
            <DataSelect
              id={field.name}
              label="Product type"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a product type' },
                ...(productTypes || [])?.map((productType) => ({
                  value: productType.id,
                  label: productType.name,
                })),
              ]}
              {...field}
            />
            {meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>

      {draughtTypes.includes(
        productTypes?.find(
          (productType) => productType.id === values.productTypeId
        )?.name
      ) && (
        <Field name="availableOnDraught">
          {({ field, meta }) => (
            <DataSelect
              id={field.name}
              label="Available on Draught"
              type="select"
              options={[
                { label: 'No', value: false, Type: 'boolean' },
                { label: 'Yes', value: true, Type: 'boolean' },
              ]}
              {...field}
            />
          )}
        </Field>
      )}

      {!!productTypeVariants?.length && (
        <Field name="productTypeVariants" type="checkbox">
          {({ field, meta }) => {
            return (
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Taste and style
                </Form.Label>
                <Col sm="10">
                  <ToggleButtonGroup
                    style={{ flexWrap: 'wrap' }}
                    type="checkbox"
                    name={field.name}
                    value={field.value}
                    onChange={(values) => {
                      setFieldValue(field.name, values);
                    }}
                  >
                    {productTypeVariants?.map((productTypeVariant) => (
                      <ToggleButton
                        key={productTypeVariant.id}
                        variant="outline-secondary"
                        value={productTypeVariant.id}
                      >
                        {productTypeVariant.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  {meta.error && (
                    <div className="text-red-500 text-xs mt-2 mb-3">
                      {meta.error}
                    </div>
                  )}
                </Col>
              </Form.Group>
            );
          }}
        </Field>
      )}

      <Field name="suitableFor" type="checkbox">
        {({ field, meta }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Suitable for
              </Form.Label>
              <Col sm="10">
                <ToggleButtonGroup
                  style={{ flexWrap: 'wrap' }}
                  type="checkbox"
                  name={field.name}
                  value={field.value}
                  onChange={(values) => {
                    setFieldValue(field.name, values);
                  }}
                >
                  {dietTypes?.map((dietType) => (
                    <ToggleButton
                      key={dietType.id}
                      variant="outline-secondary"
                      value={dietType.id}
                    >
                      {dietType.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3">
                    {meta.error}
                  </div>
                )}
              </Col>
            </Form.Group>
          );
        }}
      </Field>

      <Field name="countryId">
        {({ field, meta }) => (
          <>
            <DataSelect
              id={field.name}
              label="Country"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a country' },
                ...(countries || [])?.map((country) => ({
                  value: country.id,
                  label: country.name,
                })),
              ]}
              {...field}
            />
            {meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>

      <Field name="description">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Description"
                as="textarea"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="caloriesPerServing">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Calories per 100ml"
                placeholder="100"
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="servingMl">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Product size in ml"
                placeholder="300"
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="abv">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="ABV %"
                placeholder="0"
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Image"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>

      <Field name="foodPairings" type="checkbox">
        {({ field, meta }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Food pairing
              </Form.Label>
              <Col sm="10">
                <ToggleButtonGroup
                  className="flex-wrap"
                  type="checkbox"
                  name={field.name}
                  value={field.value}
                  onChange={(values) => {
                    setFieldValue(field.name, values);
                  }}
                >
                  {foodTypes?.map((foodType) => (
                    <ToggleButton
                      key={foodType.id}
                      variant="outline-secondary"
                      value={foodType.id}
                    >
                      {foodType.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3">
                    {meta.error}
                  </div>
                )}
              </Col>
            </Form.Group>
          );
        }}
      </Field>

      {isAdmin && (
        <Field name="productPairings">
          {({ field }) => (
            <DataSearch
              label="Pairs with"
              url="/portal/products"
              {...field}
              onChange={(productIds) =>
                setFieldValue('productPairings', productIds)
              }
            />
          )}
        </Field>
      )}

      <Field name="ingredients">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Ingredients"
                as="textarea"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="awards">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Awards"
                as="textarea"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="other">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Other"
                as="textarea"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="suggestedStockists">
        {({ field }) => (
          <DataSearch
            label="Online Stockists"
            url="/portal/products/stockists"
            {...field}
            onChange={(productIds) =>
              setFieldValue('suggestedStockists', productIds)
            }
          />
        )}
      </Field>

      {isAdmin && (
        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Drinks Menus
          </Form.Label>
          <Col sm="10">
            <ProductsDrinksMenuList />
          </Col>
        </Form.Group>
      )}

      <div className="flex justify-between items-center">
        <div>
          <Button
            variant="primary"
            type="submit"
            className="mr-3 border-orange-br bg-orange-br"
          >
            {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={history.goBack}
            className="marginR border-orange-br bg-orange-br"
          >
            Cancel
          </Button>
        </div>

        {isAdmin && onArchive && (
          <>
            <Button
              variant="primary"
              type="button"
              className="mr-3 border-orange-br bg-orange-br"
              onClick={() => {
                setShowArchiveModal(true);
              }}
            >
              Archive
            </Button>
            {showArchiveModal && (
              <ConfirmationModal
                title="Archive Product"
                body={
                  <p>
                    Are you sure you want to archive {'\n'}
                    <strong className="block truncate">{values.name}?</strong>
                  </p>
                }
                onConfirm={async () => {
                  setIsArchiving(true);
                  try {
                    await onArchive();
                  } catch (e) {
                    setIsArchiving(false);
                  } finally {
                    setShowArchiveModal(false);
                  }
                }}
                onCancel={() => {
                  setShowArchiveModal(false);
                }}
                confirmLabel={'Archive'}
                loading={isArchiving}
              />
            )}
          </>
        )}
      </div>
    </Form>
  );
};
