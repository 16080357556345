import React from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function DataSelect({
  value,
  id,
  label,
  type,
  name,
  options,
  variant,
  onChange,
  readOnly,
}) {
  return (
    <Form.Group as={Row} controlId={id}>
      <Form.Label column sm="2">
        {label}
      </Form.Label>
      <Col sm="10" style={{ ...(readOnly ? { pointerEvents: 'none' } : {}) }}>
        {type === 'select' ? (
          <Form.Control
            readOnly={readOnly}
            as="select"
            value={value}
            name={name}
            onChange={({ target }) =>
              onChange({ target: { id, value: target.value } })
            }
          >
            {options.map(({ value: optionValue, label }) => (
              <option value={optionValue} key={optionValue}>
                {label}
              </option>
            ))}
          </Form.Control>
        ) : (
          <ToggleButtonGroup
            style={{ flexWrap: 'wrap' }}
            type={type}
            value={value}
            name={name}
            onChange={() => onChange({ target: { id, value } })}
          >
            {options.map(({ value: optionValue, label }) => (
              <ToggleButton
                variant={variant}
                key={optionValue}
                value={optionValue}
              >
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </Col>
    </Form.Group>
  );
}

DataSelect.defaultProps = {
  options: [],
  type: 'checkbox', // checkbox or radio
  variant: 'outline-secondary',
  value: '',
  valueProperty: 'value',
  labelProperty: 'label',
  limitToList: true,
  name: 'checkboxes',
  onChange: null,
};
