import axios from 'axios';
import { client } from './client';

export const getSignedUrl = async (fileName) => {
  const response = await client.post('/portal/files/signed-url', { fileName });
  return response.data;
};

export const uploadFile = async (signedUrl, file) => {
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': 'image/jpeg',
      'x-amz-acl': 'public-read',
    },
  });
  return true;
};
