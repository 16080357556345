import { useAuth } from 'reactfire';
import { signOut } from 'firebase/auth';

export const AccessDenied = ({ onLogout }) => {
  const auth = useAuth();

  const handleLogout = async () => {
    await signOut(auth);
    window.location.reload();
  };

  return (
    <div className="mt-64 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-sm font-semibold text-defaultBlue uppercase tracking-wide">
            Access Denied
          </p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Sorry, you're not allowed to access this.
          </h1>
          <p className="mt-2 text-base text-gray-500">
            Please log out and try again with another account.
          </p>
          <div className="mt-6">
            <button
              onClick={handleLogout}
              className="text-base font-medium text-indigo-600 hover:text-indigo-500"
            >
              Log out<span aria-hidden="true"> &rarr;</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};
