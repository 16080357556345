import { useState } from 'react';
import { ReactComponent as Chevron } from './chevron.svg';
import styled, { css } from 'styled-components';
import * as Styles from './Sidebar.styles';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

export const ChevronWrapper = styled(Styles.IconWrapper).attrs({
  as: 'div',
})`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: #454a5c;
  margin-left: auto;
  margin-right: 14px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

  ${Styles.MenuLink}.active & {
    stroke: #ed6921;
  }

  ${Styles.MenuLink}:hover & {
    stroke: #ed6921;
  }
`;

export const IconWrapper = styled(Styles.IconWrapper)`
  ${Styles.MenuLink}.active & {
    stroke: #ed6921;
  }

  ${Styles.MenuLink}:hover & {
    stroke: #ed6921;
  }
`;

export const DropdownLink = styled.div`
  margin: 0 0 8px;
  border-radius: 5px;
  height: 32px;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 33px;
  color: #454a5c;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #fdf2ec;
    color: #ed6921;
    text-decoration: none;

    ${ChevronWrapper} {
      stroke: #ed6921;
    }

    ${Styles.IconWrapper} {
      stroke: #ed6921;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #fdf2ec;
      color: #ed6921;

      ${ChevronWrapper} {
        stroke: #ed6921;
      }

      ${Styles.IconWrapper} {
        stroke: #ed6921;
      }
    `}
`;

export const DropdownListItemLink = styled(NavLink)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: block;

  color: #454a5c;

  &:hover {
    color: #ed6921;
    text-decoration: none;
  }

  &.active {
    color: #ed6921;
  }
`;

export const DropdownList = styled.ul`
  list-style: none;
  margin-left: 40px;
  margin-bottom: 8px;
  padding: 4px 0 4px 16px;
  position: relative;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  &:before {
    height: 100%;
    width: 1px;
    background-color: #ed6921;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const DropdownListItem = styled.li`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DropdownMenuItem = ({ items, icon: Icon, label }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const folderIsActive = items.some((link) => link.isActive(match, location));
  const dropdownOpen = isOpen || folderIsActive;

  return (
    <div>
      <DropdownLink isActive={folderIsActive} onClick={toggleDropdown}>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        {label}
        <ChevronWrapper isOpen={dropdownOpen}>
          <Chevron />
        </ChevronWrapper>
      </DropdownLink>
      <DropdownList isOpen={dropdownOpen}>
        {items.map((item) => (
          <DropdownListItem key={item.path}>
            <DropdownListItemLink to={item.path} isActive={item.isActive}>
              {item.name}
            </DropdownListItemLink>
          </DropdownListItem>
        ))}
      </DropdownList>
    </div>
  );
};
