import React, { Suspense, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { NewsDataList } from '../components/NewsDataList';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { PageFrame } from '../../common/components/PageFrame';
import { AddButton } from '../../common/components/AddButton';
import { SearchBar } from '../../common/components/SearchBar';

export const NewsListPage = () => {
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame
      title="News"
      topBarContent={
        <>
          <SearchBar placeholder="Search news" onChange={setQuery} />
          <AddButton text="Add News" to="/news/add" />
        </>
      }
    >
      <ListSearchAndLimit
        setLimit={setLimit}
        limit={limit}
        onSearch={setQuery}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <NewsDataList offset={offset} limit={limit} query={query} />
      </Suspense>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </PageFrame>
  );
};
