import React from 'react';
import { Label } from '../../../components/forms/Label';
import DrinkPreferenceSelector from './DrinkPreferenceSelector';

export const PushNotificationsDrinkPreferenceSelector = ({
  options,
  value,
  onChange,
}) => {
  if (!value)
    throw new Error(
      'No array value provided to PushNotificationsDrinkPreferenceSelector'
    );

  return (
    <div className="my-4">
      <Label>By drink preference:</Label>
      <DrinkPreferenceSelector
        options={options}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
