import styled from 'styled-components';
import { useFilter } from '../../context/filterContext';
import { AppliedFilterChip } from './AppliedFilterChip';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  padding: 10px 34px;
  overflow-x: scroll;

  border-bottom: 1px solid #d9dbdd;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ClearAllButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0 8px;
  cursor: pointer;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #454a5c;
  white-space: nowrap;
`;

export const AppliedFiltersBar = () => {
  const { appliedFilters, removeFilter, clearFilters } = useFilter();

  return (
    <Container>
      {appliedFilters.map((filter) => (
        <AppliedFilterChip
          key={`${filter.property}-${filter.value}`}
          filter={filter}
          removeFilter={removeFilter}
        />
      ))}
      {appliedFilters.length > 1 && (
        <ClearAllButton onClick={clearFilters} type="button">
          Clear all
        </ClearAllButton>
      )}
    </Container>
  );
};
