import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';
import React from 'react';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from 'react-router-dom';
import { NoResults } from '../../common/components/NoResults';

export const UsersDataList = ({ offset, limit, query }) => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/users?${stringify({
      offset,
      limit,
      ...(query !== '' && { query }),
    })}`,
    fetcher
  );

  if (!data) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching users, please try again"
        onClose={revalidate}
      />
    );
  }

  if (data?.length === 0) {
    return <NoResults />;
  }

  return (
    <Table striped bordered hover>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="cursor-pointer">
            <td onClick={() => history.push(`/users/edit/${item.id}`)}>
              {item.name}
            </td>
            <td onClick={() => history.push(`/users/edit/${item.id}`)}>
              {item.email}
            </td>
            <td>
              <Button
                as={Link}
                variant="primary"
                type="button"
                className="border-orange-br bg-orange-br"
                to={`/reviews/user/${item.id}`}
              >
                Reviews
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
