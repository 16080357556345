export const Cross = ({ size = 64, ...props }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  );
};
