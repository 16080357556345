import { Field, useFormikContext } from 'formik';
import DataInput from '../../common/components/DataInput';
import Form from 'react-bootstrap/Form';
import React from 'react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { fetcher } from '../../common/api/fetcher';
import DataSelect from '../../common/components/DataSelect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NewsProductTypeSelector } from './NewsProductTypeSelector';
import { DataSearch } from '../../common/components/DataSearch';
import CountrySelector from './CountrySelector';
import ImageSelector from '../../common/components/ImageSelector';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export const validateForm = (values) => {
  const errors = {};
  return errors;
};

export const NewsForm = ({ onDelete }) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormikContext();
  const history = useHistory();

  const { data: newsCategories } = useSWR('/portal/news/categories', fetcher, {
    suspense: true,
  });
  const { data: productTypes } = useSWR('/product-types', fetcher, {
    suspense: true,
  });
  const { data: countries } = useSWR('/countries', fetcher, {
    suspense: true,
  });

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      <Field name="title">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Title"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="categoryId">
        {({ field, meta }) => (
          <>
            <DataSelect
              id={field.name}
              label="Category"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a category' },
                ...(newsCategories || [])?.map((category) => ({
                  value: category.id,
                  label: category.name,
                })),
              ]}
              {...field}
            />
            {meta.touched && meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>
      <Field name="createdBy">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Created by"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="url">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Website"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="productIds">
        {({ field }) => (
          <DataSearch
            label="Linked products"
            url="/portal/products"
            {...field}
            onChange={(productIds) => setFieldValue('productIds', productIds)}
          />
        )}
      </Field>
      <Field name="productTypeIds">
        {({ field }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Drink type:
              </Form.Label>
              <Col sm="10">
                <NewsProductTypeSelector
                  options={productTypes.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  value={field.value}
                  onChange={(drinkTypes) => {
                    setFieldValue('productTypeIds', drinkTypes);
                  }}
                />
              </Col>
            </Form.Group>
          );
        }}
      </Field>
      <Field name="countryIds">
        {({ field }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Select countries:
              </Form.Label>
              <Col sm="10">
                <CountrySelector
                  options={countries.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={field.value}
                  onChange={(countryIds) => {
                    setFieldValue('countryIds', countryIds);
                  }}
                />
              </Col>
            </Form.Group>
          );
        }}
      </Field>
      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Thumbnail"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>
      <Field name="introduction">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Introduction"
                as="textarea"
                placeholder=""
                row={10}
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="body">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Body"
                as="textarea"
                placeholder=""
                row={30}
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      {values.createdAt && (
        <Field name="createdAt">
          {({ field }) => {
            return (
              <DataInput
                id="name"
                label="Created At"
                defaultValue={field.value}
                plaintext
                readonly
              />
            );
          }}
        </Field>
      )}

      <Button variant="primary" type="submit" className="mr-3 border-orange-br bg-orange-br">
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button variant="primary" type="button" className="border-orange-br bg-orange-br" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Form>
  );
};
