import React, { Suspense, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { DrinksMenuForm, validateForm } from '../components/DrinksMenuForm';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { createDrinksMenu } from '../api/drinksMenu.api';
import { mutate } from 'swr';
import { UserContext } from '../../common/context/userContext';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

export const AddDrinksMenuPage = () => {
  const history = useHistory();
  const [error, setError] = useState(noError);
  const { companyId } = useContext(UserContext);

  const handleSubmit = async (values) => {
    try {
      await createDrinksMenu(values);
      await mutate('/portal/drinks-menus');
      history.push(`/drinks-menus/list`);
    } catch (e) {
      console.log(e);
      setError({
        title: 'An error occurred saving this drinks menu',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Drinks Menu">
      <Formik
        initialValues={{ companyId, products: {} }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <DrinksMenuForm />
        </Suspense>
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
