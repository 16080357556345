import { PrimaryButton } from './PrimaryButton';
import React from 'react';
import { ReactComponent as Plus } from '../svg/plus.svg';
import { useHistory } from 'react-router-dom';

export const AddButton = ({ to, text }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(to);
  };

  return <PrimaryButton text={text} onClick={onClick} icon={Plus} />;
};
