import { client } from '../../common/api/client';
import { formValuesToCreateProductDto } from './products.dto';

export const createProduct = (values) => {
  const createProductDto = formValuesToCreateProductDto(values);
  return client.post('/portal/products', createProductDto);
};

export const updateProduct = (id, values) => {
  const updateProductDto = formValuesToCreateProductDto(values);
  return client.patch(`/portal/products/${id}`, updateProductDto);
};

export const archiveProduct = (id) => {
  return client.patch(`/portal/products/${id}/archive`);
};
