import { Field, Form as FormikForm, useFormikContext } from 'formik';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import DataInput from '../../common/components/DataInput';
import DataSelect from '../../common/components/DataSelect';
import ImageSelector from '../../common/components/ImageSelector';
import { DataSearch } from '../../common/components/DataSearch';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ReactDatePicker from 'react-datepicker';
import Spinner from 'react-bootstrap/Spinner';
import ComboBox from '../../common/components/ComboBox';

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const validateForm = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'You must provide a name';
  }

  if (!values.email) {
    errors.email = 'You must provide an email';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.countryId) {
    errors.countryId = 'You must select a country';
  }

  return errors;
};

export const UserForm = ({ withPassword, onDelete }) => {
  const { setFieldValue, isSubmitting } = useFormikContext();
  const history = useHistory();
  const [companyQuery, setCompanyQuery] = useState('');

  const { data: companies } = useSWR(
    `/portal/companies?query=${companyQuery}`,
    fetcher,
    {
      suspense: false,
    }
  );
  const { data: productTypes } = useSWR('/product-types', fetcher, {
    suspense: true,
  });
  const { data: countries } = useSWR('/countries', fetcher, { suspense: true });
  const { data: roles } = useSWR('/portal/users/roles', fetcher, {
    suspense: true,
  });

  return (
    <FormikForm>
      <Field name="name">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Name"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="email">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Email"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      {withPassword && (
        <Field name="password">
          {({ field, meta }) => {
            return (
              <>
                <DataInput
                  id={field.name}
                  label="Password"
                  placeholder=""
                  inputType="password"
                  {...field}
                />
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3">
                    {meta.error}
                  </div>
                )}
              </>
            );
          }}
        </Field>
      )}
      <Field name="company">
        {({ field, meta }) => (
          <>
            <ComboBox
              {...field}
              id={field.name}
              label="Company"
              options={companies}
              query={companyQuery}
              onQueryChange={setCompanyQuery}
            />
            {meta.touched && meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>
      <Field name="role">
        {({ field, meta }) => (
          <>
            <DataSelect
              id={field.name}
              label="Role"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a role' },
                ...(roles || [])?.map((role) => ({
                  value: role.id,
                  label: role.name,
                })),
              ]}
              {...field}
            />
            {meta.touched && meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>
      <Field name="countryId">
        {({ field, meta }) => (
          <>
            <DataSelect
              id={field.name}
              label="Country"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a country' },
                ...(countries || [])?.map((country) => ({
                  value: country.id,
                  label: country.name,
                })),
              ]}
              {...field}
            />
            {meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3 ml-0">
                {meta.error}
              </div>
            )}
          </>
        )}
      </Field>
      <Field name="dob">
        {({ field, meta }) => {
          const { name, onChange, ...rest } = field;
          return (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Date of Birth
                </Form.Label>
                <Col sm="10">
                  <ReactDatePicker
                    className="rounded-full border border-gray-300 text-black w-full px-3 py-2 text-sm"
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={(val) => {
                      setFieldValue(field.name, val);
                    }}
                    wrapperClassName="w-full"
                    showYearDropdown
                    {...rest}
                  />
                </Col>
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3 ml-3">
                    {meta.error}
                  </div>
                )}
              </Form.Group>
            </>
          );
        }}
      </Field>
      <Field name="gender" type="checkbox">
        {({ field, meta }) => {
          return (
            <>
              <DataSelect
                id={field.name}
                name={field.name}
                label="Gender"
                type="select"
                buttons={false}
                options={[
                  { value: 0, label: 'Male' },
                  { value: 1, label: 'Female' },
                  { value: 2, label: 'Prefer not to say' },
                ]}
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Image"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>
      <Field name="bio">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Bio"
                as="textarea"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="interests">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Interests"
                as="textarea"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="home">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Home"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="favouriteProductTypes">
        {({ field }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Favourite product types
              </Form.Label>
              <Col sm="10">
                <ToggleButtonGroup
                  style={{ flexWrap: 'wrap' }}
                  type="checkbox"
                  name={field.name}
                  value={field.value}
                  onChange={(values) => {
                    setFieldValue(field.name, values);
                  }}
                >
                  {productTypes?.map((productType) => (
                    <ToggleButton
                      key={productType.id}
                      variant="outline-secondary"
                      value={productType.id}
                    >
                      {productType.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Col>
            </Form.Group>
          );
        }}
      </Field>
      <Field name="favouriteProducts">
        {({ field }) => (
          <DataSearch
            label="Favourite products"
            url="/portal/products"
            {...field}
            onChange={(productIds) =>
              setFieldValue('favouriteProducts', productIds)
            }
          />
        )}
      </Field>
      <Field name="firstLogin">
        {({ field }) => (
          <DataInput
            id="firstLogin"
            label="First login"
            defaultValue={field.value}
            plaintext
            readonly
          />
        )}
      </Field>
      <Field name="lastLogin">
        {({ field }) => (
          <DataInput
            id="lastLogin"
            label="Last Login"
            defaultValue={field.value}
            plaintext
            readonly
          />
        )}
      </Field>
      <Field name="loginCount">
        {({ field }) => (
          <DataInput
            id="loginCount"
            label="Number of logins"
            defaultValue={field.value}
            plaintext
            readonly
          />
        )}
      </Field>

      <Button
        variant="primary"
        type="submit"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={isSubmitting}
      >
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button
          variant="primary"
          type="button"
          className="border-orange-br bg-orange-br"
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </FormikForm>
  );
};
