import styled from 'styled-components';
import {
  largeScreenTemplateColumns,
  smallScreenTemplateColumns,
} from './PlaceResultItem/PlaceResultItem';
import { PlaceResultSkeleton } from './PlaceResultItem/PlaceResultSkeleton';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1244px;
`;

const SkeletonTopBar = styled.div`
  display: grid;
  grid-template-columns: ${largeScreenTemplateColumns};
  grid-column-gap: 16px;
  grid-template-rows: 1fr;
  margin-bottom: 4px;
  padding-left: 10px;

  margin-top: -16px;

  @media (max-width: 1300px) {
    grid-template-columns: ${smallScreenTemplateColumns};
  }
`;

const SkeletonLine = styled.div`
  height: 16px;
  background-color: #f3f3f3;
  border-radius: 4px;
  width: ${(props) => props.width || '100%'};

  @media (max-width: 1300px) {
    &:last-of-type {
      display: none;
    }
  }
`;

const SkeletonList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
  padding: 3px;
`;

export const SkeletonDataList = () => {
  return (
    <Container>
      <SkeletonTopBar>
        <SkeletonLine width="65px" />
        <div />
        <SkeletonLine width="20px" />
        <SkeletonLine width="20px" />
        <SkeletonLine width="20px" />
      </SkeletonTopBar>
      <SkeletonList>
        <PlaceResultSkeleton />
        <PlaceResultSkeleton />
        <PlaceResultSkeleton />
      </SkeletonList>
    </Container>
  );
};
