import React, { Suspense, useMemo, useState } from 'react';
import { PlacesList } from '../components/PlacesList';
import { PageFrame } from '../../common/components/PageFrame';
import { SkeletonDataList } from '../components/SkeletonDataList';
import { FilterBar } from '../components/FilterBar/FilterBar';
import { FilterProvider } from '../context/filterContext';
import { SearchBar } from '../../common/components/SearchBar';
import { FilterBarToggleButton } from '../components/FilterBarToggleButton/FilterBarToggleButton';
import { AppliedFiltersBar } from '../components/AppliedFiltersBar/AppliedFiltersBar';
import { AddOrEditPlaceModal } from '../components/AddAPlaceModal/AddOrEditPlaceModal';
import { PrimaryButton } from '../../common/components/PrimaryButton';
import { ReactComponent as Plus } from '../../common/svg/plus.svg';
import { DeletePlaceModal } from '../components/DeletePlaceModal/DeletePlaceModal';
import { useHistory, useLocation } from 'react-router-dom';

const PAGE_SIZE = 20;

export const PlacesListPage = () => {
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const onEditItem = (place) => {
    setSelectedPlace(place);
    setIsOpen(true);
  };

  const onDeleteItem = (place) => {
    setSelectedPlace(place);
    setIsDeleting(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    if (selectedPlace) {
      setSelectedPlace(null);
    }
  };

  const onCloseDeleteModal = () => {
    setIsDeleting(false);
    if (selectedPlace) {
      setSelectedPlace(null);
    }
  };

  return (
    <>
      <FilterProvider>
        {({ filterBarOpen, toggleFilterBar, appliedFilters }) => {
          return (
            <PageFrame
              title="Places"
              newBg
              topBarContent={
                <>
                  <SearchBar placeholder="Search places" onChange={setQuery} />
                  <FilterBarToggleButton
                    isOpen={filterBarOpen}
                    filterCount={appliedFilters.length}
                    onClick={toggleFilterBar}
                  />
                  <PrimaryButton
                    text="Add Place"
                    onClick={() => setIsOpen(true)}
                    icon={Plus}
                  />
                </>
              }
              subHeaderContent={filterBarOpen && <FilterBar />}
              belowContainerContent={
                appliedFilters.length > 0 &&
                filterBarOpen && <AppliedFiltersBar />
              }
            >
              <Suspense fallback={<SkeletonDataList />}>
                <PlacesList
                  offset={offset}
                  limit={PAGE_SIZE}
                  query={query}
                  setOffset={setOffset}
                  onEditItem={onEditItem}
                  onDeleteItem={onDeleteItem}
                />
              </Suspense>
              {isOpen && (
                <AddOrEditPlaceModal
                  isOpen
                  isEditMode={Boolean(selectedPlace)}
                  place={selectedPlace}
                  onClose={onCloseModal}
                />
              )}
              {isDeleting && (
                <DeletePlaceModal
                  isOpen
                  place={selectedPlace}
                  onClose={onCloseDeleteModal}
                />
              )}
            </PageFrame>
          );
        }}
      </FilterProvider>
    </>
  );
};
