import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import DataInput from '../../common/components/DataInput';
import React, { useContext, useState } from 'react';
import { SiteAddressAutocompleteInput } from '../../places/components/SiteAddressAutocompleteInput';
import ImageSelector from '../../common/components/ImageSelector';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../common/context/userContext';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';
import ComboBox from '../../common/components/ComboBox';

export const validateForm = (values) => {
  const errors = {};

  if (!values.company?.id) {
    errors.company = 'You must select a company';
  }

  if (!values.name) {
    errors.name = 'You must provide a name';
  }

  if (!values.address) {
    errors.address = 'You must provide a address';
  }

  return errors;
};

export const BrandForm = ({ onDelete }) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } =
    useFormikContext();
  const { isAdmin } = useContext(UserContext);
  const history = useHistory();
  const [companyQuery, setCompanyQuery] = useState('');

  const { data: companies } = useSWR(
    isAdmin ? `/portal/companies?query=${companyQuery}` : null,
    fetcher,
    { suspense: false }
  );

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      {isAdmin && (
        <Field name="company">
          {({ field, meta }) => (
            <>
              <ComboBox
                {...field}
                id={field.name}
                label="Company"
                options={companies}
                query={companyQuery}
                onQueryChange={setCompanyQuery}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}
      <Field name="name">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Name"
                placeholder="Brand name"
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="email">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Email"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="address">
        {({ field, meta }) => {
          return (
            <>
              {' '}
              <SiteAddressAutocompleteInput
                id={field.name}
                label="Address"
                {...field}
                value={field.value}
                onChange={(value) => {
                  setFieldValue('address', value);
                }}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="bio">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Bio"
                as="textarea"
                placeholder="Short brand biography"
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="foundedYear">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Founded"
                placeholder="Usually a date, but any text"
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="founder">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Founder"
                placeholder="Leave blank for now"
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="phoneNumber">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Phone number"
                placeholder="Leave blank for now"
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="url">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Website"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>
      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Logo"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>

      <DataInput
        id="name"
        label="Created"
        defaultValue={values?.createdAt}
        plaintext
        readonly
      />
      <DataInput
        id="name"
        label="Changed"
        defaultValue={values?.updatedAt}
        plaintext
        readonly
      />

      <Button
        variant="primary"
        type="submit"
        className="mr-3 border-orange-br bg-orange-br"
      >
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button
          className="border-orange-br bg-orange-br"
          variant="primary"
          type="button"
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </Form>
  );
};
