import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import useSWR from 'swr';
import { fetcher } from '../api/fetcher';

export const AutoCompleteComboBox = (props) => {
  const [query, setQuery] = useState('');

  const { data } = useSWR(
    `/portal/autocomplete/${props.endpoint}?q=${query}`,
    fetcher,
    { suspense: false }
  );

  const { setFieldValue } = useFormikContext();
  return (
    <div>
      <label className="bw-form-label" htmlFor={props.name}>
        {props.label}:
      </label>
      <div className="row mb-2">
        <div className="col-6">
          <Combobox
            as="div"
            value={props.value}
            onChange={(option) => setFieldValue(props.name, option)}
          >
            <Combobox.Input
              displayValue={(company) => company?.name}
              onChange={(event) => setQuery(event.target.value)}
              className="text-sm py-1.5 pl-3 rounded-full h-8 w-full"
              style={{ backgroundColor: '#f2f2f2' }}
              placeholder={props.placeholder}
            />
            <Combobox.Button className="absolute inset-y-0 right-2 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
            {data?.length > 0 && (
              <Combobox.Options
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                style={{
                  backgroundColor: '#f2f2f2',
                }}
              >
                {data?.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                        active ? 'bg-gray-300' : ''
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {option.name}
                        </span>
                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </Combobox>
        </div>
      </div>
    </div>
  );
};
