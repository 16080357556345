import styled, { css } from 'styled-components';
import { sidebarWidth } from '../common.const';
import React from 'react';

const PageContainer = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${sidebarWidth}px;
  height: 100%;
`;

const TopBar = styled.div`
  background-color: white;
  border-bottom: 1px solid #d9dbdd;
  display: flex;
  flex-direction: column;
  padding: 12px 48px 12px 34px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Subheader = styled.div`
  padding: 12px 0 0;
`;

const Title = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #454a5c;
  margin-bottom: 0;
`;

const Content = styled.div`
  ${({ newBg }) => css`
    flex: 1;
    padding: 24px;
    background-color: ${newBg ? '#F7F8F9' : 'white'};
    overflow: auto;
  `}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 8px;
  }
`;

export const PageFrame = ({
  title,
  children,
  topBarContent,
  subHeaderContent,
  belowContainerContent,
  newBg = false,
}) => {
  return (
    <PageContainer>
      <MainContent>
        <TopBar>
          <Row>
            <Title>{title}</Title>
            <Actions>{topBarContent}</Actions>
          </Row>
          {subHeaderContent && <Subheader>{subHeaderContent}</Subheader>}
        </TopBar>
        {belowContainerContent}
        <Content newBg={newBg}>{children}</Content>
      </MainContent>
    </PageContainer>
  );
};
