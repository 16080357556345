import styled, { css } from 'styled-components';
import { ReactComponent as Filter } from './filter.svg';

const Container = styled.button`
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  height: 34px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d808d;

  margin-left: -8px;
  margin-right: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 10px 8.5px 8px;

  svg {
    margin-right: 5px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid #ed6921;
      box-shadow: 0 0 5px rgba(237, 105, 33, 0.4);
    `}
`;

const CountBadge = styled.div`
  width: 15px;
  height: 15px; /* Ellipse 17 */
  background: #ed6921;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 5px;
`;

export const FilterBarToggleButton = ({
  isOpen = false,
  filterCount = 0,
  onClick,
}) => {
  return (
    <Container isOpen={isOpen} type="button" onClick={onClick}>
      {filterCount > 0 ? <CountBadge>{filterCount}</CountBadge> : <Filter />}
      Filters
    </Container>
  );
};
