import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { appConfig } from '../../common/app.config';

export const SiteAddressAutocompleteInput = ({
  id,
  name,
  label,
  msg,
  value,
  onChange,
  readOnly,
}) => {
  return (
    <Form.Group as={Row} controlId={id}>
      <Form.Label column sm="2">
        {label}
      </Form.Label>
      <Col sm="10" style={{ ...(readOnly ? { pointerEvents: 'none' } : {}) }}>
        <GooglePlacesAutocomplete
          apiKey={appConfig.googleMaps.apiKey}
          selectProps={{
            id,
            name,
            value,
            onChange,
            styles: {
              control: (provided) => ({
                ...provided,
                borderRadius: 30,
                ...(readOnly ? { background: '#e9ecef' } : {}),
              }),
            },
            placeholder: 'Start typing an address...',
          }}
        />
        {msg && <div style={{ color: '#ff0000' }}>{msg}</div>}
      </Col>
    </Form.Group>
  );
};
