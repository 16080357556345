import styled from 'styled-components';
import { sidebarWidth, topBarHeight, userProfileHeight } from '../common.const';

const Container = styled.div`
  min-width: 1200px;
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
  background: #f7f8f9;
`;

const Sidebar = styled.div`
  background-color: white;
  border-right: 1px solid #d9dbdd;
  height: 100vh;
  position: fixed;
  top: 0;
  width: ${sidebarWidth}px;
`;

const TopBar = styled.div`
  background-color: white;
  border-bottom: 1px solid #d9dbdd;
  height: ${topBarHeight}px;
  margin-left: ${sidebarWidth}px;
  position: fixed;
  top: 0;
  z-index: 1;
  width: calc(100% - ${sidebarWidth}px);
  padding: 12px 48px 12px 34px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 9px;
  width: 100%;
  height: ${userProfileHeight}px;
  border-top: 1px solid #d9dbdd;
  cursor: pointer;

  position: absolute;
  bottom: 0;
  left: 0;
`;

const SkeletonAvatar = styled.div`
  width: 30px;
  height: 30px;
  background: #f7f8f9;
  border-radius: 50%;
`;

export const SkeletonAppFrame = () => {
  return (
    <Container>
      <Sidebar>
        <UserProfile>
          <SkeletonAvatar />
        </UserProfile>
      </Sidebar>
      <TopBar />
    </Container>
  );
};
