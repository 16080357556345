import styled, { css } from 'styled-components';
import { FormGrid } from './styles';
import { useFormContext } from 'react-hook-form';
import useSWR from 'swr';
import { fetcher } from '../../../common/api/fetcher';
import Imgix from 'react-imgix';

import {
  Label as TypesLabel,
  StyledCheckbox,
  Wrapper as TypesWrapper,
  Icon as TypesIcon,
} from './PlaceTypesSelector';
import { ReactComponent as Tick } from './tick.svg';

const DisabledTypesWrapper = styled(TypesWrapper)`
  pointer-events: none;
  gap: 6px;
`;

const StyledTypesLabel = styled(TypesLabel)`
  padding: 6px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  line-height: 12px;

  ${({ checked }) =>
    !checked &&
    css`
      opacity: 0.7;
    `}
`;

const StyledCB = styled(StyledCheckbox)`
  height: 13px;
  width: 13px;
`;

const StylesTypesIcon = styled(TypesIcon)`
  transform: scale(0.8);
`;

const DetailsContainer = styled.div`
  width: 500px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  padding: 8px 24px 8px 8px;

  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 10px;
`;

const Key = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #7d808d;
`;

const Value = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;

  // truncate text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SectionTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7d808d;
  margin-top: 14px;
`;

const ImagePreview = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 5px;
  filter: drop-shadow(0px 0.5px 1.5px rgba(69, 74, 92, 0.25));
  background: #d9d9d9;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

export const Overview = () => {
  const { data: countries } = useSWR('/countries', fetcher);
  const { data: placeTypes } = useSWR('/place-types', fetcher);
  const { getValues } = useFormContext();

  const values = getValues();

  const country = countries?.find((country) => country.code === values.country);

  const fullPhoneNumber =
    values.phoneNumber && values.phoneNumber.length > 0
      ? `+${values.phoneCountryCode} ${values.phoneNumber}`
      : null;

  const image = values.imageUrl
    ? values.imageUrl
    : '/assets/places-placeholder.png';

  return (
    <FormGrid style={{ gridRowGap: 24 }}>
      <SectionTitle>Details</SectionTitle>
      <div>
        <DetailsContainer>
          <Key>Name</Key>
          <Value>{values.name}</Value>

          <Key>Address line 1</Key>
          <Value>{values.addressLine1}</Value>

          <Key>Address line 2</Key>
          <Value>{values.addressLine2 || '-'}</Value>

          <Key>Town/City</Key>
          <Value>{values.city}</Value>

          <Key>Postcode/Zip</Key>
          <Value>{values.postalCode}</Value>

          <Key>State/Province</Key>
          <Value>{values.stateRegion || '-'}</Value>

          <Key>Country</Key>
          <Value>{country?.name || '-'}</Value>

          <Key>Email</Key>
          <Value>{values.email || '-'}</Value>

          <Key>Phone number</Key>
          <Value>{fullPhoneNumber || '-'}</Value>

          <Key>Website</Key>
          <Value>{values.url || '-'}</Value>

          <Key>what3words</Key>
          <Value>{values.what3words || '-'}</Value>
        </DetailsContainer>
      </div>

      <SectionTitle>Image</SectionTitle>
      <div>
        {image && (
          <ImagePreview>
            <Imgix width={90} height={90} src={image} alt={values.name} fluid />
          </ImagePreview>
        )}
      </div>

      <SectionTitle>Drinks menu</SectionTitle>
      <div>
        <DetailsContainer>
          <Key>Company</Key>
          <Value>{values.company.name}</Value>

          <Key>Drinks menu</Key>
          <Value>{values.drinksMenu.name}</Value>
        </DetailsContainer>
      </div>

      <SectionTitle>Type(s)</SectionTitle>
      <div>
        <DisabledTypesWrapper>
          {placeTypes?.map((placeType) => {
            const checked = values.placeTypes.includes(placeType.slug);

            return (
              <StyledTypesLabel key={placeType.slug} checked={checked}>
                <StyledCB checked={checked}>
                  <StylesTypesIcon as={Tick} />
                </StyledCB>
                {placeType.name}
              </StyledTypesLabel>
            );
          })}
        </DisabledTypesWrapper>
      </div>
    </FormGrid>
  );
};
