import { useCallback } from 'react';
import { merge } from 'lodash/object';
import { fetcher } from '../../../../common/api/fetcher';
import { convertGooglePlaceToAddress } from '../utils';

export const useHandleAddressSelect = (setValue, handleNext) => {
  return useCallback(
    async (address) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      const request = {
        placeId: address.placeId,
        fields: [
          'address_component',
          'types',
          'formatted_phone_number',
          'website',
        ],
      };

      service.getDetails(request, async (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const detailedAddress = convertGooglePlaceToAddress(
            merge(address, place)
          );

          try {
            const { words } = await fetcher(
              `/portal/places/w3w?lat=${detailedAddress.latitude}&lng=${detailedAddress.longitude}`
            );

            if (words) {
              setValue('what3words', words);
            }
          } catch (error) {
            console.log(error);
          }

          // Use the setValue function to update the form fields.
          setValue('name', detailedAddress.name);
          setValue('addressLine1', detailedAddress.addressLine1);
          setValue('addressLine2', detailedAddress.addressLine2);
          setValue('city', detailedAddress.city);
          setValue('stateRegion', detailedAddress.stateRegion);
          setValue('postalCode', detailedAddress.postalCode);
          setValue('country', detailedAddress.country);
          setValue('phoneNumber', detailedAddress.phoneNumber);
          setValue('phoneCountryCode', detailedAddress.phoneCountryCode);
          setValue('url', detailedAddress.url);
          setValue('placeTypes', detailedAddress.placeTypes);
          setValue('location', {
            lat: detailedAddress.latitude,
            lng: detailedAddress.longitude,
          });
          setValue('googlePlaceId', detailedAddress.placeId);

          handleNext();
        }
      });
    },
    [setValue, handleNext]
  );
};
