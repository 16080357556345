import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { Field, useFormikContext } from 'formik';

import { TextInput } from '../../../components/forms/TextInput';
import { Select } from '../../../components/forms/Select';
import { TextArea } from '../../../components/forms/TextArea';
import { Radio } from '../../../components/forms/Radio';
import { Label } from '../../../components/forms/Label';
import { PushNotificationsDrinkPreferenceSelector } from './PushNotificationsDrinkPreferenceSelector';
import { DatePicker } from '../../../components/forms/DatePicker';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';
import { AutoCompleteComboBox } from '../../common/components/AutoCompleteComboBox';
import { PushNotificationLinkedEntitySelector } from './PushNotificationLinkedEntitySelector';

export const validateForm = (values) => {
  const errors = {};
  if (!values.headline) {
    errors.headline = 'You must supply a headline';
  }
  if (!values.brandId) {
    errors.brandId = 'You must select a brand';
  }
  if (!values.body) {
    errors.body = 'You must enter some text';
  }
  if (values.targetAge === 'ageRange') {
    if (!values.minAge) {
      errors.minAge = 'You must enter a min age';
    }
    if (!values.maxAge) {
      errors.maxAge = 'You must enter a min age';
    }
  }

  if (values.sendTo === '') {
    errors.sendTo = 'You must select an audience';
  }

  if (!values.sendAt) {
    errors.sendAt = 'You must select a date and time';
  }

  return errors;
};

export const PushNotificationsForm = ({ onDelete }) => {
  const history = useHistory();
  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormikContext();
  const { data: brands } = useSWR(`/portal/brands?limit=500`, fetcher, {
    suspense: true,
  });
  const { data: productTypes } = useSWR('/product-types', fetcher, {
    suspense: true,
  });
  const { data: countries } = useSWR('/countries', fetcher, { suspense: true });

  const readOnly = values.status === 'sent';

  return (
    <Form>
      {values.status === 'sent' && (
        <Alert variant="warning">
          This notification has been sent. It is read only.
        </Alert>
      )}
      <div className="module-title-block p-2">
        <div className="grid grid-cols-2 gap-4 mb-2">
          <Field name="headline">
            {({ field, meta }) => {
              return (
                <TextInput
                  label="Headline:"
                  placeholder="Enter headline"
                  error={meta.error}
                  readOnly={readOnly}
                  {...field}
                />
              );
            }}
          </Field>
          <Field name="brandId">
            {({ field, meta }) => {
              return (
                <Select
                  label="Brand:"
                  error={meta.error}
                  {...field}
                  {...(readOnly && { onChange: () => {} })}
                >
                  <option value="">Select a brand</option>
                  {brands?.map((brand) => (
                    <option value={brand.id}>{brand.name}</option>
                  ))}
                </Select>
              );
            }}
          </Field>
        </div>
        <Field name="body">
          {({ field, meta }) => {
            return (
              <TextArea
                label="Text:"
                rows={3}
                placeholder="Enter text..."
                error={meta.error}
                {...field}
                readOnly={readOnly}
              />
            );
          }}
        </Field>
      </div>

      <div className="module-title-block p-2">
        <Row className="mb-2.5">
          <Col xs="6">
            <Field name="sendTo">
              {({ field, meta }) => (
                <Select
                  label="Send to:"
                  {...field}
                  {...(readOnly && { onChange: () => {} })}
                  error={meta.error}
                >
                  <option value="">Select a user group</option>
                  <option value="all">All users</option>
                  <option value="targeted">Selected users</option>
                </Select>
              )}
            </Field>
            {values.sendTo === 'targeted' && (
              <>
                <Field name="countryId">
                  {({ field, meta }) => {
                    return (
                      <div className="my-3">
                        <Select
                          label="By country:"
                          error={meta.error}
                          {...field}
                          {...(readOnly && { onChange: () => {} })}
                        >
                          <option value="">Select a country</option>
                          {countries.map((country) => (
                            <option value={country.id}>{country.name}</option>
                          ))}
                        </Select>
                      </div>
                    );
                  }}
                </Field>
                <Field name="gender">
                  {({ field }) => (
                    <Radio
                      label="By gender:"
                      options={[
                        { value: '', label: 'All' },
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                      ]}
                      {...field}
                      {...(readOnly && { onChange: () => {} })}
                    />
                  )}
                </Field>
              </>
            )}
          </Col>
          {values.sendTo === 'targeted' && (
            <Col xs="6">
              <Field name="productTypeIds">
                {({ field }) => {
                  return (
                    <PushNotificationsDrinkPreferenceSelector
                      options={productTypes.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                      onChange={(drinkPreferences) => {
                        setFieldValue('productTypeIds', drinkPreferences);
                      }}
                      {...(readOnly && { onChange: () => {} })}
                      value={field.value}
                    />
                  );
                }}
              </Field>
              <Field name="targetAge">
                {({ field }) => (
                  <Radio
                    label="By age range:"
                    options={[
                      { value: '', label: 'All' },
                      { value: 'ageRange', label: 'Age Range' },
                    ]}
                    {...field}
                    {...(readOnly && { onChange: () => {} })}
                  />
                )}
              </Field>
              {values.targetAge === 'ageRange' && (
                <div className="grid grid-cols-2 gap-4">
                  <Field name="minAge">
                    {({ field, meta }) => (
                      <TextInput
                        label="Min age:"
                        placeholder="Minimum Age"
                        type="number"
                        error={meta.touched && meta.error}
                        {...field}
                        readOnly={readOnly}
                      />
                    )}
                  </Field>
                  <Field name="maxAge">
                    {({ field, meta }) => (
                      <TextInput
                        label="Max age:"
                        placeholder="Max Age"
                        type="number"
                        error={meta.touched && meta.error}
                        {...field}
                        readOnly={readOnly}
                      />
                    )}
                  </Field>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
      <div className="module-title-block p-2">
        <Field name="linkedType">
          {({ field }) => {
            return (
              <div className="mb-2">
                <label className="bw-form-label">Link to:</label>
                <PushNotificationLinkedEntitySelector
                  options={[
                    { label: 'None', value: '' },
                    { label: 'Product', value: 'product' },
                    { label: 'Place', value: 'place' },
                    { label: 'News article / Giveaway', value: 'news' },
                  ]}
                  value={field.value}
                  onChange={(linkedType) => {
                    setFieldValue('data.productId', '');
                    setFieldValue('data.placeId', '');
                    setFieldValue('data.newsId', '');
                    setFieldValue(field.name, linkedType);
                  }}
                />
              </div>
            );
          }}
        </Field>
        {values.linkedType === 'product' && (
          <Field name="data.productId">
            {({ field }) => (
              <AutoCompleteComboBox
                {...field}
                id={field.name}
                label="Select"
                endpoint="products"
                placeholder="Search for a product..."
              />
            )}
          </Field>
        )}
        {values.linkedType === 'place' && (
          <Field name="data.placeId">
            {({ field }) => (
              <AutoCompleteComboBox
                {...field}
                id={field.name}
                label="Select"
                endpoint="places"
                placeholder="Search for a place..."
              />
            )}
          </Field>
        )}
        {values.linkedType === 'news' && (
          <Field name="data.newsId">
            {({ field }) => (
              <AutoCompleteComboBox
                {...field}
                id={field.name}
                label="Select"
                endpoint="news"
                placeholder="Search for a news article..."
              />
            )}
          </Field>
        )}
      </div>

      <div className="module-title-block px-2 py-4">
        <div className="grid grid-cols-2 gap-4">
          <Field name="sendAt">
            {({ field, meta }) => (
              <DatePicker
                label="Schedule date:"
                value={field.value}
                onChange={(value) => {
                  setFieldValue(field.name, value);
                }}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={new Date()}
                error={meta.error}
              />
            )}
          </Field>
          <div>
            <Label>
              Send status:{' '}
              <Badge className="ml-2 p-2" variant="dark">
                {values.status}
              </Badge>
            </Label>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          {values.createdAt && (
            <p className="text-sm">Saved: {values.createdAt}</p>
          )}
        </div>
        <div>
          {!readOnly && (
            <Button
              variant="primary"
              type="button"
              onClick={handleSubmit}
              className="mr-3 bg-orange-br border-orange-br"
            >
              {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          )}
          <Button
            variant="primary"
            type="button"
            onClick={history.goBack}
            className="mr-3 bg-orange-br border-orange-br"
          >
            Cancel
          </Button>
          {onDelete && (
            <Button
              variant="primary"
              type="button"
              className="bg-orange-br border-orange-br"
              onClick={onDelete}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
};
