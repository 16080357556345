import React, { Suspense, useState } from 'react';
import { PushNotificationsDataList } from '../components/PushNotificationsDataList';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';
import { AddButton } from '../../common/components/AddButton';
import { SearchBar } from '../../common/components/SearchBar';

export const PushNotificationsListPage = () => {
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame
      title="Push Notifications"
      topBarContent={
        <>
          <SearchBar
            placeholder="Search notifications"
            onChange={setQuery}
            style={{ width: 250 }}
          />
          <AddButton text="Add Notification" to="/push-notifications/add" />
        </>
      }
    >
      <ListSearchAndLimit
        limit={limit}
        setLimit={setLimit}
        onSearch={setQuery}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <PushNotificationsDataList
          offset={offset}
          limit={limit}
          query={query}
          setOffset={setOffset}
        />
      </Suspense>
    </PageFrame>
  );
};
