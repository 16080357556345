import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Menu } from '@headlessui/react';
import Imgix from 'react-imgix';
import { userProfileHeight, UserRoles } from '../../common.const';

import { ReactComponent as Ellipse } from '../../svg/ellipse.svg';
import { ReactComponent as Account } from './account.svg';
import { ReactComponent as SignOut } from './sign-out.svg';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/userContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 9px 10px;
  width: 100%;
  height: ${userProfileHeight}px;
  border-top: 1px solid #d9dbdd;
  cursor: pointer;

  position: absolute;
  bottom: 0;
  left: 0;
`;

const AvatarWrapper = styled.div`
  margin-left: 8px;
`;

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  background: #fdf2ec;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;

  ${Container}:hover & {
    border: 1px solid #ed6921;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid #ed6921;
    `}

  ${({ src }) =>
    src &&
    css`
      border: none;
    `}
`;

const AvatarText = styled.span`
  font-family: 'Signika', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #ed6921;
`;

const NameAndRole = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.span`
  height: 15px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ed6921;

  max-width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const UserRole = styled.span`
  height: 13px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #454a5c;
  margin-top: 1px;
  max-width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AvatarMenu = styled.div`
  background: #ffffff;
  border: 1px solid #d9dbdd;
  box-shadow: 0.2px 0.2px 2px rgba(69, 74, 92, 0.15);
  position: absolute;
  left: 100%;
  bottom: 0;
  overflow: clip;
  display: flex;
  flex-direction: column;
  border-radius: 0 5px 0 0;
`;

const MenuItem = styled(Link)`
  padding: 10px 20px 10px 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #454a5c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  white-space: nowrap;

  // make every item but the last have a bottom border
  &:not(:last-child) {
    border-bottom: 1px solid #ececee;
  }

  svg {
    margin-right: 6px;
    stroke: #454a5c;
    flex-shrink: 0;
    height: 16px;
    width: auto;
    margin-bottom: 0px;
  }

  &:hover {
    color: #ed6921;
    background: #fdf2ec;
    text-decoration: none;

    svg {
      stroke: #ed6921;
    }
  }
`;

const MenuButton = styled.button`
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 3px;

  svg {
    transition: all 0.2s ease-in-out;
    stroke: #454a5c;
  }

  ${Container}:hover & {
    svg {
      stroke: #ed6921;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        stroke: #ed6921;
      }
    `}
`;

const RoleItem = styled.div`
  padding: 12px;
  border-bottom: 1px solid #ececee;
`;

const RoleTitle = styled.span`
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #454a5c;
  white-space: nowrap;
`;

const UserEmail = styled.span`
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #a4a6af;
  white-space: nowrap;
  margin-top: 4px;
`;

const roleToPrettyName = {
  [UserRoles.Admin]: 'Admin',
  [UserRoles.BrandManager]: 'Brand Manager',
  [UserRoles.CompanyManager]: 'Company Manager',
  [UserRoles.LocationsManager]: 'Locations Manager',
  [UserRoles.RetailManager]: 'Retail Manager',
};

export const UserProfile = () => {
  const { role, user } = useContext(UserContext);

  const initial = user.name.charAt(0).toUpperCase();
  const roleText = roleToPrettyName[role];
  const companyName = user.company.name;

  return (
    <Menu>
      {({ open }) => {
        return (
          <Menu.Button as={Container}>
            <AvatarWrapper>
              {!user.imageUrl ? (
                <Avatar as={Imgix} src={user.imageUrl} />
              ) : (
                <Avatar isOpen={open}>
                  <AvatarText>{initial}</AvatarText>
                </Avatar>
              )}
            </AvatarWrapper>
            <NameAndRole>
              <UserName>{user.name}</UserName>
              <UserRole>{companyName}</UserRole>
            </NameAndRole>
            <MenuButton isOpen={open}>
              <Ellipse />
            </MenuButton>
            <Menu.Items as={AvatarMenu}>
              <Menu.Item>
                <RoleItem>
                  <RoleTitle>{roleText}</RoleTitle>
                  <UserEmail>{user.email}</UserEmail>
                </RoleItem>
              </Menu.Item>
              <Menu.Item>
                <MenuItem to="/change-password">
                  <Account />
                  Account
                </MenuItem>
              </Menu.Item>
              <Menu.Item>
                <MenuItem to="/logout">
                  <SignOut />
                  Sign out
                </MenuItem>
              </Menu.Item>
            </Menu.Items>
          </Menu.Button>
        );
      }}
    </Menu>
  );
};
