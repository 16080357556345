import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import React from 'react';
import Table from 'react-bootstrap/Table';

export const ProductEditsDataList = () => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/edits/products`,
    fetcher,
    {
      suspense: true,
    }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching brands, please try again"
        onClose={revalidate}
      />
    );
  }

  if (!data) return null;

  return (
    <Table striped bordered hover className="mt-3">
      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.id} className="cursor-pointer">
              <td onClick={() => history.push(`/edits/products/${item.id}`)}>
                {item.product ? item.product.name : item.name} -{' '}
                {item.company.name}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
