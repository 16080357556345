import styled, { css } from 'styled-components';
import useSWR from 'swr';
import { Controller, useFormContext } from 'react-hook-form';

import { ReactComponent as Clear } from '../../../../modules/common/svg/clear.svg';

import { fetcher } from '../../../common/api/fetcher';
import chevronDown from './chevronDown.png';
import { PhoneNumberInput } from './PhoneNumberInput';
import {
  ClearIcon,
  FormGrid,
  InputError,
  InputWrapper,
  LabelText,
  TextInput,
} from './styles';
import { ImageUploadInput } from '../ImageUploadInput/ImageUploadInput';

export const SelectWrapper = styled.div`
  position: relative;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  width: 100%;
  
    ${({ error }) =>
      error &&
      css`
        border: 1px solid #e44e47;
      `}}

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 11px;
    width: 12px; /* update width */
    height: 12px; /* update height */
    margin-top: -6px; /* adjust margin-top to vertically center the smaller image */
    background: url(${chevronDown}) no-repeat;
    background-size: contain; /* ensures the image fits in the given dimensions */
    pointer-events: none;
  }
`;

export const Select = styled.select`
  height: 48px;
  background: transparent;
  appearance: none; /* remove default select styles */
  border: none;
  width: 100%;
  padding: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;

  &:focus {
    outline: none;
  }
`;

export const AddressForm = () => {
  const { data: countries } = useSWR('/countries', fetcher);
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGrid>
      <LabelText>Name *</LabelText>
      <div>
        <Controller
          control={control}
          name="name"
          rules={{ required: 'Field required' }}
          render={({ field }) => (
            <InputWrapper>
              {errors[field.name] && (
                <InputError>{errors[field.name].message}</InputError>
              )}
              <TextInput error={errors[field.name]} {...field} />
              {getValues('name') && (
                <ClearIcon as={Clear} onClick={() => setValue('name', '')} />
              )}
            </InputWrapper>
          )}
        />
      </div>
      <LabelText>Address line 1 *</LabelText>
      <div>
        <Controller
          control={control}
          name="addressLine1"
          rules={{ required: 'Field required' }}
          render={({ field }) => (
            <InputWrapper>
              {errors[field.name] && (
                <InputError>{errors[field.name].message}</InputError>
              )}
              <TextInput error={errors[field.name]} {...field} />
              {getValues('addressLine1') && (
                <ClearIcon
                  as={Clear}
                  onClick={() => setValue('addressLine1', '')}
                />
              )}
            </InputWrapper>
          )}
        />
      </div>
      <LabelText>Address line 2</LabelText>
      <div>
        <Controller
          control={control}
          name="addressLine2"
          render={({ field }) => <TextInput {...field} />}
        />
      </div>
      <LabelText>Town/City *</LabelText>
      <div>
        <Controller
          control={control}
          name="city"
          rules={{ required: 'Field required' }}
          render={({ field }) => (
            <InputWrapper>
              {errors[field.name] && (
                <InputError>{errors[field.name].message}</InputError>
              )}
              <TextInput error={errors[field.name]} {...field} />
              {getValues('city') && (
                <ClearIcon as={Clear} onClick={() => setValue('city', '')} />
              )}
            </InputWrapper>
          )}
        />
      </div>
      <LabelText>State/County</LabelText>
      <div>
        <Controller
          control={control}
          name="stateRegion"
          render={({ field }) => (
            <InputWrapper>
              {errors[field.name] && (
                <InputError>{errors[field.name].message}</InputError>
              )}
              <TextInput error={errors[field.name]} {...field} />
              {getValues('state_region') && (
                <ClearIcon as={Clear} onClick={() => setValue('state', '')} />
              )}
            </InputWrapper>
          )}
        />
      </div>
      <LabelText>Postcode/Zip *</LabelText>
      <div>
        <Controller
          control={control}
          name="postalCode"
          rules={{ required: 'Field required' }}
          render={({ field }) => (
            <InputWrapper>
              {errors[field.name] && (
                <InputError>{errors[field.name].message}</InputError>
              )}
              <TextInput error={errors[field.name]} {...field} />
              {getValues('postalCode') && (
                <ClearIcon
                  as={Clear}
                  onClick={() => setValue('postalCode', '')}
                />
              )}
            </InputWrapper>
          )}
        />
      </div>
      <LabelText>Country *</LabelText>
      <div>
        <Controller
          control={control}
          name="country"
          rules={{ required: 'Field required' }}
          render={({ field }) => (
            <SelectWrapper error={errors[field.name]}>
              <InputWrapper>
                {errors[field.name] && (
                  <InputError>{errors[field.name].message}</InputError>
                )}
                <Select {...field}>
                  <option value="">Select country</option>
                  {countries?.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </InputWrapper>
            </SelectWrapper>
          )}
        />
      </div>
      <LabelText>Business email</LabelText>
      <div>
        <Controller
          control={control}
          name="email"
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          }}
          render={({ field }) => (
            <InputWrapper>
              {errors[field.name] && (
                <InputError>{errors[field.name].message}</InputError>
              )}
              <TextInput error={errors[field.name]} {...field} />
              {getValues('email') && (
                <ClearIcon as={Clear} onClick={() => setValue('email', '')} />
              )}
            </InputWrapper>
          )}
        />
      </div>
      <LabelText>Phone number</LabelText>
      <div>
        <PhoneNumberInput control={control} name="phoneNumber" />
      </div>
      <LabelText>Website</LabelText>
      <div>
        <Controller
          control={control}
          name="url"
          rules={{
            pattern: {
              value: /^(http|https):\/\/[^ "]+$/i,
              message: 'invalid url',
            },
          }}
          render={({ field }) => {
            const { onChange, ...rest } = field;
            return (
              <TextInput
                {...rest}
                onChange={(e) => {
                  let value = e.target.value;

                  // Auto-prepend 'http://' if not present, and not empty
                  if (value && !/^https?:\/\//i.test(value)) {
                    value = `https://${value}`;
                  }

                  onChange(value);
                }}
              />
            );
          }}
        />
      </div>
      <div>
        <LabelText>Image</LabelText>
      </div>
      <div>
        <Controller
          control={control}
          name="imageUrl"
          render={({ field: { ref, ...field } }) => {
            return <ImageUploadInput {...field} />;
          }}
        />
      </div>
    </FormGrid>
  );
};
