import { client } from '../../common/api/client';
import {
  newsFormValuesToUpdateNewsDto,
  newsFormValuesToCreateNewsDto,
} from './news.dto';

export const createNews = (values) => {
  const createNewsDto = newsFormValuesToCreateNewsDto(values);
  return client.post('/portal/news', createNewsDto);
};

export const updateNews = (id, values) => {
  const updateNewsDto = newsFormValuesToUpdateNewsDto(values);
  return client.patch(`/portal/news/${id}`, updateNewsDto);
};

export const deleteNews = (id) => {
  return client.delete(`/portal/news/${id}`);
};
