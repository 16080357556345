import { useContext, useEffect, useState, forwardRef } from 'react';
import { UserContext } from '../../../common/context/userContext';
import useSWR from 'swr';
import { fetcher } from '../../../common/api/fetcher';
import { Combobox } from '@headlessui/react';
import { ClearIcon, InputError, InputWrapper, TextInput } from './styles';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as Clear } from '../../../../modules/common/svg/clear.svg';
import { isEmpty } from 'lodash';

const OptionsContainer = styled.div`
  width: 500px;
  border: 1px solid #ed6921;
  border-radius: 5px;
  margin-top: 4px;
  overflow: hidden;
`;

const ExistingCompaniesLabel = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a4a6af;
  margin: 10px 8px 6px;
`;

const Option = styled.div`
  height: 48px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;

  &:hover {
    background: #fdf2ec;
    cursor: pointer;
  }
`;

const AddCompanyButton = styled.button`
  background: #fad2bc;
  border-radius: 0px 0px 5px 5px;
  height: 40px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddCompanyButtonText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
  margin-left: 4px;
`;

export const CompanyFinder = forwardRef(
  ({ name, value, onChange, error }, ref) => {
    const { isAdmin } = useContext(UserContext);
    const [companyQuery, setCompanyQuery] = useState('');
    const [localCompanies, setLocalCompanies] = useState([]);
    const { data: companies } = useSWR(
      isAdmin && !isEmpty(companyQuery)
        ? `/portal/companies?query=${companyQuery}&limit=5`
        : null,
      fetcher,
      { suspense: false }
    );

    useEffect(() => {
      if (companies) {
        setLocalCompanies(companies);
      }
    }, [companies]);

    const queryMatchesExistingCompany = localCompanies?.some(
      (company) => company.name.toLowerCase() === companyQuery.toLowerCase()
    );

    return (
      <>
        <Combobox
          name={name}
          value={value}
          onChange={(company) => {
            onChange(company);
            setCompanyQuery('');
          }}
        >
          <InputWrapper>
            {error && <InputError>{error.message}</InputError>}
            <Combobox.Input
              placeholder="Start typing name of company"
              onChange={(event) => setCompanyQuery(event.target.value)}
              displayValue={(company) => company?.name}
              as={TextInput}
              $error={error}
            />
            {value && <ClearIcon as={Clear} onClick={() => onChange('')} />}
          </InputWrapper>
          <Combobox.Options as={OptionsContainer}>
            {localCompanies?.length > 0 && (
              <ExistingCompaniesLabel>
                Existing companies ({localCompanies?.length})
              </ExistingCompaniesLabel>
            )}
            {localCompanies?.map((company) => (
              <Combobox.Option as={Option} key={company.id} value={company}>
                {company.name}
              </Combobox.Option>
            ))}
            {!queryMatchesExistingCompany && (
              <Combobox.Option
                value={{ id: null, name: companyQuery }}
                as={AddCompanyButton}
                type="button"
              >
                <AddIcon />
                <AddCompanyButtonText>
                  Add as a new company
                </AddCompanyButtonText>
              </Combobox.Option>
            )}
          </Combobox.Options>
        </Combobox>
      </>
    );
  }
);
