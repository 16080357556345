import React, { Suspense, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { GiveawayEntriesDataList } from '../components/GiveawayEntriesDataList';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { PageFrame } from '../../common/components/PageFrame';

export const GiveawayEntriesListPage = () => {
  const { id: giveawayId } = useParams();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [errorBox, setErrorBox] = useState(noError);

  const {
    data: giveawayItem,
    error: giveawayError,
    isValidating: loading,
  } = useSWR(`/portal/giveaways/${giveawayId}`, fetcher);

  useEffect(() => {
    if (giveawayError) {
      setErrorBox({
        title: 'Error loading location',
        msg: 'Please try again',
      });
    }
  }, [giveawayItem]);

  return (
    <PageFrame
      title={!loading && !giveawayError ? `${giveawayItem.title} Entries` : ''}
    >
      <ListSearchAndLimit setLimit={setLimit} limit={limit} />
      <Suspense fallback={<LoadingSpinner />}>
        <GiveawayEntriesDataList
          offset={offset}
          limit={limit}
          giveawayId={giveawayId}
        />
      </Suspense>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
