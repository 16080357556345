export const giveawayApiResponseToFormValues = (giveaway) => {
  return {
    title: giveaway.title,
    createdBy: giveaway.createdBy,
    startDate: giveaway.startDate,
    endDate: giveaway.endDate,
    imageUrl: giveaway.imageUrl,
    introduction: giveaway.introduction,
    body: giveaway.body,
    productIds: giveaway.products?.map(({ id }) => id),
    countryIds: giveaway.countries?.map(({ id }) => id),
    createdAt: giveaway.createdAt,
  };
};

export const giveawayFormValuesToCreateGiveawaysDto = (formValues) => {
  return {
    title: formValues.title,
    createdBy: formValues.createdBy,
    startDate: formValues.startDate,
    endDate: formValues.endDate,
    imageUrl: formValues.imageUrl,
    introduction: formValues.introduction,
    body: formValues.body,
    countryIds: formValues.countryIds || [],
    productIds: formValues.productIds || [],
  };
};

export const giveawayFormValuesToUpdateGiveawaysDto = (formValues) => {
  return {
    title: formValues.title,
    createdBy: formValues.createdBy,
    startDate: formValues.startDate,
    endDate: formValues.endDate,
    imageUrl: formValues.imageUrl,
    introduction: formValues.introduction,
    body: formValues.body,
    countryIds: formValues.countryIds || [],
    productIds: formValues.productIds || [],
  };
};
