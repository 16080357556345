import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { Formik } from 'formik';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { RetailerForm, validateForm } from '../components/RetailerForm';
import { deleteRetailer, updateRetailer } from '../api/retailers.api';
import { retailersApiResponseToFormValues } from '../api/retailers.dto';
import { PageFrame } from '../../common/components/PageFrame';

export const EditRetailerPage = () => {
  const history = useHistory();
  const { id: retailerId } = useParams();
  const {
    data: retailer,
    error,
    isValidating: loading,
  } = useSWR(`/portal/retailers/${retailerId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateRetailer(retailerId, values);
      await mutate(`/portal/retailers/${retailerId}`);
      await mutate(`/portal/retailers`);
      history.push('/retailers/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this retailer',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRetailer(retailerId);
      await mutate(`/portal/retailers`);
      history.push('/retailers/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this retailer',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading retailer',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Retailer">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {retailer && (
        <Formik
          initialValues={retailersApiResponseToFormValues(retailer)}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <RetailerForm onDelete={handleDelete} />
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
