import { brandsFormValuesToCreateBrandDto } from './brands.dto';
import { client } from '../../common/api/client';

export const createBrand = async (values) => {
  const createBrandDto = await brandsFormValuesToCreateBrandDto(values);
  return client.post('/portal/brands', createBrandDto);
};

export const updateBrand = async (id, values) => {
  const updateBrandDto = await brandsFormValuesToCreateBrandDto(values);
  return client.patch(`/portal/brands/${id}`, updateBrandDto);
};

export const deleteBrand = async (id) => {
  return client.delete(`/portal/brands/${id}`);
};
