import React from 'react';
import Form from 'react-bootstrap/Form';
import DataInput from '../../common/components/DataInput';
import { SiteAddressAutocompleteInput } from '../../places/components/SiteAddressAutocompleteInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import get from 'lodash/get';

export const PlaceEditForm = ({ value, onApprove, onDeny, loadingValue }) => {
  const { data: placeTypes } = useSWR('/place-types', fetcher);

  return (
    <Form className="detailsTable">
      <DataInput label="Name" value={value.name} readOnly />
      {get(value, 'owner.name') && (
        <DataInput
          label="Suggested By"
          value={get(value, 'owner.name', 'No user')}
          readOnly
        />
      )}
      <SiteAddressAutocompleteInput
        label="Address"
        value={value.address}
        readOnly
      />

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Place Type
        </Form.Label>
        <Col sm="10">
          <ToggleButtonGroup
            style={{ flexWrap: 'wrap' }}
            type="checkbox"
            value={value.placeTypes}
          >
            {placeTypes?.map((placeType) => (
              <ToggleButton
                key={placeType.id}
                variant="outline-secondary"
                value={placeType.id}
              >
                {placeType.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
      </Form.Group>

      <DataInput label="Email" value={value.emailAddress} readOnly />
      <DataInput label="Phone Number" value={value.phoneNumber} readOnly />
      <DataInput label="Website" value={value.url} readOnly />
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Products
        </Form.Label>
        <Col sm="10">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {value.drinksMenu?.menuProducts?.map((menuProduct) => {
                return (
                  <tr key={menuProduct?.product?.id}>
                    <td>{menuProduct?.product?.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Form.Group>

      <Button
        variant="primary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        onClick={onDeny}
      >
        {loadingValue === 'deny' ? (
          <Spinner animation="border" size="sm" />
        ) : (
          'Deny'
        )}
      </Button>

      <Button
        variant="primary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        onClick={onApprove}
      >
        {loadingValue === 'approve' ? (
          <Spinner animation="border" size="sm" />
        ) : (
          'Approve'
        )}
      </Button>
    </Form>
  );
};
