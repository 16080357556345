import { client } from '../../common/api/client';
import {
  retailerFormValuesToCreateRetailerDto,
  retailerFormValuesToUpdateRetailerDto,
} from './retailers.dto';

export const updateRetailer = (id, values) => {
  const updateRetailerDto = retailerFormValuesToUpdateRetailerDto(values);
  return client.patch(`/portal/retailers/${id}`, updateRetailerDto);
};

export const createRetailer = (values) => {
  const createRetailerDto = retailerFormValuesToCreateRetailerDto(values);
  return client.post('/portal/retailers', createRetailerDto);
};

export const deleteRetailer = (id) => {
  return client.delete(`portal/retailers/${id}`);
};
