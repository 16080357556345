import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import useSWR from 'swr';
import { Field, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { DrinksMenuProductList } from './DrinksMenuProductList';
import DataInput from '../../common/components/DataInput';
import { UserContext } from '../../common/context/userContext';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';
import ComboBox from '../../common/components/ComboBox';

export const validateForm = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'You must provide a name';
  }

  if (!values.company?.id) {
    errors.company = 'You must select a company';
  }

  return errors;
};

export const DrinksMenuForm = ({ onDelete }) => {
  const { handleSubmit, isSubmitting } = useFormikContext();
  const history = useHistory();
  const { isAdmin } = useContext(UserContext);
  const [companyQuery, setCompanyQuery] = useState('');

  const { data: companies } = useSWR(
    isAdmin ? `/portal/companies?query=${companyQuery}` : null,
    fetcher,
    {
      suspense: false,
    }
  );

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      <Field name="name">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Name"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      {isAdmin && (
        <Field name="company">
          {({ field, meta }) => (
            <>
              <ComboBox
                {...field}
                id={field.name}
                label="Company"
                options={companies}
                query={companyQuery}
                onQueryChange={setCompanyQuery}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Products
        </Form.Label>
        <Col sm="10">
          <DrinksMenuProductList />
        </Col>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="mr-3 border-orange-br bg-orange-br"
      >
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button
          variant="primary"
          type="button"
          className="border-orange-br bg-orange-br"
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </Form>
  );
};
