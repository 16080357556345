import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ErrorBox(props) {
  if (props.title === '') return '';
  return (
    <Modal show={true} onHide={handleErrorClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.msg}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleErrorClose} className="border-orange-br bg-orange-br">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function handleErrorClose() {
    if (props.onCloseReload) window.location.reload();
    if (props.onClose) props.onClose();
  }
}

export const noError = { title: '', msg: '' };

ErrorBox.defaultProps = {
  title: '',
  msg: '',
};
