import { Controller, useFormContext } from 'react-hook-form';
import { PlaceTypesSelector } from './PlaceTypesSelector';
import { FormGrid, LabelText } from './styles';

export const PlaceTypesForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGrid>
      <LabelText>Select type(s) *</LabelText>
      <div>
        <Controller
          control={control}
          name="placeTypes"
          rules={{
            required: 'Field required',
          }}
          render={({ field }) => {
            return <PlaceTypesSelector {...field} error={errors[field.name]} />;
          }}
        />
      </div>
    </FormGrid>
  );
};
