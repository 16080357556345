import parsePhoneNumber from 'libphonenumber-js';
import { FormStepsMap } from './FormSteps';

const types = [
  'leisure_venue',
  'event_venue',
  'hotel',
  'pub',
  'casual_dining',
  'fine_dining',
  'bar',
  'supermarket',
  'bottle_shop',
];

export const convertGooglePlaceToAddress = (place) => {
  let address = {
    name: place.name || '',
    address: place.formatted_address || '',
    latitude: place.latitude || place.geometry?.location.lat() || '',
    longitude: place.longitude || place.geometry?.location.lng() || '',
    placeId: place.placeId || place.place_id || '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateRegion: '',
    postalCode: '',
    country: '',
    phoneNumber: place.formatted_phone_number || '',
    url: place.website || '',
    placeTypes: (place.types || []).filter((type) => types.includes(type)),
  };

  // Check if address_components is an array before iterating
  if (Array.isArray(place.address_components)) {
    for (let component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address.addressLine1 = `${component.long_name} ${address.addressLine1}`;
          break;
        }
        case 'route':
        case 'street_address':
        case 'premise': {
          if (address.addressLine1.trim().match(/^\d+$/)) {
            address.addressLine1 = `${address.addressLine1.trim()} ${
              component.long_name
            }`;
          } else {
            address.addressLine1 = `${
              address.addressLine1 ? `${address.addressLine1}, ` : ''
            }${component.long_name}`;
          }
          break;
        }
        case 'sublocality': {
          address.addressLine2 = component.long_name;
          break;
        }
        case 'locality':
        case 'postal_town': {
          address.city = component.long_name;
          break;
        }
        case 'postal_code': {
          address.postalCode = component.long_name;
          break;
        }
        case 'country': {
          address.country = component.short_name;
          break;
        }
        case 'administrative_area_level_1': {
          address.stateRegion = component.short_name;
          break;
        }
      }
    }
  }

  if (!address.country && place.formatted_address) {
    address.country = place.formatted_address.split(', ').pop();
  }

  if (place.formatted_phone_number) {
    const result = parsePhoneNumber(
      place.formatted_phone_number,
      address.country
    );
    if (result) {
      address.phoneNumber = result.nationalNumber;
      address.phoneCountryCode = result.countryCallingCode;
    }
  }

  address.label = `${place.name || ''}, ${place.formatted_address || ''}`;

  return address;
};

export const getNextFormStep = (currentStep) => {
  switch (currentStep) {
    case FormStepsMap.ADDRESS_FINDER:
      return FormStepsMap.ADDRESS;
    case FormStepsMap.ADDRESS:
      return FormStepsMap.COMPANY;
    case FormStepsMap.COMPANY:
      return FormStepsMap.PLACE_TYPES;
    case FormStepsMap.PLACE_TYPES:
      return FormStepsMap.OVERVIEW;
    case FormStepsMap.OVERVIEW:
      return FormStepsMap.REMINDER;
    default:
      return FormStepsMap.ADDRESS;
  }
};

export const getPreviousFormStep = (currentStep) => {
  switch (currentStep) {
    case FormStepsMap.ADDRESS:
      return FormStepsMap.ADDRESS_FINDER;
    case FormStepsMap.COMPANY:
      return FormStepsMap.ADDRESS;
    case FormStepsMap.PLACE_TYPES:
      return FormStepsMap.COMPANY;
    case FormStepsMap.OVERVIEW:
      return FormStepsMap.PLACE_TYPES;
    case FormStepsMap.REMINDER:
      return FormStepsMap.OVERVIEW;
    default:
      return FormStepsMap.ADDRESS;
  }
};
