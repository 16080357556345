import React, { Suspense, useState } from 'react';
import { Formik } from 'formik';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { UserForm, validateForm as validate } from '../components/UserForm';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { createUser } from '../api/users.api';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { PageFrame } from '../../common/components/PageFrame';

const validateForm = (values) => {
  const errors = validate(values);

  if (!values.password) {
    errors.password = 'You must provide a password';
  }

  return errors;
};

export const AddUserPage = () => {
  const history = useHistory();
  const [errorBox, setErrorBox] = useState(noError);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await createUser(values);
      await mutate(`/portal/users`);
      history.push('/users/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred creating this user',
        msg: 'Please try again',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageFrame title="Add User">
      <Formik
        initialValues={{ gender: 2, role: 'User' }}
        onSubmit={handleSubmit}
        validate={validateForm}
        validateOnBlur={true}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <UserForm withPassword loading={loading} />
        </Suspense>
      </Formik>
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
