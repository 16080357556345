import styled, { css } from 'styled-components';
import { useFilter } from '../../context/filterContext';
import { useButtonPosition } from '../../../common/hooks/useButtonPosition';
import { useRef } from 'react';
import { Filter } from './Filter';

const Container = styled.button`
  ${({ isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7d808d;
    background: #fff;
    border-radius: 5px;
    margin: 0;

    padding: 8px 12px;

    svg:first-of-type {
      margin-right: 4px;
    }

    svg:last-of-type {
      margin-left: 7px;
    }

    svg {
      stroke: #7d808d;
    }

    &:hover {
      color: #ed6921;

      svg {
        stroke: #ed6921;
      }
    }

    ${isActive &&
    css`
      background: #fdf2ec;
      color: #ed6921;

      svg {
        stroke: #ed6921;
      }
    `}
  `}
`;

export const FilterButton = ({ filterId, children, renderFilter }) => {
  const buttonRef = useRef();
  const { activeFilter, setActiveFilter } = useFilter();
  const position = useButtonPosition(buttonRef);

  const handleClick = () => {
    setActiveFilter((prevFilter) =>
      prevFilter === filterId ? null : filterId
    );
  };

  return (
    <>
      <Container
        ref={buttonRef}
        onClick={handleClick}
        isActive={activeFilter === filterId}
      >
        {children}
      </Container>
      <Filter
        filterId={filterId}
        position={position}
        renderContent={renderFilter}
      />
    </>
  );
};
