import React, { useEffect, useMemo, useState } from 'react';
import { useSWRInfinite } from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import { NoResults } from '../../common/components/NoResults';
import { useInView } from 'react-intersection-observer';

import { PlaceResultItem } from './PlaceResultItem/PlaceResultItem';
import { TableCountAndSort } from './TableCountAndSort';
import styled from 'styled-components';
import { debounce } from 'lodash/function';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { useHistory, useLocation } from 'react-router-dom';
import { useFilter } from '../context/filterContext';

const PlaceList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
`;

const NoResultsContainer = styled.div`
  margin-top: 56px;
`;

export const PlacesList = ({ limit, query, onEditItem, onDeleteItem }) => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialSortField = searchParams.get('sf') || 'createdAt';
  const initialSortOrder = searchParams.get('so') || 'desc';

  const { appliedFilters } = useFilter();

  const [sort, setSort] = useState({
    field: initialSortField,
    order: initialSortOrder,
  });
  const [isLoading, setIsLoading] = useState(false);
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && previousPageData.data.length === 0) return null;

    // Only include 'property' and 'value' in the filter query param
    const minimalFilters = appliedFilters.map((filter) => ({
      property: filter.property,
      value: filter.value,
    }));

    return `/portal/places?${stringify({
      offset: pageIndex * limit,
      limit,
      sortField: sort.field,
      sortOrder: sort.order,
      ...(query !== '' && { query }),
      filters: JSON.stringify(minimalFilters),
    })}`;
  };

  const { data, error, revalidate, size, setSize, mutate } = useSWRInfinite(
    getKey,
    fetcher,
    {
      suspense: true,
    }
  );

  const flattenedPlaces = data
    ? [].concat(...data.map((page) => page.data))
    : [];
  const isReachingEnd = data && data[data.length - 1]?.data.length < limit;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const handleSortChange = async (field, order) => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        setSort({ field, order });
        await setSize(size + 1);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error loading more data:', error);
      setIsLoading(false);
    }
  };

  const loadMore = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        await setSize(size + 1);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error loading more data:', error);
      setIsLoading(false);
    }
  };

  const debouncedLoadMore = debounce(loadMore, 500);

  useEffect(() => {
    if (inView && !isReachingEnd && !error && !isLoading) {
      debouncedLoadMore();
    }

    return () => {
      debouncedLoadMore.cancel();
    };
  }, [inView, isReachingEnd, error, isLoading, debouncedLoadMore]);

  useEffect(() => {
    const currentSortField = searchParams.get('sf');
    const currentSortOrder = searchParams.get('so');

    if (currentSortField !== sort.field || currentSortOrder !== sort.order) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set('sf', sort.field);
      newSearchParams.set('so', sort.order);

      history.replace({
        ...location,
        search: newSearchParams.toString(),
      });
    }
  }, [sort, history, location, searchParams]);

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching pages, please try again"
        onClose={revalidate}
      />
    );
  }

  const totalCount = data[0]?.metadata.totalCount;

  if (totalCount === 0) {
    return (
      <NoResultsContainer>
        <NoResults />
      </NoResultsContainer>
    );
  }

  return (
    <div>
      <TableCountAndSort
        totalCount={totalCount}
        sort={sort}
        onSortChange={handleSortChange}
      />
      <PlaceList>
        {flattenedPlaces.map((place) => (
          <PlaceResultItem
            key={place.id}
            place={place}
            onEdit={onEditItem}
            onDelete={onDeleteItem}
          />
        ))}
        {isLoading && <LoadingSpinner />}
        <div ref={ref} />
      </PlaceList>
    </div>
  );
};
