import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Label } from './Label';
import 'react-datepicker/dist/react-datepicker.css';

export const DatePicker = ({ label, value, onChange, error, ...props }) => {
  return (
    <div>
      <Label className="mr-3">{label}</Label>
      <ReactDatePicker
        className="rounded-full border-0 bg-gray-light text-black w-full px-3 py-1.5 outline-none text-sm"
        selected={value}
        onChange={onChange}
        wrapperClassName="w-64"
        {...props}
      />
      {error && <p className="text-xs text-red-600 mt-2">{error}</p>}
    </div>
  );
};
