import styled from 'styled-components';

const ReminderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0 32px;
`;

const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #7d808d;
  max-width: 450px;
  text-align: center;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  strong:first-of-type {
    font-weight: 500;
  }
`;

export const Reminder = ({ place }) => {
  return (
    <ReminderContainer>
      <Text>
        Success, you've added <strong>{place.name}</strong> as a place!
      </Text>

      <Text>
        Don’t forget to add products to your new drinks menu:
        {'\n\n'}
        <strong>{place.drinksMenu.name}</strong>
      </Text>
    </ReminderContainer>
  );
};
