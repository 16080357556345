import { FormStepsMap } from './FormSteps';
import { StepIndicator, StepText } from './styles';
import { ReactComponent as StepOne } from './1-4.svg';
import { ReactComponent as StepTwo } from './2-4.svg';
import { ReactComponent as StepThree } from './3-4.svg';
import { ReactComponent as StepFour } from './4-4.svg';
import { ReactComponent as ReminderIcon } from './reminder.svg';

export const StepIcon = ({ step }) => {
  switch (step) {
    case FormStepsMap.ADDRESS_FINDER:
    case FormStepsMap.ADDRESS:
      return (
        <StepIndicator>
          <StepOne />
          <StepText>1/4</StepText>
        </StepIndicator>
      );
    case FormStepsMap.COMPANY:
      return (
        <StepIndicator>
          <StepTwo />
          <StepText>2/4</StepText>
        </StepIndicator>
      );
    case FormStepsMap.PLACE_TYPES:
      return (
        <StepIndicator>
          <StepThree />
          <StepText>3/4</StepText>
        </StepIndicator>
      );
    case FormStepsMap.OVERVIEW:
      return (
        <StepIndicator>
          <StepFour />
          <StepText>4/4</StepText>
        </StepIndicator>
      );
    case FormStepsMap.REMINDER:
      return <ReminderIcon />;
    default:
      return null;
  }
};
