export const formValuesToUpdateCompanyDto = (formValues) => {
  return {
    name: formValues.name,
    imageUrl: formValues.imageUrl,
  };
};

export const formValuesToCreateCompanyDto = (formValues) => {
  return {
    name: formValues.name,
    imageUrl: formValues.imageUrl,
  };
};