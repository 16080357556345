import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import React, { useContext } from 'react';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { UserContext } from '../../common/context/userContext';
import { NoResults } from '../../common/components/NoResults';

export const ProductsDataList = ({ offset, limit, query, setOffset }) => {
  const history = useHistory();
  const { isAdmin } = useContext(UserContext);
  const { data, error, revalidate } = useSWR(
    `/portal/products?${stringify({
      offset,
      limit,
      includeEdits: true,
      ...(query !== '' && { query }),
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching products, please try again"
        onClose={revalidate}
      />
    );
  }

  if (data?.length === 0) {
    return <NoResults />;
  }

  if (!data?.length) {
    return (
      <div className="text-center my-8 block">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No products</h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a product.
        </p>
      </div>
    );
  }

  return (
    <div>
      <Table striped bordered hover>
        <tbody>
          {data.map((item) => {
            const productIsLocked = item.hasOpenEdits && !isAdmin;
            return (
              <tr
                key={item.id}
                className={productIsLocked ? '' : 'cursor-pointer'}
              >
                <td
                  onClick={() =>
                    productIsLocked
                      ? {}
                      : history.push(`/products/edit/${item.id}`)
                  }
                >
                  {item.name}{' '}
                  {item.awaitingApproval && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      <svg
                        className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                      Awaiting Approval
                    </span>
                  )}
                  {item.hasOpenEdits && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      <svg
                        className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                      Edits Awaiting Approval
                    </span>
                  )}
                </td>
                {!item.awaitingApproval && isAdmin && (
                  <td>
                    <Button
                      as={Link}
                      variant="primary"
                      type="button"
                      className="border-orange-br bg-orange-br"
                      to={`/reviews/product/${item.id}`}
                    >
                      Reviews
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </div>
  );
};
