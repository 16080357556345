import React from 'react';
import './styles.css';

export const Label = ({ children, className, ...props }) => {
  return (
    <label className={`bw-form-label ${className}`} {...props}>
      {children}
    </label>
  );
};
