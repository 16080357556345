import ErrorBox from '../../common/components/ErrorBox';
import React from 'react';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import Table from 'react-bootstrap/Table';

export const GiveawayEntriesDataList = ({ offset, limit, giveawayId }) => {
  const { data, error, revalidate } = useSWR(
    `/portal/giveaways/entries/${giveawayId}?${stringify({
      offset,
      limit,
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching giveaways, please try again"
        onClose={revalidate}
      />
    );
  }

  return (
    <Table striped bordered hover>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="cursor-pointer">
            <td>
              <strong>{item.user.name}</strong> - {item.user.email}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
