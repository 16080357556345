import React from 'react';
import { AccessDenied } from './AccessDenied';

// Error boundaries currently have to be classes.
export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null, key: null };
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }
  setKey = () => this.setState({ key: new Date().getTime() });
  render() {
    if (this.state.hasError) {
      if (
        [401, 403].includes(this.state.error.response?.status) &&
        this.state.error.request?.responseURL?.includes('/portal/me')
      ) {
        // access denied
        return <AccessDenied onLogout={this.setKey} />;
      }
      return this.props.fallback;
    }
    return this.props.children;
  }
}
