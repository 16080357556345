import styled from 'styled-components';
import useSWR from 'swr';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { fetcher } from '../../../common/api/fetcher';

const Flag = styled.span`
  width: 19px;
  height: 14px;
  filter: drop-shadow(0.2px 0.5px 2px rgba(69, 74, 92, 0.25));
  border-radius: 3px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const PhoneNumberInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;

const CountryName = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  margin-left: 8px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const TextInput = styled.input`
  height: 48px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  padding-right: 31px; /* Make room for the button */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;

  &:focus {
    outline: none;
    border: 1px solid #ed6921;
    box-shadow: 0 0 5px rgba(237, 105, 33, 0.4);
  }
`;

const telCodeSelectStyles = {
  control: (base, { isFocused }) => ({
    ...base,
    border: '1px solid #d9dbdd',
    borderRadius: '5px',
    height: '48px',
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    color: '#454A5C',
    borderColor: isFocused ? '#ed6921 !important' : '#d9dbdd',
    boxShadow: isFocused ? '0 0 5px rgba(237, 105, 33, 0.4)' : '',
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? '#ed6921' : isFocused ? '#FDF2EC' : '#ffffff',
    color: isSelected ? '#ffffff' : '#454A5C',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  svg: (base) => ({
    ...base,
    height: '14px',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#ed6921',
    '&:hover': { color: '#ed6921' },
    '& svg': {
      height: '15px',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const PhoneNumberInput = () => {
  const { data: countries } = useSWR('/countries', fetcher);
  const { control, getValues } = useFormContext();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const options = countries?.map((country) => ({
    value: country.code,
    telCode: country.telCode,
    label: `+${country.telCode}`,
    flag: country.code.toLowerCase(),
  }));

  const selectedCountryCode = getValues('country') ?? 'GB';

  useEffect(() => {
    if (countries) {
      const selectedCountry = options.find(
        (option) => option.value === selectedCountryCode
      );
      setSelectedCountry(selectedCountry);
    }
  }, [countries, selectedCountryCode]);

  const formatOptionLabel = ({ label, flag }) => (
    <OptionWrapper>
      <Flag className={`fi fi-${flag}`}></Flag>
      <CountryName>{label}</CountryName>
    </OptionWrapper>
  );

  return (
    <PhoneNumberInputWrapper>
      <Controller
        control={control}
        name="phoneCountryCode"
        render={({ field: { onChange, value } }) => (
          <Select
            value={selectedCountry}
            onChange={(selectedOption) => {
              setSelectedCountry(selectedOption);
              onChange(selectedOption.telCode);
            }}
            options={options}
            styles={telCodeSelectStyles}
            menuPortalTarget={document.body}
            formatOptionLabel={formatOptionLabel}
          />
        )}
      />

      <Controller
        control={control}
        name="phoneNumber"
        render={({ field: { onChange, value } }) => (
          <TextInput
            placeholder="Enter phone number"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </PhoneNumberInputWrapper>
  );
};
