import React from 'react';
import clsx from 'classnames';

const DrinkPreferenceSelector = ({ options, onChange, value }) => {
  const handleClickOption = (drinkType) => {
    if (value.includes(drinkType)) {
      onChange(value.filter((type) => type !== drinkType));
    } else {
      onChange([...value, drinkType]);
    }
  };

  return (
    <div className="flex items-start flex-wrap">
      {options.map((productType) => (
        <button
          key={productType.value}
          type="button"
          className="whitespace-nowrap mb-2 mr-2"
          onClick={() => handleClickOption(productType.value)}
        >
          <div
            className={clsx(
              'rounded-full bg-gray-light px-3 py-1.5 text-sm font-medium text-sm',
              {
                'bg-orange-br text-white': value.includes(productType.value),
              }
            )}
          >
            {productType.label}
          </div>
        </button>
      ))}
    </div>
  );
};

export default DrinkPreferenceSelector;
