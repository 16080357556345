import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Combobox} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import {useFormikContext} from 'formik';

export default function ComboBox(props) {
    const {setFieldValue} = useFormikContext();
    return (
        <Form.Group as={Row}>
            <Form.Label column sm="2">
                {props.label}
            </Form.Label>
            <Col sm="10">
                <Combobox
                    as="div"
                    value={props.value}
                    onChange={(option) => setFieldValue(props.name, option)}
                >
                    <Combobox.Input
                        as={Form.Control}
                        displayValue={(company) => company?.name}
                        onChange={(event) => props.onQueryChange(event.target.value)}
                    />
                    <Combobox.Button
                        className="absolute inset-y-0 right-2 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                    {props.options?.length > 0 && (
                        <Combobox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {props.options?.map((option) => (
                                <Combobox.Option
                                    key={option.id}
                                    value={option}
                                    className={({active}) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-orange-br text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({active, selected}) => (
                                        <>
                      <span
                          className={classNames(
                              'block truncate',
                              selected && 'font-semibold'
                          )}
                      >
                        {option.name}
                      </span>
                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    )}
                                                >
                          <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                        </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </Combobox>
            </Col>
        </Form.Group>
    );
}
