import React, { useContext, useEffect, useState } from 'react';
import useScript from 'react-script-hook';
import useSWR, { mutate } from 'swr';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { SiteForm } from '../components/SiteForm';
import { deletePlace, updateSite } from '../api/sites.api';
import { appConfig } from '../../common/app.config';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { fetcher } from '../../common/api/fetcher';
import { placeToSiteFormValues } from '../api/sites.dto';
import { UserContext } from '../../common/context/userContext';
import { PageFrame } from '../../common/components/PageFrame';

export const EditPlacePage = () => {
  const history = useHistory();
  const { id: siteId } = useParams();
  const [errorBox, setErrorBox] = useState(noError);
  const [site, setSite] = useState(null);
  const [scriptLoading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${appConfig.googleMaps.apiKey}&libraries=places`,
    checkForExisting: true,
  });
  const { isAdmin, companyId } = useContext(UserContext);
  const {
    data,
    error,
    isValidating: loading,
  } = useSWR(scriptLoading ? null : `/portal/places/${siteId}`, fetcher);

  useEffect(() => {
    const run = async () => {
      const _site = await placeToSiteFormValues(data);
      setSite(_site);
    };
    if (data) {
      run();
    }
  }, [data]);

  const handleSubmit = async (values) => {
    try {
      await updateSite(siteId, values);
      await mutate(`/portal/places/${siteId}`);
      await mutate(`/portal/places`);
      history.push('/places/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this site',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deletePlace(siteId);
      await mutate(`/portal/places`);
      history.push('/places/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this site',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading site',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Place">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {site && !scriptLoading && (
        <Formik
          initialValues={{
            ...(!isAdmin && { company: { id: companyId } }),
            ...site,
          }}
          onSubmit={handleSubmit}
        >
          <SiteForm onDelete={handleDelete} />
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
