import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Search } from '../svg/search.svg';
import { ReactComponent as Clear } from '../svg/clear.svg';

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  margin-right: 16px;
  position: relative;
`;

const SearchInput = styled.input`
  position: relative;
  width: 200px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
  padding-left: 31px;
  padding-right: 31px;
  box-sizing: border-box;

  &::placeholder {
    color: #a4a6af;
  }

  &:focus {
    outline: none;
    border: 1px solid #ed6921;
    box-shadow: 0 0 5px rgba(237, 105, 33, 0.4);
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  left: 8px;
  top: 9px;
`;

const ClearIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  right: 10px;
  top: 11px;
  cursor: pointer;
`;

export const SearchBar = ({ onChange, placeholder, ...props }) => {
  // show a cross icon to clear the search bar when there is text in it
  const [showClear, setShowClear] = React.useState(false);
  // control the input value
  const [value, setValue] = React.useState('');

  const handleChange = (e) => {
    // call the onChange function passed in from the parent component
    onChange(e.target.value);
    setValue(e.target.value);
    // if there is text in the search bar, show the clear icon
    if (e.target.value) {
      setShowClear(true);
    } else {
      setShowClear(false);
    }
  };

  return (
    <SearchBarContainer>
      <SearchInput
        type="text"
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        {...props}
      />
      <SearchIcon as={Search} />
      {showClear && (
        <ClearIcon
          as={Clear}
          onClick={() => handleChange({ target: { value: '' } })}
        />
      )}
    </SearchBarContainer>
  );
};
