import { useLayoutEffect, useState } from 'react';

export const useButtonPosition = (buttonRef) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        console.log(rect.left, rect.top, rect.width, rect.height);
        setPosition({ top: rect.top + rect.height + 6, left: rect.left });
      }
    };

    setTimeout(() => {
      updatePosition();
    }, 100);

    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [buttonRef]);

  return position;
};
