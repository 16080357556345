import styled from 'styled-components';
import { sidebarWidth, userProfileHeight } from '../../common.const';
import { Link, NavLink } from 'react-router-dom';

export const Container = styled.div`
  background-color: white;
  border-right: 1px solid #d9dbdd;
  height: 100vh;
  position: fixed;
  top: 0;
  width: ${sidebarWidth}px;
`;

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  padding: 8px;
  height: calc(100vh - ${userProfileHeight}px);
`;

export const Nav = styled.nav``;

export const LogoWrapper = styled(Link)`
  margin: 10px 0 34px 17px;
  display: block;
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const MenuItem = styled.li``;

export const MenuLink = styled(NavLink)`
  margin: 0 0 8px;
  border-radius: 5px;
  height: 32px;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 33px;
  color: #454a5c;
  text-decoration: none;

  &:hover {
    background-color: #fdf2ec;
    color: #ed6921;
    text-decoration: none;
  }

  &.active {
    background-color: #fdf2ec;
    color: #ed6921;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 6px;
  margin-left: 15px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: #454a5c;

  ${MenuLink}.active & {
    stroke: #ed6921;
  }

  ${MenuLink}:hover & {
    stroke: #ed6921;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 173px;
  margin: 24px 15px;
  background-color: #ecedee;
`;
