import {
  formValuesToCreatePushNotificationDto,
  formValuesToUpdatePushNotificationDto,
} from './pushNotifications.dto';
import { client } from '../../common/api/client';

export const createPushNotification = (values) => {
  const createPushNotificationDto =
    formValuesToCreatePushNotificationDto(values);
  return client.post(`/portal/push-notifications`, createPushNotificationDto);
};

export const updatePushNotification = (id, values) => {
  const updatePushNotificationDto =
    formValuesToUpdatePushNotificationDto(values);
  return client.patch(
    `/portal/push-notifications/${id}`,
    updatePushNotificationDto
  );
};

export const deletePushNotification = (id) => {
  return client.delete(`/portal/push-notifications/${id}`);
};
