import React from 'react';
import { Sidebar } from './Sidebar/Sidebar';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 1200px;
  width: 100%;
  overflow-x: scroll;
`;

export const AppFrame = ({ children }) => {
  return (
    <Container>
      {children}
      <Sidebar />
    </Container>
  );
};
