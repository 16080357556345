import React from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';

export const PlaceEditsDataList = () => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(`/portal/edits/places`, fetcher, {
    suspense: true,
  });

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching places, please try again"
        onClose={revalidate}
      />
    );
  }

  if (!data) return null;

  return (
    <Table striped bordered hover className="mt-3">
      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.id} className="cursor-pointer">
              <td onClick={() => history.push(`/edits/places/${item.id}`)}>
                <strong>{item.name}</strong> <small>{item.address}</small>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
