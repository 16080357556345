import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

import { getViewportSize } from '../../../lib/getViewportSize';
import Button from 'react-bootstrap/Button';

const ImageModal = ({ show, src, onHide }) => {
  const [screen] = useState(getViewportSize());

  return (
    <Modal centered size="lg" show={show} onHide={onHide}>
      <Modal.Body>
        <Image width={screen.width * 0.8} src={src} fluid />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="border-orange-br bg-orange-br" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
