import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
`;
export const Scrollable = styled.div`
  position: fixed;
  inset: 0;
  overflow-y: auto;
  min-width: 1200px;
`;
export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 64px;
  min-height: 100vh;
`;
export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 128px);
  max-width: 730px;
  box-shadow: 0px 0px 10px rgba(69, 74, 92, 0.25);
  border-radius: 10px;
`;
export const Header = styled.div`
  flex-shrink: 0;
  border-bottom: 1px solid #ecedee;
  padding: 24px 32px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Footer = styled.div`
  flex-shrink: 0;
  // Add any additional footer styles
  background: #f7f8f9;
  border-top: 1px solid #ecedee;
  border-radius: 0px 0px 10px 10px;

  padding: 16px 32px;

  width: 100%;
  height: 66px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const BackButton = styled.button`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: none;
  border: none;
  cursor: pointer;
  color: #454a5c;
  border-bottom: 1px solid #454a5c;
  padding-bottom: 3px;
`;
export const ContentArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px 32px 32px;
  // Add any additional content area styles
`;
export const StepIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #454a5c;
`;
export const StepText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;
`;
export const Title = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #454a5c;
  margin: 0;
`;
export const CloseButton = styled.button`
  width: 22px;
  height: 22px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;

  &:hover {
    background: #fdf2ec;
  }
`;
export const LabelText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7d808d;
  text-align: left;

  margin-top: 16px;
`;
export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 150px 500px;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
`;
export const ExplainerText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #a4a6af;
  text-align: left;
  max-width: 400px;
  display: block;
  margin-bottom: 16px;
`;
export const InputWrapper = styled.div`
  position: relative;
`;
export const InputError = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #e44e47;

  position: absolute;
  bottom: 105%;
  right: 8px;
`;
export const TextInput = styled.input`
  height: 48px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  padding-right: 31px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;

  &:focus {
    outline: none;
    border: 1px solid #ed6921;
    box-shadow: 0 0 5px rgba(237, 105, 33, 0.4);
  }

  ${({ $error }) =>
    $error &&
    css`
      border: 1px solid #e44e47;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background: #f7f8f9;
      border: 1px solid #ecedee;

      &:focus {
        outline: none;
        border: 1px solid #ecedee;
        box-shadow: none;
        pointer-events: none;
      }
    `}
`;
export const ClearIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  right: 12px;
  top: 18px;
  cursor: pointer;
`;
export const LinkButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
  padding-bottom: 3px;
  border-bottom: 1px solid #454a5c;
  margin-right: 8px;
`;
export const ButtonFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;
export const ErrorText = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #e44e47;
  margin: 0;
`;
