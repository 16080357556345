export const drinksMenuFormValuesToCreateDrinksMenuDto = (formValues) => {
  return {
    companyId: formValues.company?.id,
    name: formValues.name,
    products: Object.keys(formValues.products).map((productId) => {
      const { price, servedOnDraught } = formValues.products[productId];
      return {
        productId,
        price,
        servedOnDraught,
      };
    }),
  };
};

export const drinksMenuFormValuesToUpdateDrinksMenuDto =
  drinksMenuFormValuesToCreateDrinksMenuDto;

export const drinksMenuApiResponseToFormValues = (drinksMenu) => {
  return {
    company: drinksMenu.company,
    name: drinksMenu.name,
    products: drinksMenu.menuProducts.reduce(
      (products, menuProduct) => ({
        ...products,
        [menuProduct.productId]: {
          price: menuProduct.price,
          servedOnDraught: menuProduct.servedOnDraught,
        },
      }),
      {}
    ),
  };
};
