import { client } from '../../common/api/client';

export const approveBrandEdit = (brandEditId) => {
  return client.patch(`/portal/edits/brands/${brandEditId}/approve`);
};

export const denyBrandEdit = (brandEditId) => {
  return client.patch(`/portal/edits/brands/${brandEditId}/deny`);
};

export const approveProductEdit = (productEditId) => {
  return client.patch(`/portal/edits/products/${productEditId}/approve`);
};

export const denyProductEdit = (productEditId) => {
  return client.patch(`/portal/edits/products/${productEditId}/deny`);
};

export const approvePlaceEdit = (placeId) => {
  return client.patch(`/portal/edits/places/${placeId}/approve`);
};

export const denyPlaceEdit = (placeId) => {
  return client.patch(`/portal/edits/places/${placeId}/deny`);
};
