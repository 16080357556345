import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ChevronDownIcon } from '../../../common/svg/chevron-down.svg';
import { ReactComponent as CompanyIcon } from './company.svg';
import { ReactComponent as CountryIcon } from './country.svg';
import { ReactComponent as TypeIcon } from './type.svg';
import { ReactComponent as ProductIcon } from './product.svg';
import { ReactComponent as MenuIcon } from './menu.svg';
import { FilterButton } from './FilterButton';
import { SearchAndSelectFilter } from '../SearchAndSelectFilter/SearchAndSelectFilter';
import { useFilter } from '../../context/filterContext';
import { ListSelectFilter } from '../ListSelectFilter/ListSelectFilter';
import { RoleBasedComponent } from '../../../common/components/RoleBasedComponent';
import { UserRoles } from '../../../common/common.const';

const Container = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 12px;

  margin-left: -10px;
`;

export const FilterBar = () => {
  const { appliedFilters, setFilters } = useFilter();

  return (
    <>
      <Container>
        <RoleBasedComponent requiredRoles={[UserRoles.Admin]}>
          <FilterButton
            filterId="company"
            renderFilter={() => (
              <SearchAndSelectFilter
                appliedFilters={appliedFilters}
                onChange={setFilters}
                url="/portal/autocomplete/companies"
                placeholder="Search for a company"
                label="Select a company"
                property="company"
              />
            )}
          >
            <CompanyIcon />
            Company
            <ChevronDownIcon />
          </FilterButton>
        </RoleBasedComponent>

        <FilterButton
          filterId="country"
          renderFilter={() => (
            <ListSelectFilter
              appliedFilters={appliedFilters}
              onChange={setFilters}
              property="country"
              url="/countries/with-places"
            />
          )}
        >
          <CountryIcon />
          Country
          <ChevronDownIcon />
        </FilterButton>

        <FilterButton
          filterId="type"
          renderFilter={() => (
            <ListSelectFilter
              appliedFilters={appliedFilters}
              onChange={setFilters}
              property="type"
              url="/place-types/with-places"
            />
          )}
        >
          <TypeIcon />
          Type
          <ChevronDownIcon />
        </FilterButton>

        <FilterButton
          filterId="product"
          renderFilter={() => (
            <SearchAndSelectFilter
              appliedFilters={appliedFilters}
              onChange={setFilters}
              url="/portal/autocomplete/products"
              placeholder="Search for a product"
              label="Select a product"
              property="product"
            />
          )}
        >
          <ProductIcon />
          Product
          <ChevronDownIcon />
        </FilterButton>

        <FilterButton
          filterId="menu"
          renderFilter={() => (
            <SearchAndSelectFilter
              appliedFilters={appliedFilters}
              onChange={setFilters}
              url="/portal/autocomplete/drinks-menus"
              placeholder="Search for a menu"
              label="Select a menu"
              property="menu"
            />
          )}
        >
          <MenuIcon />
          Menu
          <ChevronDownIcon />
        </FilterButton>
      </Container>
    </>
  );
};
