import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { SiteForm, validateForm } from '../components/SiteForm';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { mutate } from 'swr';
import { createSite } from '../api/sites.api';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../common/context/userContext';
import { PageFrame } from '../../common/components/PageFrame';

export const AddPlacePage = () => {
  const history = useHistory();
  const { isAdmin, companyId } = useContext(UserContext);
  const [error, setError] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await createSite(values);
      await mutate('/portal/places');
      history.push('/places/list');
    } catch (e) {
      console.log(e);
      setError({
        title: 'An error occurred saving this site',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Site">
      <Formik
        initialValues={{ ...(!isAdmin && { company: { id: companyId } }) }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <SiteForm />
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
