import { useContext, useEffect } from 'react';
import { TitleContext } from '../context/titleContext';

export const useUpdateTitle = (title) => {
  const { updateTitle } = useContext(TitleContext);

  useEffect(() => {
    updateTitle(title);
  }, [updateTitle, title]);
};
