import { useHistory, useParams } from 'react-router-dom';
import { fetcher } from '../../common/api/fetcher';
import useSWR, { mutate } from 'swr';
import React, { Suspense, useEffect, useState } from 'react';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import {
  deletePushNotification,
  updatePushNotification,
} from '../api/pushNotifications.api';
import { pushNotificationsApiResponseToFormValues } from '../api/pushNotifications.dto';
import { Formik } from 'formik';
import {
  PushNotificationsForm,
  validateForm,
} from '../components/PushNotificationsForm';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

export const EditPushNotificationPage = () => {
  const history = useHistory();
  const { id: pushNotificationId } = useParams();
  const {
    data: pushNotification,
    error,
    isValidating: loading,
  } = useSWR(`/portal/push-notifications/${pushNotificationId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updatePushNotification(pushNotificationId, values);
      await mutate(`/portal/push-notifications/${pushNotificationId}`);
      await mutate('/portal/push-news');
      history.push('/push-news/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this brand',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deletePushNotification(pushNotificationId);
      await mutate('/portal/push-news');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred deleting this brand',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading location',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Push Notification">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {pushNotification && (
        <Formik
          initialValues={pushNotificationsApiResponseToFormValues(
            pushNotification
          )}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <PushNotificationsForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
