import { client } from '../../common/api/client';
import {
  placeFormValuesToCreatePlaceV2Dto,
  siteFormValuesToCreatePlaceDto,
} from './sites.dto';

export const createSite = async (values) => {
  const createPlaceDto = await siteFormValuesToCreatePlaceDto(values);
  return client.post('/portal/places', createPlaceDto);
};

export const createPlaceV2 = async (values) => {
  const createPlaceV2Dto = await placeFormValuesToCreatePlaceV2Dto(values);
  const { data } = await client.post('/portal/places/v2', createPlaceV2Dto);
  return data;
};

export const updatePlaceV2 = async (id, values) => {
  const updatePlaceV2Dto = await placeFormValuesToCreatePlaceV2Dto(values);
  const { data } = await client.patch(
    `/portal/places/v2/${id}`,
    updatePlaceV2Dto
  );
  return data;
};

export const updateSite = async (id, values) => {
  const updatePlaceDto = await siteFormValuesToCreatePlaceDto(values);
  return client.patch(`/portal/places/${id}`, updatePlaceDto);
};

export const deletePlace = async (id) => {
  return client.delete(`/portal/places/${id}`);
};

export const getPlaceIdsInDatabase = async (placeIds) => {
  const { data } = await client.get(
    `/portal/places/exists?id=${placeIds.join(',')}`
  );
  return data;
};
