import React from 'react';
import './styles.css';

export const TextArea = ({ name, value, onChange, label, error, ...props }) => {
  return (
    <div>
      <label htmlFor={name} className="bw-form-label">
        {label}
      </label>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        className="bw-form-input"
        {...props}
      />
      {error && <p className="text-xs text-red-600 mt-2">{error}</p>}
    </div>
  );
};
