import * as React from 'react';
import { Cross } from '../../../components/icons/Cross';
import { Chip } from '../../common/components/Chip';

export const PushNotificationsCountrySelectorChip = ({
  label,
  value,
  onClick,
}) => {
  return (
    <div className="relative">
      <Chip tabIndex={0}>{label}</Chip>
      <div className="absolute -top-0.5 -right-0.5 rounded-full w-4 h-4 border shadow-md bg-white flex items-center justify-center text-xs">
        <Cross
          size={6}
          className="cursor-pointer"
          onClick={() => onClick(value)}
        />
      </div>
    </div>
  );
};
