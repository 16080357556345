import {
  drinksMenuFormValuesToCreateDrinksMenuDto,
  drinksMenuFormValuesToUpdateDrinksMenuDto,
} from './drinksMenu.dto';
import { client } from '../../common/api/client';

export const createDrinksMenu = (values) => {
  const createDrinksMenuDto = drinksMenuFormValuesToCreateDrinksMenuDto(values);
  return client.post('/portal/drinks-menus', createDrinksMenuDto);
};

export const updateDrinksMenu = (id, values) => {
  const updateDrinksMenuDto = drinksMenuFormValuesToUpdateDrinksMenuDto(values);
  return client.patch(`/portal/drinks-menus/${id}`, updateDrinksMenuDto);
};

export const deleteDrinksMenu = (id) => {
  return client.delete(`/portal/drinks-menus/${id}`);
};
