import { Field, useFormikContext } from 'formik';
import DataSelect from '../../common/components/DataSelect';
import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DataInput from '../../common/components/DataInput';
import ImageSelector from '../../common/components/ImageSelector';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { DataSearch } from '../../common/components/DataSearch';
import { RetailersProductList } from './RetailersProductList';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import { UserContext } from '../../common/context/userContext';
import Spinner from 'react-bootstrap/Spinner';
import ComboBox from '../../common/components/ComboBox';

export const validateForm = (values) => {
  const errors = {};

  if (!values.company?.id) {
    errors.company = 'You must select a company';
  }

  if (!values.name) {
    errors.name = 'You must provide a name';
  }

  return errors;
};

export const RetailerForm = ({ onDelete }) => {
  const { handleSubmit, setFieldValue, values, isSubmitting } =
    useFormikContext();
  const { isAdmin } = useContext(UserContext);
  const history = useHistory();
  const [companyQuery, setCompanyQuery] = useState('');

  const { data: companies } = useSWR(
    isAdmin ? `/portal/companies?query=${companyQuery}` : null,
    fetcher,
    { suspense: false }
  );

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      {isAdmin && (
        <Field name="company">
          {({ field, meta }) => (
            <>
              <ComboBox
                {...field}
                id={field.name}
                label="Company"
                options={companies}
                query={companyQuery}
                onQueryChange={setCompanyQuery}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}

      {isAdmin && (
        <Field name="hidden">
          {({ field, meta }) => (
            <>
              <DataSelect
                id={field.name}
                label="Hide on app"
                type="select"
                buttons={false}
                options={[
                  { label: 'No', value: false, Type: 'boolean' },
                  { label: 'Yes', value: true, Type: 'boolean' },
                ]}
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}

      <Field name="name">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Name"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="url">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Website"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Logo"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>

      {isAdmin && (
        <Field name="ranking">
          {({ field, meta }) => (
            <>
              <DataSelect
                id={field.name}
                label="Ranking"
                type="select"
                buttons={false}
                options={[
                  { label: 'No ranking', value: null },
                  { label: '1st', value: 1 },
                  { label: '2nd', value: 2 },
                  { label: '3rd', value: 3 },
                ]}
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}

      {!!values.suggestedProducts?.length && (
        <div style={{ backgroundColor: '#f2fff4' }}>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Suggested Products to Stock
            </Form.Label>
            <Col sm="10">
              <Table striped bordered>
                <tbody>
                  {values.suggestedProducts.map((product) => (
                    <tr key={product.id}>
                      <td style={{ fontWeight: 'bold' }}>{product.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Form.Group>
          <hr />
        </div>
      )}

      <Field name="countries">
        {({ field }) => {
          return (
            <DataSearch
              label="Countries"
              url="/countries/supported"
              {...field}
              onChange={(productIds) => setFieldValue('countries', productIds)}
            />
          );
        }}
      </Field>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Products
        </Form.Label>
        <Col sm="10">
          <RetailersProductList />
        </Col>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="mr-3 border-orange-br bg-orange-br"
      >
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button
          variant="primary"
          type="button"
          className="border-orange-br bg-orange-br"
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </Form>
  );
};
