import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { mutate } from 'swr';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { createPushNotification } from '../api/pushNotifications.api';
import { Formik } from 'formik';
import {
  PushNotificationsForm,
  validateForm,
} from '../components/PushNotificationsForm';
import { PageFrame } from '../../common/components/PageFrame';

export const AddPushNotificationPage = () => {
  const history = useHistory();
  const [error, setError] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await createPushNotification(values);
      await mutate('/portal/push-news');
      history.push('/push-news/list');
    } catch (e) {
      console.log(e);
      setError({
        title: 'An error occurred saving this brand',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Push Notification">
      <Formik
        initialValues={{ productTypeIds: [], linkedType: '' }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <PushNotificationsForm />
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
