import styled from 'styled-components';
import { ReactComponent as NoResultsGraphic } from '../svg/no-results-graphic.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GraphicWrapper = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #454a5c;
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #454a5c;
`;

export const NoResults = () => {
  return (
    <Container>
      <GraphicWrapper>
        <NoResultsGraphic />
      </GraphicWrapper>
      <Title>No results</Title>
      <Text style={{ marginBottom: 8 }}>
        Sorry, we couldn’t find what you’re looking for.
      </Text>
      <Text>Try searching for something else.</Text>
    </Container>
  );
};
