import Button from 'react-bootstrap/Button';
import React, { useRef, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { ProductsDataList } from './ProductsDataList';

export const DrinksMenuProductSelector = ({ onSelect }) => {
  const [showList, setShowList] = useState(false);
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const searchRef = useRef();

  return (
    <div>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setShowList(true)}
      >
        Add
      </Button>
      {showList && (
        <div>
          <div className="DataTwoArrays-perPage">
            <InputGroup>
              <FormControl
                as="input"
                placeholder="search"
                size="sm"
                ref={searchRef}
              />
              <Button
                variant="secondary"
                className="border-orange-br bg-orange-br"
                type="button"
                onClick={() => setQuery(searchRef.current.value)}
              >
                Search
              </Button>
              <span>Show</span>
              <FormControl
                as="select"
                type="select"
                size="sm"
                defaultValue={limit}
                onChange={function (e) {
                  setLimit(Number(e.target.value));
                }}
              >
                <option>2</option>
                <option>5</option>
                <option>10</option>
                <option>20</option>
                <option>50</option>
                <option>100</option>
                <option>1000</option>
              </FormControl>
            </InputGroup>
          </div>
          <ProductsDataList
            query={query}
            offset={offset}
            limit={limit}
            onSelect={onSelect}
          />
          <Button
            variant="secondary"
            type="button"
            className="mr-3 border-orange-br bg-orange-br"
            disabled={offset === 0}
            onClick={() => setOffset(offset - limit)}
          >
            Previous
          </Button>
          <Button
            variant="secondary"
            type="button"
            className="mr-3 border-orange-br bg-orange-br"
            onClick={() => setOffset(offset + limit)}
          >
            Next
          </Button>
          <Button
            variant="secondary"
            type="button"
            className="border-orange-br bg-orange-br"
            onClick={() => setShowList(false)}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
};
