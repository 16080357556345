import React, { useContext, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { UserContext } from '../../common/context/userContext';

import DataSelect from '../../common/components/DataSelect';
import DataInput from '../../common/components/DataInput';
import ImageSelector from '../../common/components/ImageSelector';
import { SiteAddressAutocompleteInput } from './SiteAddressAutocompleteInput';
import Button from 'react-bootstrap/Button';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';
import ComboBox from '../../common/components/ComboBox';

export const validateForm = (values) => {
  const errors = {};
  if (!values.company?.id) {
    errors.company = 'You must select a company';
  }
  if (!values.name) {
    errors.name = 'You must provide a name';
  }
  if (!values.placeTypes?.length) {
    errors.placeTypes = 'You must select at least one type';
  }
  if (!values.address) {
    errors.address = 'You must provide an address';
  }
  return errors;
};

export const SiteForm = ({ onDelete }) => {
  const history = useHistory();
  const { isAdmin } = useContext(UserContext);
  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormikContext();
  const [companyQuery, setCompanyQuery] = useState('');
  const { data: drinksMenus, isValidating: drinksMenusLoading } = useSWR(
    values?.company?.id
      ? `/portal/drinks-menus?limit=500&companyId=${values.company?.id}`
      : null,
    fetcher
  );
  const { data: placeTypes } = useSWR('/place-types', fetcher);
  const { data: companies } = useSWR(
    isAdmin ? `/portal/companies?query=${companyQuery}` : null,
    fetcher
  );

  const companyName = values.company?.name;

  return (
    <Form onSubmit={handleSubmit}>
      {isAdmin && (
        <Field name="company">
          {({ field, meta }) => (
            <>
              <ComboBox
                {...field}
                id={field.name}
                label="Company"
                options={companies}
                query={companyQuery}
                onQueryChange={setCompanyQuery}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
      )}
      <Field name="name">
        {({ field, meta }) => (
          <>
            <DataInput
              id={field.name}
              label="Name"
              placeholder="Site name"
              {...field}
            />
            {meta.error && (
              <div className="text-red-500 text-xs mt-2 mb-3">{meta.error}</div>
            )}
          </>
        )}
      </Field>
      <Field name="placeTypes" type="checkbox">
        {({ field, meta }) => {
          return (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Types
              </Form.Label>
              <Col sm="10">
                <ToggleButtonGroup
                  style={{ flexWrap: 'wrap' }}
                  type="checkbox"
                  name={field.name}
                  value={field.value}
                  onChange={(values) => {
                    setFieldValue(field.name, values);
                  }}
                >
                  {placeTypes?.map((placeType) => (
                    <ToggleButton
                      key={placeType.id}
                      variant="outline-secondary"
                      value={placeType.id}
                    >
                      {placeType.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                {meta.error && (
                  <div className="text-red-500 text-xs mt-2 mb-3">
                    {meta.error}
                  </div>
                )}
              </Col>
            </Form.Group>
          );
        }}
      </Field>

      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Image"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>

      {isEmpty(values.company) && (
        <div className="pt-4 pb-6">
          Please select a company to see drinks menus
        </div>
      )}

      {!isEmpty(values.company) &&
        !drinksMenusLoading &&
        isEmpty(drinksMenus) && (
          <div className="pt-4 pb-6">
            No Drinks Menus Available - please create one or more for{' '}
            {companyName}
          </div>
        )}

      {drinksMenus && (
        <Field name="drinksMenuId">
          {({ field }) => (
            <DataSelect
              id={field.name}
              label="Drinks Menu"
              type="select"
              buttons={false}
              options={[
                { value: '', label: 'Select a drinks menu' },
                ...drinksMenus?.map((drinksMenu) => ({
                  value: drinksMenu.id,
                  label: drinksMenu.name,
                })),
              ]}
              {...field}
            />
          )}
        </Field>
      )}

      <Field name="url">
        {({ field }) => (
          <DataInput
            id={field.name}
            label="Website"
            placeholder=""
            {...field}
          />
        )}
      </Field>

      <Field name="phoneNumber">
        {({ field }) => (
          <DataInput
            id={field.name}
            label="Phone Number"
            placeholder=""
            {...field}
          />
        )}
      </Field>

      <Field name="emailAddress">
        {({ field }) => (
          <DataInput
            id={field.name}
            label="Business Email"
            placeholder=""
            {...field}
          />
        )}
      </Field>

      <Field name="address">
        {({ field, meta }) => {
          return (
            <>
              {' '}
              <SiteAddressAutocompleteInput
                id={field.name}
                label="Address"
                {...field}
                value={field.value}
                onChange={(value) => {
                  setFieldValue('address', value);
                }}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      {onDelete && (
        <Field name="what3Words">
          {({ field }) => (
            <DataInput
              id={field.name}
              label="What3Words"
              placeholder=""
              readOnly
              {...field}
            />
          )}
        </Field>
      )}

      <Button
        variant="primary"
        type="submit"
        className="mr-3 border-orange-br bg-orange-br"
      >
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button
          variant="primary"
          type="button"
          className="border-orange-br bg-orange-br"
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </Form>
  );
};
