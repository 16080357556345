import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { Formik } from 'formik';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { CompanyForm, validateForm } from '../components/CompanyForm';
import { deleteCompany, updateCompany } from '../api/companies.api';
import { PageFrame } from '../../common/components/PageFrame';

export const EditCompanyPage = () => {
  const history = useHistory();
  const { id: companyId } = useParams();
  const {
    data: company,
    error,
    isValidating: loading,
  } = useSWR(`/portal/companies/${companyId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateCompany(companyId, values);
      await mutate(`/portal/companies/${companyId}`);
      await mutate(`/portal/companies`);
      history.push('/companies/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this location',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCompany(companyId);
      await mutate(`/portal/companies`);
      history.push('/companies/list');
    } catch (e) {
      if (e.response.status === 409) {
        setErrorBox({
          title: 'An error occurred deleting this company',
          msg: 'This brand has associated entities, please remove them and try again',
        });
        return;
      }

      setErrorBox({
        title: 'An error occurred deleting this brand',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading location',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Company">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {company && (
        <Formik
          initialValues={company}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          <CompanyForm onDelete={handleDelete} />
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
