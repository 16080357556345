import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }) => {
  const portalRef = useRef(document.createElement('div'));

  useEffect(() => {
    const portalDiv = portalRef.current;
    document.body.appendChild(portalDiv);

    return () => {
      document.body.removeChild(portalDiv);
    };
  }, []);

  return ReactDOM.createPortal(children, portalRef.current);
};
