import styled, { css } from 'styled-components';
import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash/function';
import { ReactComponent as Clear } from '../../../common/svg/clear.svg';
import googleAttributionImage from './google_on_white_hdpi.png';
import { getPlaceIdsInDatabase } from '../../api/sites.api';

const SearchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const SearchInput = styled.input`
  height: 48px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  padding-right: 31px; /* Make room for the button */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;
  margin-bottom: 10px;

  &:focus {
    outline: none;
    border: 1px solid #ed6921;
    box-shadow: 0 0 5px rgba(237, 105, 33, 0.4);
  }
`;

const ClearIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  right: 13px;
  top: 18px;
  cursor: pointer;
`;

const ResultsList = styled.ul`
  border: 1px solid #ed6921;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  position: relative;

  padding-bottom: 32px;
`;

const InUseLabel = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #a4a6af;
  margin-bottom: 2px;
  display: block;
  margin-top: -2px;
`;

const GoogleAttributionImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 12px;
  margin: 10px;
`;

const ResultItem = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;
  padding: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: #fdf2ec;
    cursor: pointer;
  }

  ${({ inUse }) =>
    inUse &&
    css`
      background: #f7f8f9;
      color: #a4a6af;

      &:hover {
        background: #f7f8f9;
        cursor: not-allowed;
        color: #a4a6af;
      }
    `}
`;

export const AddressSearchInput = ({
  onSearch,
  onSelectAddress,
  searchTerm,
  onSearchTermChange,
  searchResults,
  onSearchResultsChange,
}) => {
  const handleInputChange = (e) => {
    onSearchTermChange(e.target.value);
  };

  const handleClearInput = () => {
    onSearchTermChange('');
    onSearchResultsChange([]);
  };

  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      if (onSearch) {
        const results = await onSearch(searchTerm);
        const placeIds = results.map((result) => result.placeId);
        const inUsePlaceIds = new Set(await getPlaceIdsInDatabase(placeIds));
        for (let result of results) {
          result.inUse = inUsePlaceIds.has(result.placeId);
        }
        onSearchResultsChange(results);
      }
    }, 300),
    [searchTerm]
  );

  const handleResultClick = (result) => {
    onSelectAddress(result);
  };

  const hasResults = searchResults.length > 0;

  useEffect(() => {
    if (searchTerm.length > 3) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);

  return (
    <>
      <SearchWrapper>
        <SearchInput
          placeholder="Start typing name or address"
          value={searchTerm}
          onChange={handleInputChange}
          type="text"
        />
        {searchTerm && (
          <ClearIcon as={Clear} onClick={handleClearInput}>
            X
          </ClearIcon>
        )}
      </SearchWrapper>
      {hasResults && (
        <ResultsList>
          {searchResults.map((result) => (
            <ResultItem
              key={result.placeId}
              onClick={() => {
                if (!result.inUse) {
                  handleResultClick(result);
                }
              }}
              inUse={result.inUse}
            >
              {result.inUse && <InUseLabel>Place already in use</InUseLabel>}
              {result.label}
            </ResultItem>
          ))}
          <GoogleAttributionImage
            src={googleAttributionImage}
            alt="Powered by Google"
          />
        </ResultsList>
      )}
    </>
  );
};
