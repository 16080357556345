import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { UsersDataList } from '../components/UsersDataList';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { fetcher } from '../../common/api/fetcher';
import { PageFrame } from '../../common/components/PageFrame';
import { PrimaryButton } from '../../common/components/PrimaryButton';
import { AddButton } from '../../common/components/AddButton';
import { SearchBar } from '../../common/components/SearchBar';

export const UsersListPage = () => {
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const handleDownloadUsersCsv = async () => {
    const data = await fetcher(`/portal/users/csv`);
    const csvFile = `data:text/csv;charset=utf-8,${data}`;
    const encodedUri = encodeURI(csvFile);
    const link = document.createElement('a');
    const fileName = `bw-users-${new Date().toISOString()}.csv`;

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    link.click();
  };

  return (
    <PageFrame
      title="Users"
      topBarContent={
        <>
          <SearchBar placeholder="Search users" onChange={setQuery} />
          <PrimaryButton
            onClick={handleDownloadUsersCsv}
            text="Download all users (csv)"
          />
          <AddButton text="Add User" to="/users/add" />
        </>
      }
    >
      <ListSearchAndLimit
        setLimit={setLimit}
        limit={limit}
        onSearch={setQuery}
      />
      <UsersDataList offset={offset} limit={limit} query={query} />
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </PageFrame>
  );
};
