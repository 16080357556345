import React, { useCallback, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import Spinner from 'react-bootstrap/Spinner';
import 'react-image-crop/dist/ReactCrop.css';

import { ReactComponent as CancelCrop } from '../svg/cancel-crop.svg';
import { ReactComponent as ConfirmCrop } from '../svg/confirm-crop.svg';

import { getViewportSize } from '../../../lib/getViewportSize';
import { getCanvasBlob } from '../../../lib/getCanvasBlob';
import styled from 'styled-components';
import { ExplainerText } from '../../places/components/AddAPlaceModal/styles';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;

const InvisibleButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 8px;
`;

const ImageCropper = ({ src, onConfirm, onCancel, loading }) => {
  const [screen] = useState(getViewportSize());
  const [crop, setCrop] = useState({ aspect: 1 });
  const imageRef = useRef(null);

  const handleImageLoaded = useCallback((_sourceImage) => {
    const width = _sourceImage.naturalWidth;
    const height = _sourceImage.naturalHeight;
    const ratio = width / height;
    const _crop = { unit: 'px', aspect: 1, x: 0, y: 0 };

    if (ratio > 1) {
      _crop.height = height;
    } else {
      _crop.width = width;
    }

    setCrop(_crop);
    imageRef.current = _sourceImage;
    return false;
  }, []);

  // returns a blob
  const handleConfirm = async () => {
    const canvas = document.createElement('canvas');
    const image = imageRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    try {
      const blob = await getCanvasBlob(canvas);
      onConfirm(blob);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <ReactCrop
        src={src}
        crop={crop}
        onChange={setCrop}
        maxWidth={screen.width}
        onImageLoaded={handleImageLoaded}
      />
      <CaptionContainer>
        <ExplainerText style={{ maxWidth: 340 }}>
          Drag the corners of the box to crop your image and click the tick to
          confirm.
        </ExplainerText>
        <ButtonContainer>
          <InvisibleButton onClick={onCancel} type="button">
            <CancelCrop />
          </InvisibleButton>
          <InvisibleButton onClick={handleConfirm} type="button">
            {loading ? (
              <Spinner
                style={{ color: '#2AC6C6' }}
                animation="border"
                size="sm"
              />
            ) : (
              <ConfirmCrop />
            )}
          </InvisibleButton>
        </ButtonContainer>
      </CaptionContainer>
    </div>
  );
};

export default ImageCropper;
