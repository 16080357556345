import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import React, { Suspense, useEffect, useState } from 'react';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { Formik } from 'formik';
import { UserForm, validateForm } from '../components/UserForm';
import { userApiResponseToFormValues } from '../api/users.dto';
import { deleteUser, updateUser } from '../api/users.api';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

export const EditUserPage = () => {
  const history = useHistory();
  const { id: userId } = useParams();
  const { data: user, error } = useSWR(`/portal/users/${userId}`, fetcher, {
    suspense: true,
  });
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateUser(userId, values);
      await mutate(`/portal/users/${userId}`);
      await mutate(`/portal/users`);
      history.push('/users/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this user',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUser(userId);
      await mutate(`/portal/users`);
      history.push('/users/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred deleting this user',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading user',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit User">
      {user && (
        <Formik
          initialValues={userApiResponseToFormValues(user)}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <UserForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
