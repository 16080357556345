import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { isEmpty } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';

export const formatFormAddress = async (brandAddress) => {
  if (isEmpty(brandAddress)) return null;
  try {
    const [address] = await geocodeByAddress(brandAddress);
    return {
      label: address.formatted_address,
      value: {
        description: address.formatted_address,
      },
    };
  } catch (e) {
    return null;
  }
};

export const placeToSiteFormValues = async (dbPlace) => {
  try {
    const address = await formatFormAddress(dbPlace.address);
    return {
      address,
      what3Words: dbPlace.what3Words,
      company: dbPlace.company,
      name: dbPlace.name,
      imageUrl: dbPlace.imageUrl,
      placeTypes: dbPlace.placeTypes?.map((placeType) => placeType.id) || [],
      url: dbPlace.url,
      drinksMenuId: dbPlace.drinksMenuId,
      emailAddress: dbPlace.emailAddress,
      phoneNumber: dbPlace.phoneNumber,
      drinksMenu: dbPlace.drinksMenu,
    };
  } catch (e) {
    console.log(e);
    console.log('problem parsing database site record');
  }
};

const IMAGE_PLACEHOLDER_URL = '/assets/places-placeholder.png';

export const placeToPlaceFormValues = (dbPlace) => {
  let phoneNumber = '';
  let phoneCountryCode = '';

  if (dbPlace.phoneNumber) {
    const number = parsePhoneNumber(
      dbPlace.phoneNumber,
      dbPlace.country.code || 'GB'
    );

    if (number) {
      phoneNumber = number.nationalNumber;
      phoneCountryCode = number.countryCallingCode;
    }
  }

  let imageUrl = null;

  // if the dbPlace has an imageUrl, and it's not the placeholder, use that
  if (dbPlace.imageUrl && dbPlace.imageUrl !== IMAGE_PLACEHOLDER_URL) {
    imageUrl = dbPlace.imageUrl;
  }

  return {
    id: dbPlace.id,
    name: dbPlace.name,
    addressLine1: dbPlace.addressLine1,
    addressLine2: dbPlace.addressLine2,
    city: dbPlace.city,
    stateRegion: dbPlace.stateRegion,
    postalCode: dbPlace.postalCode,
    country: dbPlace.country.code,
    company: dbPlace.company,
    email: dbPlace.emailAddress,
    phoneNumber: phoneNumber,
    phoneCountryCode: phoneCountryCode,
    drinksMenu: dbPlace.drinksMenu,
    imageUrl: imageUrl,
    url: dbPlace.url,
    placeTypes: dbPlace.placeTypes?.map((placeType) => placeType.slug) || [],
    googlePlaceId: dbPlace.googlePlaceId,
    what3Words: dbPlace.what3Words,
  };
};

const getCountryCode = (addressComponents) => {
  const countryCodeComponent = addressComponents.find((component) =>
    component.types.includes('country')
  );
  return countryCodeComponent?.short_name;
};

export const siteFormValuesToCreatePlaceDto = async (formValues) => {
  const [address] = await geocodeByAddress(
    formValues.address.value.description
  );
  const countryCode = getCountryCode(address.address_components);
  const location = await getLatLng(address);
  return {
    companyId: formValues.company?.id,
    name: formValues.name,
    placeTypes: formValues.placeTypes,
    imageUrl: formValues.imageUrl,
    drinksMenuId: formValues.drinksMenuId,
    url: formValues.url,
    address: address.formatted_address,
    countryCode,
    location: {
      lat: location.lat.toString(),
      lng: location.lng.toString(),
    },
  };
};

// phoneNumber comes in as international format, e.g. +44 1234 567890
// we need to strip out the +44 and replace with nothing, and then strip out spaces
// so we end up with 1234567890, and have the country code separately in phoneCountryCode

export const placeFormValuesToCreatePlaceV2Dto = async (formValues) => {
  return {
    name: formValues.name,
    addressComponents: {
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2,
      city: formValues.city,
      stateRegion: formValues.stateRegion,
      postalCode: formValues.postalCode,
      countryCode: formValues.country,
    },
    company: {
      id: formValues.company?.id,
      name: formValues.company?.name,
    },
    drinksMenu: {
      id: formValues.drinksMenu.id,
      name: formValues.drinksMenu.name,
    },
    location: formValues.location,
    imageUrl: formValues.imageUrl,
    placeTypes: formValues.placeTypes,
    phoneNumber: `${formValues.phoneCountryCode} ${formValues.phoneNumber}`,
    emailAddress: formValues.email,
    url: formValues.url,
    what3Words: formValues.what3words,
    googlePlaceId: formValues.googlePlaceId,
  };
};
