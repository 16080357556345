import React, { useEffect, useState } from 'react';
import { BrandEditForm } from '../components/BrandEditForm';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { approveBrandEdit, denyBrandEdit } from '../api/edits.api';
import { brandsApiResponseToFormValues } from '../../brands/api/brands.dto';
import useScript from 'react-script-hook';
import { appConfig } from '../../common/app.config';
import { PageFrame } from '../../common/components/PageFrame';

export const ViewBrandEditPage = () => {
  const history = useHistory();
  const { id: brandEditId } = useParams();
  const [brandEdit, setBrandEdit] = useState(false);
  const [scriptLoading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${appConfig.googleMaps.apiKey}&libraries=places`,
    checkForExisting: true,
  });
  const { data, error } = useSWR(
    !scriptLoading ? `/portal/edits/brands/${brandEditId}` : null,
    fetcher
  );
  const [errorBox, setErrorBox] = useState(noError);
  const [loadingValue, setLoadingValue] = useState(null);

  const handleApprove = async () => {
    setLoadingValue('approve');
    await approveBrandEdit(brandEditId);
    await mutate(`/portal/edits/brands`);
    await mutate(`/portal/edits`);
    history.push('/edits/list');
  };

  const handleDeny = async () => {
    setLoadingValue('approve');
    await denyBrandEdit(brandEditId);
    await mutate(`/portal/edits/brands`);
    await mutate(`/portal/edits`);
    history.push('/edits/list');
  };

  useEffect(() => {
    const run = async () => {
      const _brandEdit = await brandsApiResponseToFormValues(data);
      setBrandEdit(_brandEdit);
    };
    if (data) {
      run();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading brand',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="View Brand Edit">
      {!brandEdit && <LoadingSpinner />}
      {brandEdit && (
        <BrandEditForm
          onApprove={handleApprove}
          onDeny={handleDeny}
          value={brandEdit}
          loadingValue={loadingValue}
        />
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
