export const userCountries = [
  'Australia',
  'Canada',
  'United Kingdom',
  'United States',
];

export const UserRoles = {
  Admin: 'Admin',
  BrandManager: 'BrandManager',
  User: 'User',
  LocationsManager: 'LocationManager',
  RetailManager: 'RetailManager',
  CompanyManager: 'CompanyManager',
};

export const UserAdminIds = [
  '172872cb-7588-4c2c-b36d-00b9fe3965e7',
  'de7559e8-e20a-4325-a257-dd91815f442d',
  'df337029-6017-4bc4-9869-3c75a5440dc1',
  '25ebec4f-a058-4b08-b99e-ba4b25e868fe',
];

export const topBarHeight = 58;

export const extendedTopBarHeight = 102;

export const sidebarWidth = 236;
export const userProfileHeight = 66;
