import get from 'lodash/get';

export const formValuesToCreatePushNotificationDto = (formValues) => {
  const ageRange =
    formValues.targetAge === ''
      ? { minAge: null, maxAge: null }
      : { maxAge: formValues.maxAge, minAge: formValues.minAge };

  const productId = get(formValues, 'data.productId.id', null);
  const placeId = get(formValues, 'data.placeId.id', null);
  const newsId = get(formValues, 'data.newsId.id', null);

  const hasLinkedData = productId || placeId || newsId;

  return {
    headline: formValues.headline,
    brandId: formValues.brandId,
    body: formValues.body,
    ...(formValues.sendTo === 'all'
      ? {
          sendTo: formValues.sendTo,
          productTypeIds: [],
          countryId: null,
          gender: null,
          minAge: null,
          maxAge: null,
        }
      : {
          ...ageRange,
          sendTo: formValues.sendTo,
          productTypeIds: formValues.productTypeIds || [],
          countryId: formValues.countryId,
          gender: transformGender(formValues.gender),
        }),
    ...(hasLinkedData
      ? {
          data: {
            ...(productId && { productId }),
            ...(placeId && { placeId }),
            ...(newsId && { newsId }),
          },
        }
      : { data: {} }),
    productTypeIds: formValues.productTypeIds || [],
    countryId: formValues.countryId,
    maxAge: formValues.maxAge,
    minAge: formValues.minAge,
    sendAt: formValues.sendAt.toISOString(),
  };
};

const transformGender = (gender) => {
  if (gender === 'Male') return 0;
  if (gender === 'Female') return 1;
  return null;
};

export const formValuesToUpdatePushNotificationDto = (formValues) => {
  const ageRange =
    formValues.targetAge === ''
      ? { minAge: null, maxAge: null }
      : { maxAge: formValues.maxAge, minAge: formValues.minAge };

  const productId = get(formValues, 'data.productId.id', null);
  const placeId = get(formValues, 'data.placeId.id', null);
  const newsId = get(formValues, 'data.newsId.id', null);

  const hasLinkedData = productId || placeId || newsId;

  return {
    headline: formValues.headline,
    brandId: formValues.brandId,
    body: formValues.body,
    ...(formValues.sendTo === 'all'
      ? {
          sendTo: formValues.sendTo,
          productTypeIds: [],
          countryId: null,
          gender: null,
          minAge: null,
          maxAge: null,
        }
      : {
          ...ageRange,
          sendTo: formValues.sendTo,
          productTypeIds: formValues.productTypeIds || [],
          countryId: formValues.countryId,
          gender: transformGender(formValues.gender),
        }),
    ...(hasLinkedData
      ? {
          data: {
            ...(productId && { productId }),
            ...(placeId && { placeId }),
            ...(newsId && { newsId }),
          },
        }
      : { data: {} }),
    sendAt: formValues.sendAt.toISOString(),
  };
};

export const pushNotificationsApiResponseToFormValues = (pushNotification) => {
  const transformGender = (gender) => {
    if (gender === 0) return 'Male';
    if (gender === 1) return 'Female';
    return '';
  };

  let linkedType = '';
  if (get(pushNotification, 'data.productId')) {
    linkedType = 'product';
  } else if (get(pushNotification, 'data.placeId')) {
    linkedType = 'place';
  } else if (get(pushNotification, 'data.newsId')) {
    linkedType = 'news';
  }

  return {
    headline: pushNotification.headline,
    brandId: pushNotification.brandId,
    body: pushNotification.body,
    sendTo: pushNotification.sendTo,
    productTypeIds: pushNotification.productTypes?.map(({ id }) => id) || [],
    countryId: pushNotification.countries?.[0]?.id,
    ...(pushNotification.maxAge && pushNotification.minAge
      ? {
          targetAge: 'ageRange',
          minAge: pushNotification.minAge,
          maxAge: pushNotification.maxAge,
        }
      : { targetAge: '' }),
    gender: transformGender(pushNotification.gender),
    sendAt: new Date(pushNotification.sendAt),
    status: pushNotification.status,
    data: pushNotification.data,
    linkedType,
  };
};
