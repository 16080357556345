import React from 'react';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';
import { useHistory, useParams } from 'react-router-dom';

export const ProductReviewsDataList = ({ offset, limit }) => {
  const { id: productId } = useParams();
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/reviews?${stringify({
      productId,
      offset,
      limit,
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching companies, please try again"
        onClose={revalidate}
      />
    );
  }

  return (
    <Table striped bordered hover>
      <tbody>
        {data.map((item) => (
          <tr
            key={item.id}
            onClick={() => history.push(`/reviews/${item.id}`)}
            className="cursor-pointer"
          >
            <td>{item.createdAt}</td>
            <td>{item.user?.name}</td>
            <td>{item.title}</td>
            <td>{item.body}</td>
            <td>{item.starRating}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
