import { useSigninCheck } from 'reactfire';
import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { UserContext } from '../context/userContext';
import { NotFound } from './NotFound';

export const PrivateRoute = ({
  children,
  allowedRoles = [],
  allowedIds = [],
  ...rest
}) => {
  const location = useLocation();
  const { data: signInCheckResult } = useSigninCheck({ suspense: true });
  const { role, user } = useContext(UserContext);
  const [isUserAllowedToView, setIsUserAllowedToView] = useState(false);

  useEffect(() => {
    const hasRolesList = allowedRoles?.length > 0;
    const hasIdsList = allowedIds?.length > 0;
    const userHasCorrectRole = hasRolesList
      ? allowedRoles.includes(role)
      : true;
    const userHasCorrectId = hasIdsList ? allowedIds.includes(user.id) : true;

    setIsUserAllowedToView(userHasCorrectRole && userHasCorrectId);
  }, [allowedRoles, allowedIds, role, user]);

  if (!signInCheckResult.signedIn)
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );

  return (
    <Route
      {...rest}
      render={() => (isUserAllowedToView ? children : <NotFound />)}
    />
  );
};
