import React from 'react';

export const Chip = ({ children, className, ...props }) => {
  return (
    <div
      className={`rounded-full bg-orange-br text-white px-3 py-1.5 text-sm font-medium text-sm ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};
