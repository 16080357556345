import React, { Suspense, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { mutate } from 'swr';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { UserContext } from '../../common/context/userContext';
import { Formik } from 'formik';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { GiveawayForm, validateForm } from '../components/GiveawayForm';
import { createGiveaway } from '../api/giveaways.api';
import { PageFrame } from '../../common/components/PageFrame';

export const AddGiveawayPage = () => {
  const history = useHistory();
  const [error, setError] = useState(noError);
  const { user } = useContext(UserContext);

  const handleSubmit = async (values) => {
    try {
      await createGiveaway(values);
      await mutate('/portal/giveaways');
      history.push('/giveaways/list');
    } catch (e) {
      console.log(e);
      setError({
        title: 'An error occurred saving this giveaway',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Giveaway">
      <Formik
        initialValues={{
          productIds: [],
          countryIds: [],
          createdBy: user.name,
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <GiveawayForm />
        </Suspense>
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
