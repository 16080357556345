import { client } from '../../common/api/client';
import {
  formValuesToCreateUserDto,
  formValuesToUpdateUserDto,
} from './users.dto';

export const createUser = (values) => {
  const createUserDto = formValuesToCreateUserDto(values);
  return client.post(`/portal/users`, createUserDto);
};

export const updateUser = (id, values) => {
  const updateUserDto = formValuesToUpdateUserDto(values);
  return client.patch(`/portal/users/${id}`, updateUserDto);
};

export const deleteUser = (id) => {
  return client.delete(`/portal/users/${id}`);
};
