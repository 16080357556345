export const formValuesToCreateProductDto = (formValues) => {
  return {
    name: formValues.name,
    brandId: formValues.brandId,
    companyId: formValues.company.id,
    productTypeId: formValues.productTypeId,
    availableOnDraught: formValues.availableOnDraught === 'true',
    productTypeVariants: formValues.productTypeVariants,
    suitableFor: formValues.suitableFor,
    countryId: formValues.countryId,
    description: formValues.description,
    caloriesPerServing: parseInt(formValues.caloriesPerServing, 10),
    servingMl: parseInt(formValues.servingMl, 10),
    abv: parseFloat(formValues.abv),
    imageUrl: formValues.imageUrl,
    foodPairings: formValues.foodPairings,
    awards: formValues.awards,
    other: formValues.other,
    ingredients: formValues.ingredients,
    suggestedStockists: formValues.suggestedStockists,
    productPairings: formValues.productPairings,
    ...(formValues.soldAt
      ? {
          soldAt: Object.keys(formValues.soldAt)?.map((drinksMenuId) => {
            const { price, servedOnDraught } = formValues.soldAt[drinksMenuId];
            return {
              drinksMenuId,
              price,
              servedOnDraught,
            };
          }),
        }
      : {}),
  };
};

export const productsApiResponseToFormValues = (product) => {
  return {
    name: product.name,
    company: product.company,
    brandId: product.brand?.id,
    productTypeId: product.productType?.id,
    availableOnDraught: product.availableOnDraught ? 'true' : 'false',
    productTypeVariants: product.productTypeVariants?.map(({ id }) => id),
    suitableFor: product.suitableFor?.map(({ id }) => id),
    countryId: product.country?.id,
    description: product.description,
    caloriesPerServing: product.caloriesPerServing,
    servingMl: product.servingMl,
    abv: `${product.abv}`,
    imageUrl: product.imageUrl,
    foodPairings: product.foodPairings?.map(({ id }) => id),
    productPairings: product.productPairings?.map(({ id }) => id),
    awards: product.awards,
    other: product.other,
    ingredients: product.ingredients,
    suggestedStockists: product.suggestedStockists?.map(({ id }) => id),
    soldAt: product.soldAt?.reduce(
      (drinksMenus, drinksMenuProduct) => ({
        ...drinksMenus,
        [drinksMenuProduct.drinksMenuId]: {
          price: drinksMenuProduct.price,
          servedOnDraught: drinksMenuProduct.servedOnDraught,
        },
      }),
      {}
    ),
  };
};
