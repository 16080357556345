import { Formik } from 'formik';
import { giveawayApiResponseToFormValues } from '../api/giveaways.dto';
import React, { Suspense, useEffect, useState } from 'react';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import { GiveawayForm, validateForm } from '../components/GiveawayForm';
import { deleteGiveaway, updateGiveaway } from '../api/giveaways.api';
import { PageFrame } from '../../common/components/PageFrame';

export const EditGiveawayPage = () => {
  const history = useHistory();
  const { id: giveawayId } = useParams();
  const {
    data: giveawayItem,
    error,
    isValidating: loading,
  } = useSWR(`/portal/giveaways/${giveawayId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateGiveaway(giveawayId, values);
      await mutate(`/portal/giveaways`);
      await mutate(`/portal/giveaways/${giveawayId}`);
      history.push('/giveaways/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this news item',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteGiveaway(giveawayId);
      await mutate(`/portal/giveaways`);
      history.push('/giveaways/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this news item',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading location',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Giveaway">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {giveawayItem && (
        <Formik
          initialValues={giveawayApiResponseToFormValues(giveawayItem)}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <GiveawayForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
