import React from 'react';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';
import { useHistory } from 'react-router-dom';
import { NoResults } from '../../common/components/NoResults';

export const NewsDataList = ({ offset, limit, query }) => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/news?${stringify({
      offset,
      limit,
      ...(query !== '' && { query }),
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching news items, please try again"
        onClose={revalidate}
      />
    );
  }

  if (data?.length === 0) {
    return <NoResults />;
  }

  return (
    <Table striped bordered hover>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="cursor-pointer">
            <td onClick={() => history.push(`/news/edit/${item.id}`)}>
              {item.title}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
