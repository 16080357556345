const devConfig = {
  appEnv: 'dev',
  imgix: {
    domain: 'better-without.imgix.net',
  },
  firebase: {
    apiKey: 'AIzaSyBI3wc7v1Lv0zcldzRL20mCLphbSxXJiZc',
    authDomain: 'better-without-dev.firebaseapp.com',
    projectId: 'better-without-dev',
    storageBucket: 'better-without-dev.appspot.com',
    messagingSenderId: '849679068565',
    appId: '1:849679068565:web:927069dcf22e257508da9c',
    measurementId: 'G-1P5GNS72K6',
  },
  // firebase: {
  //   apiKey: 'AIzaSyCFrkzQhIYFOPh_4kzuI311iNlRji9-SgQ',
  //   authDomain: 'better-without.firebaseapp.com',
  //   databaseURL: 'https://better-without.firebaseio.com',
  //   projectId: 'better-without',
  //   storageBucket: 'better-without.appspot.com',
  //   messagingSenderId: '951900250232',
  //   appId: '1:951900250232:web:16e4e9144aa15960b40d8d',
  //   measurementId: 'G-LG8ZJH7C0S',
  // },
  googleMaps: {
    apiKey: 'AIzaSyC68y4BHccgZGYRUNcbmoIXsCf348Pw3_8',
  },
  api: {
    endpoint: 'http://localhost:3000',
  },
  sentry: {
    dsn: 'https://da8ca531ff364f88989685f1ef1459a2@o680420.ingest.sentry.io/5889618',
  },
};

const betaConfig = {
  appEnv: 'beta',
  imgix: {
    domain: 'better-without.imgix.net',
  },
  firebase: {
    apiKey: 'AIzaSyBI3wc7v1Lv0zcldzRL20mCLphbSxXJiZc',
    authDomain: 'better-without-dev.firebaseapp.com',
    projectId: 'better-without-dev',
    storageBucket: 'better-without-dev.appspot.com',
    messagingSenderId: '849679068565',
    appId: '1:849679068565:web:927069dcf22e257508da9c',
    measurementId: 'G-1P5GNS72K6',
  },
  googleMaps: {
    apiKey: 'AIzaSyC68y4BHccgZGYRUNcbmoIXsCf348Pw3_8',
  },
  api: {
    endpoint: 'https://api.dev.betterwithout.app',
  },
  sentry: {
    dsn: 'https://da8ca531ff364f88989685f1ef1459a2@o680420.ingest.sentry.io/5889618',
  },
};

const prodConfig = {
  appEnv: 'prod',
  imgix: {
    domain: 'better-without.imgix.net',
  },
  firebase: {
    apiKey: 'AIzaSyCFrkzQhIYFOPh_4kzuI311iNlRji9-SgQ',
    authDomain: 'better-without.firebaseapp.com',
    databaseURL: 'https://better-without.firebaseio.com',
    projectId: 'better-without',
    storageBucket: 'better-without.appspot.com',
    messagingSenderId: '951900250232',
    appId: '1:951900250232:web:16e4e9144aa15960b40d8d',
    measurementId: 'G-LG8ZJH7C0S',
  },
  googleMaps: {
    apiKey: 'AIzaSyC68y4BHccgZGYRUNcbmoIXsCf348Pw3_8',
  },
  api: {
    endpoint: 'https://api.betterwithout.app',
  },
  sentry: {
    dsn: 'https://da8ca531ff364f88989685f1ef1459a2@o680420.ingest.sentry.io/5889618',
  },
};

const envMap = {
  prod: prodConfig,
  beta: betaConfig,
  dev: devConfig,
};

export const appConfig = envMap[process.env.REACT_APP_ENV];
