import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import React, { Suspense, useEffect, useState } from 'react';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { deleteDrinksMenu, updateDrinksMenu } from '../api/drinksMenu.api';
import { drinksMenuApiResponseToFormValues } from '../api/drinksMenu.dto';
import { DrinksMenuForm, validateForm } from '../components/DrinksMenuForm';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

export const EditDrinksMenuPage = () => {
  // create random key so a new drinks menu
  // is fetched on every mount
  const history = useHistory();
  const { id: drinksMenuId } = useParams();
  const {
    data: drinksMenu,
    error,
    isValidating: loading,
  } = useSWR(`/portal/drinks-menus/${drinksMenuId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateDrinksMenu(drinksMenuId, values);
      await mutate(`/portal/drinks-menus/${drinksMenuId}`);
      await mutate(`/portal/drinks-menus`);
      history.push(`/drinks-menus/list`);
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this drinks menu',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDrinksMenu(drinksMenuId);
      await mutate(`/portal/drinks-menus`);
      history.push(`/drinks-menus/list`);
    } catch (e) {
      if (e.response.status === 409) {
        setErrorBox({
          title: 'An error occurred deleting this drinks menu',
          msg: 'This drinks menu is associated with a location, fix this and try again',
        });
        return;
      }

      setErrorBox({
        title: 'An error occurred deleting this drinks menu',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading drinks menu',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Drinks Menu">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {drinksMenu && (
        <Formik
          enableReinitialize
          initialValues={drinksMenuApiResponseToFormValues(drinksMenu)}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <DrinksMenuForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
