export const getViewportSize = () => {
  const viewportWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const viewportHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const pixelsPerPx = Math.max(1, window.devicePixelRatio);
  return { width: viewportWidth, height: viewportHeight, ppp: pixelsPerPx };
};
