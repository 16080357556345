import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import DataInput from '../../common/components/DataInput';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export const validateForm = (values) => {
  const errors = {};
  return errors;
};

export const ReviewForm = ({ onDelete }) => {
  const history = useHistory();
  const { values, handleSubmit, isSubmitting } = useFormikContext();

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      <Field name="starRating">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Number of stars"
                placeholder=""
                inputType="number"
                max={5}
                min={0}
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <DataInput
        label="Reviewer name"
        placeholder=""
        readOnly
        value={values.userName}
      />

      <Field name="title">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Title"
                placeholder=""
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="body">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Review"
                placeholder=""
                as="textarea"
                {...field}
              />
              {meta.touched && meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <DataInput
        label="Product name"
        placeholder=""
        readOnly
        value={values.productName}
      />

      {values.createdAt && (
        <Field name="createdAt">
          {({ field }) => {
            return (
              <DataInput
                id="name"
                label="Created At"
                defaultValue={field.value}
                plaintext
                readonly
              />
            );
          }}
        </Field>
      )}

      <Button variant="primary" type="submit" className="mr-3 border-orange-br bg-orange-br">
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button variant="primary" type="button" className="border-orange-br bg-orange-br" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Form>
  );
};
