import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Imgix from 'react-imgix';
import 'react-image-crop/dist/ReactCrop.css';

import ImageCropper from './ImageCropper';
import ImageModal from './ImageModal';
import Button from 'react-bootstrap/Button';
import { useStorage, useUser } from 'reactfire';
import { getSignedUrl, uploadFile } from '../api/upload';

const ImageSelector = ({ label, value, onChange, onDelete, readOnly }) => {
  const [editing, setEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data } = useUser();

  // Handles the initial selection of the image by the user
  // Triggers the cropper to be displayed
  const handleImageSelected = ({ target: { files } }) => {
    if (files?.length > 0) {
      setEditing(true);
      const reader = new FileReader();
      reader.addEventListener('load', function () {
        setSelectedImage(reader.result);
      });
      reader.readAsDataURL(files[0]);
    }
  };

  // Handles the "confirm" button press after the user has
  // cropped the image
  const handleImageCropped = async (blob) => {
    // upload blob and get imageUrl back to use
    setLoading(true);
    const fileName = `${(Math.random() * 1e16).toString(36)}.jpg`;
    const signedUrl = await getSignedUrl(fileName);
    const file = new File([blob], fileName);
    await uploadFile(signedUrl, file);
    const path = `users/${data.uid}/${fileName}`;
    onChange(path);
    setLoading(false);
    setEditing(false);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label column sm="2">
        {label}
      </Form.Label>
      <Col sm="10">
        {!editing && !value && !readOnly && (
          <Form.File onChange={handleImageSelected} />
        )}
        {editing && selectedImage && (
          <ImageCropper
            src={selectedImage}
            loading={loading}
            onConfirm={handleImageCropped}
            onCancel={() => {
              setSelectedImage(null);
              setEditing(false);
            }}
          />
        )}
        {!editing && value && (
          <>
            <Imgix
              width={100}
              src={value}
              onClick={() => setShowImageModal(true)}
              fluid
            />
            {!readOnly && (
              <Button variant="primary" type="button" className="border-orange-br bg-orange-br" onClick={onDelete}>
                Delete
              </Button>
            )}
          </>
        )}
        {!editing && showImageModal && value && (
          <ImageModal
            src={value}
            show={showImageModal}
            onHide={() => setShowImageModal(false)}
          />
        )}
      </Col>
    </Form.Group>
  );
};

export default ImageSelector;
