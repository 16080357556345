import React, { Suspense, useState } from 'react';
import { ProductsDataList } from '../components/ProductsDataList';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { useUpdateTitle } from '../../common/hooks/useUpdateTitle';
import { PageFrame } from '../../common/components/PageFrame';
import { AddButton } from '../../common/components/AddButton';
import { SearchBar } from '../../common/components/SearchBar';

export const ProductsListPage = () => {
  useUpdateTitle('Products');
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame
      title="Products"
      topBarContent={
        <>
          <SearchBar placeholder="Search products" onChange={setQuery} />
          <AddButton text="Add Product" to="/products/add" />
        </>
      }
    >
      <ListSearchAndLimit
        setLimit={setLimit}
        limit={limit}
        onSearch={setQuery}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <ProductsDataList
          offset={offset}
          limit={limit}
          query={query}
          setOffset={setOffset}
        />
      </Suspense>
    </PageFrame>
  );
};
