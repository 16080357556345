export const newsApiResponseToFormValues = (news) => {
  return {
    title: news.title,
    categoryId: news.categoryId,
    createdBy: news.createdBy,
    url: news.url,
    productIds: news.products?.map(({ id }) => id),
    productTypeIds: news.productTypes?.map(({ id }) => id),
    countryIds: news.countries?.map(({ id }) => id),
    imageUrl: news.imageUrl,
    introduction: news.introduction,
    body: news.body,
    createdAt: news.createdAt,
  };
};

export const newsFormValuesToUpdateNewsDto = (formValues) => {
  return {
    title: formValues.title,
    categoryId: formValues.categoryId,
    createdBy: formValues.createdBy,
    url: formValues.url,
    productIds: formValues.productIds || [],
    productTypeIds: formValues.productTypeIds || [],
    countryIds: formValues.countryIds || [],
    imageUrl: formValues.imageUrl,
    introduction: formValues.introduction,
    body: formValues.body,
  };
};

export const newsFormValuesToCreateNewsDto = (formValues) => {
  return {
    title: formValues.title,
    categoryId: formValues.categoryId,
    createdBy: formValues.createdBy,
    url: formValues.url,
    productIds: formValues.productIds || [],
    productTypeIds: formValues.productTypeIds || [],
    countryIds: formValues.countryIds || [],
    imageUrl: formValues.imageUrl,
    introduction: formValues.introduction,
    body: formValues.body,
  };
};
