import React from 'react';

export const RetailerRanking = ({ ranking }) => {
  let label;

  if (ranking === 1) {
    label = '1st';
  } else if (ranking === 2) {
    label = '2nd';
  } else if (ranking === 3) {
    label = '3rd';
  }

  return (
    <span
      style={{
        color: '#ffffff',
        padding: 5,
        backgroundColor: '#0bb810',
        borderRadius: 6,
        marginLeft: 15,
        fontSize: 12,
      }}
    >
      {label}
    </span>
  );
};
