import React, { useState } from 'react';
import logo from '../../../img/BetterWithout_Logo Full_White.png';
import { client } from '../../common/api/client';
import { useAuth } from 'reactfire';
import { signInWithEmailAndPassword } from 'firebase/auth';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import loginImage from '../../../img/bwa-discover-login-cover.jpg';

export const LoginPage = () => {
  const auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [errBox, setErrBox] = useState(noError);
  const history = useHistory();

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      try {
        await signInWithEmailAndPassword(auth, username, password);
        const { data: user } = await client.get('/portal/me');
        if (
          user.roles?.some((role) => ['Admin', 'Brand Manager'].includes(role))
        ) {
          history.push('/overview');
        } else {
          setErrBox({ title: 'Login Failed', msg: '' });
          setValidated(false);
        }
      } catch (error) {
        let err = { title: 'Login Failed', msg: '' };
        switch (error.code) {
          case 'auth/invalid-email':
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            err.msg = 'Please check your email address and try again.';
            break;
          case 'auth/wrong-password':
            err.msg = 'Please check your password and try again.';
            break;
          default:
            err.msg = error.message;
        }
        setErrBox(err);
        setValidated(false);
      }
    }
    setValidated(true);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${loginImage})`,
        backgroundSize: 'cover',
        height: '100vh',
        backgroundBlendMode: 'soft-light',
        backgroundColor: '#454a5c',
      }}
    >
      <div className="max-w-sm mx-auto xs:p-4 sm:p-0">
        <header className="pt-8">
          <img
            src={logo}
            className="w-60 h-auto mx-auto"
            alt="Better Without"
          />
        </header>
        <main className="bg-white rounded-b-xl px-8 py-2">
          <p className="text-lg mb-0 mt-3 text-center">Welcome to the</p>
          <p className="text-lg font-bold mb-4 text-center">Drinks Cupboard</p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={username}
                onChange={function (e) {
                  setUsername(e.target.value);
                }}
                placeholder="Enter email"
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={function (e) {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
                required
              />
            </Form.Group>
            <Button
              variant="secondary"
              type="submit"
              className="mx-auto my-8 block border-orange-br bg-orange-br"
            >
              Sign in
            </Button>
          </Form>
          <p className="text-muted text-center">
            &copy; Better Without {new Date().getFullYear()}
          </p>
        </main>
        <ErrorBox
          title={errBox.title}
          msg={errBox.msg}
          onClose={function () {
            setErrBox(noError);
          }}
        />
      </div>
    </div>
  );
};
