import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ExplainerText, FormGrid, LabelText, TextInput } from './styles';
import { useContext, useEffect } from 'react';
import { DrinksMenuFinder } from './DrinksMenuFinder';
import { CompanyFinder } from './CompanyFinder';
import { UserContext } from '../../../common/context/userContext';

export const CompanyForm = () => {
  const { user, isAdmin } = useContext(UserContext);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const company = useWatch({
    name: 'company',
  });

  useEffect(() => {
    if (!company) {
      setValue('drinksMenu', null);
    }
  }, [company]);

  return (
    <FormGrid>
      <LabelText>Company *</LabelText>
      <div>
        {isAdmin ? (
          <Controller
            control={control}
            name="company"
            rules={{ required: 'Field required' }}
            render={({ field }) => {
              return <CompanyFinder {...field} error={errors[field.name]} />;
            }}
          />
        ) : (
          <TextInput
            placeholder={user.company?.name}
            $disabled={!isAdmin}
            disabled={!isAdmin}
          />
        )}
        <ExplainerText
          style={{ maxWidth: 460, marginTop: 10, marginBottom: 16 }}
        >
          We need this information so we can show you the correct drinks menu
          options. Select an existing company or add a new one.
        </ExplainerText>
      </div>
      <LabelText>Drinks menu *</LabelText>
      <div>
        <Controller
          control={control}
          name="drinksMenu"
          rules={{ required: 'Field required' }}
          render={({ field }) => {
            return <DrinksMenuFinder {...field} error={errors[field.name]} />;
          }}
        />
      </div>
    </FormGrid>
  );
};
