import axios from 'axios';
import { appConfig } from '../app.config';
import { getFirebaseApp } from '../../../lib/firebaseApp';
import { getAuth } from 'firebase/auth';

export const fetcher = async (url) => {
  const auth = getAuth(getFirebaseApp());
  const token = await auth.currentUser?.getIdToken();
  const baseUrl = appConfig.api.endpoint;
  return axios({
    method: 'get',
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    url: `${baseUrl}${url}`,
  }).then((res) => res.data);
};
