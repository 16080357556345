import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { RetailerRanking } from './RetailerRanking';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { NoResults } from '../../common/components/NoResults';

export const RetailersDataList = ({ offset, limit, query, setOffset }) => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/retailers?${stringify({
      offset,
      limit,
      expand: 'stockedProducts',
      ...(query !== '' && { query }),
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching retailers, please try again"
        onClose={revalidate}
      />
    );
  }

  if (data.length === 0) {
    return <NoResults />;
  }

  return (
    <div>
      <Table striped bordered hover>
        <tbody>
          {data.map((item) => {
            const missingUrlCount = item.stockedProducts?.filter(
              (stockedProduct) => !stockedProduct.url
            )?.length;
            return (
              <tr key={item.id} className="cursor-pointer">
                <td onClick={() => history.push(`/retailers/edit/${item.id}`)}>
                  <span style={{ fontWeight: 600 }}>
                    {item.name}
                    {item.ranking && <RetailerRanking ranking={item.ranking} />}
                  </span>
                  <span style={{ color: '#888', float: 'right' }}>
                    {item.stockedProducts?.length === 1
                      ? '1 product stocked'
                      : item.stockedProducts?.length + ' products'}
                    {!!missingUrlCount && (
                      <span style={{ color: '#ff7578' }}>
                        {' (' + missingUrlCount + ' missing URLs)'}
                      </span>
                    )}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </div>
  );
};
