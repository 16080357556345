export const retailerFormValuesToCreateRetailerDto = (formValues) => {
  const ranking =
    formValues.ranking === 'No ranking' ? null : formValues.ranking;
  return {
    ranking,
    hidden: formValues.hidden,
    name: formValues.name,
    companyId: formValues.company?.id,
    url: formValues.url,
    imageUrl: formValues.imageUrl,
    countries: formValues.countries,
    stockedProducts: Object.keys(formValues.stockedProducts || {}).map(
      (productId) => {
        const { url } = formValues.stockedProducts[productId];
        return {
          productId,
          url,
        };
      }
    ),
  };
};

export const retailerFormValuesToUpdateRetailerDto =
  retailerFormValuesToCreateRetailerDto;

export const retailersApiResponseToFormValues = (retailer) => {
  return {
    hidden: retailer.hidden,
    name: retailer.name,
    company: retailer.company,
    url: retailer.url,
    imageUrl: retailer.imageUrl,
    ranking: retailer.ranking,
    suggestedProducts: retailer.suggestedProducts,
    countries: retailer.countries.map(({ id }) => id),
    stockedProducts:
      retailer.stockedProducts?.reduce(
        (products, stockedProduct) => ({
          ...products,
          [stockedProduct.productId]: {
            url: stockedProduct.url,
            name: stockedProduct.product?.name,
          },
        }),
        {}
      ) || {},
  };
};
