import { useHistory } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { UserContext } from '../../common/context/userContext';
import { createRetailer } from '../api/retailers.api';
import { mutate } from 'swr';
import { RetailerForm, validateForm } from '../components/RetailerForm';
import { Formik } from 'formik';
import { PageFrame } from '../../common/components/PageFrame';

export const AddRetailerPage = () => {
  const history = useHistory();
  const [error, setError] = useState(noError);
  const { isAdmin, companyId } = useContext(UserContext);

  const handleSubmit = async (values) => {
    try {
      await createRetailer(values);
      await mutate('/portal/retailers');
      history.push('/retailers/list');
    } catch (e) {
      console.log(e);
      setError({
        title: 'An error occurred saving this retailer',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Retailer">
      <Formik
        initialValues={{ ...(isAdmin ? {} : { companyId }) }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <RetailerForm />
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
