import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';
import React from 'react';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';

export const DrinksMenuDataList = ({
  offset,
  limit,
  query,
  onSelect,
  url = '/portal/products/drinks-menus',
}) => {
  const { data, error, revalidate } = useSWR(
    `${url}?${stringify({
      offset,
      limit,
      ...(query !== '' && { query }),
    })}`,
    fetcher
  );

  if (!data) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching drinks menus, please try again"
        onClose={revalidate}
      />
    );
  }

  return (
    <Table striped bordered hover>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="cursor-pointer">
            <td onClick={() => onSelect(item)}>
              {item.name} <br />
              <small>
                <b>{item.company.name}</b>
              </small>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
