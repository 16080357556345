import Spinner from 'react-bootstrap/Spinner';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

export const LoadingSpinner = () => {
  return (
    <Container>
      <Spinner animation="border" role="status" variant="secondary" size="md">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Container>
  );
};
