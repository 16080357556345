import { DropdownMenuItem } from './DropdownMenuItem';
import * as Styles from './Sidebar.styles';

import { ReactComponent as Logo } from '../../../../img/better-without.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Brands } from './brands.svg';
import { ReactComponent as Products } from './products.svg';
import { ReactComponent as Places } from './places.svg';
import { ReactComponent as Marketing } from './marketing.svg';
import { ReactComponent as Management } from './management.svg';
import { ReactComponent as DrinksMenus } from './drinks-menus.svg';
import { ReactComponent as Retailers } from './retailers.svg';
import { UserRoles } from '../../common.const';
import { useContext } from 'react';
import { UserContext } from '../../context/userContext';
import { UserProfile } from '../UserProfile/UserProfile';

const marketingLinks = [
  {
    name: 'News',
    path: '/news/list',
    parent: '/news',
    isActive: (match, location) => {
      return !!location.pathname.startsWith('/news');
    },
  },
  {
    name: 'Giveaways',
    path: '/giveaways/list',
    parent: '/giveaways',
    isActive: (match, location) => {
      return !!location.pathname.startsWith('/giveaways');
    },
  },
  {
    name: 'Push Notifications',
    path: '/push-notifications/list',
    parent: '/push-notifications',
    isActive: (match, location) => {
      return !!location.pathname.startsWith('/push-notifications');
    },
  },
];
const managementLinks = [
  {
    name: 'Users',
    path: '/users/list',
    parent: '/users',
    isActive: (match, location) => {
      return !!location.pathname.startsWith('/users');
    },
  },
  {
    name: 'Companies',
    path: '/companies/list',
    parent: '/companies',
    isActive: (match, location) => {
      return !!location.pathname.startsWith('/companies');
    },
  },
  {
    name: 'Approvals',
    path: '/edits/list',
    parent: '/edits',
    isActive: (match, location) => {
      return !!location.pathname.startsWith('/edits');
    },
  },
];

const getManagementItems = (role, isUserAdmin) => {
  const items = managementLinks.filter((item) => {
    if (![UserRoles.Admin].includes(role)) {
      return false;
    }
    // if !isUserAdmin, filter out users
    return !(!isUserAdmin && item.path.startsWith('/users'));
  });
  return items;
};
const getMarketingItems = (role) => {
  const items = marketingLinks.filter((item) => {
    return [UserRoles.Admin].includes(role);
  });
  return items;
};

export const Sidebar = () => {
  const { role, isUserAdmin } = useContext(UserContext);

  const managementItems = getManagementItems(role, isUserAdmin);
  const marketingItems = getMarketingItems(role);

  return (
    <Styles.Container>
      <Styles.ScrollableContainer>
        <Styles.LogoWrapper to="/">
          <Logo />
        </Styles.LogoWrapper>
        <Styles.Nav>
          <Styles.Menu>
            <Styles.MenuItem>
              <Styles.MenuLink
                to="/"
                isActive={(match, location) => {
                  return location.pathname === '/';
                }}
              >
                <Styles.IconWrapper>
                  <Home />
                </Styles.IconWrapper>
                Home
              </Styles.MenuLink>
            </Styles.MenuItem>
            {[
              UserRoles.Admin,
              UserRoles.BrandManager,
              UserRoles.CompanyManager,
            ].includes(role) && (
              <Styles.MenuLink
                to="/brands/list"
                isActive={(match, location) => {
                  return !!location.pathname.startsWith('/brands');
                }}
              >
                <Styles.IconWrapper>
                  <Brands />
                </Styles.IconWrapper>
                Brands
              </Styles.MenuLink>
            )}
            {[
              UserRoles.Admin,
              UserRoles.BrandManager,
              UserRoles.CompanyManager,
            ].includes(role) && (
              <Styles.MenuLink
                to="/products/list"
                isActive={(match, location) => {
                  return !!location.pathname.startsWith('/products');
                }}
              >
                <Styles.IconWrapper>
                  <Products />
                </Styles.IconWrapper>
                Products
              </Styles.MenuLink>
            )}
            {[
              UserRoles.Admin,
              UserRoles.LocationsManager,
              UserRoles.CompanyManager,
            ].includes(role) && (
              <Styles.MenuLink
                to="/places/list"
                isActive={(match, location) => {
                  return !!location.pathname.startsWith('/places');
                }}
              >
                <Styles.IconWrapper>
                  <Places />
                </Styles.IconWrapper>
                Places
              </Styles.MenuLink>
            )}
            {[
              UserRoles.Admin,
              UserRoles.LocationsManager,
              UserRoles.CompanyManager,
            ].includes(role) && (
              <Styles.MenuLink
                to="/drinks-menus/list"
                isActive={(match, location) => {
                  return !!location.pathname.startsWith('/drinks-menus');
                }}
              >
                <Styles.IconWrapper>
                  <DrinksMenus />
                </Styles.IconWrapper>
                Drinks Menus
              </Styles.MenuLink>
            )}
            {[
              UserRoles.Admin,
              UserRoles.RetailManager,
              UserRoles.CompanyManager,
            ].includes(role) && (
              <Styles.MenuLink
                to="/retailers/list"
                isActive={(match, location) => {
                  return !!location.pathname.startsWith('/retailers');
                }}
              >
                <Styles.IconWrapper>
                  <Retailers />
                </Styles.IconWrapper>
                Online Retailers
              </Styles.MenuLink>
            )}
            {marketingItems.length ? (
              <DropdownMenuItem
                label="Marketing"
                icon={Marketing}
                items={marketingItems}
              />
            ) : null}
            {managementItems.length ? (
              <DropdownMenuItem
                label="Management"
                icon={Management}
                items={managementItems}
              />
            ) : null}
          </Styles.Menu>
        </Styles.Nav>
      </Styles.ScrollableContainer>
      <UserProfile />
    </Styles.Container>
  );
};
