import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import { Formik } from 'formik';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { useHistory, useParams } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { deleteNews, updateNews } from '../api/news.api';
import { NewsForm, validateForm } from '../components/NewsForm';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { newsApiResponseToFormValues } from '../api/news.dto';
import { PageFrame } from '../../common/components/PageFrame';

export const EditNewsPage = () => {
  const history = useHistory();
  const { id: newsId } = useParams();
  const {
    data: newsItem,
    error,
    isValidating: loading,
  } = useSWR(`/portal/news/${newsId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateNews(newsId, values);
      await mutate(`/portal/news`);
      await mutate(`/portal/news/${newsId}`);
      history.push('/news/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this news item',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteNews(newsId);
      await mutate(`/portal/news`);
      history.push('/news/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this news item',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading location',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit News">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {newsItem && (
        <Formik
          initialValues={newsApiResponseToFormValues(newsItem)}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <NewsForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
