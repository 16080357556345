import { useState } from 'react';
import { useUser } from 'reactfire';
import { useDropzone } from 'react-dropzone';
import Imgix from 'react-imgix';

import { ReactComponent as PlaceholderIcon } from './placeholder.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';

import { getSignedUrl, uploadFile } from '../../../common/api/upload';
import ImageModal from '../../../common/components/ImageModal';
import ImageCropper from '../../../common/components/ImageCropper';
import styled from 'styled-components';

const getBorderColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#D9DBDD';
};

const PlaceholderWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-width: 1px;
  border-style: dashed;
  border-color: ${(props) => getBorderColor(props)};
  width: 90px;
  height: 90px;
  background: #f7f8f9;
  border-radius: 5px;
  box-shadow: 0 0.5px 1.5px rgba(69, 74, 92, 0.25);
`;

const PlaceholderText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #a4a6af;
  display: block;
  margin-top: 8px;
`;

const Underlined = styled.label`
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  padding: 0;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const ImagePreview = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 5px;
  filter: drop-shadow(0px 0.5px 1.5px rgba(69, 74, 92, 0.25));
  background: #d9d9d9;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const DeleteButton = styled.button`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fdf2ec;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageUploadInput = ({ name, onChange, value, readOnly }) => {
  const [editing, setEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data } = useUser();

  // Handles the initial selection of the image by the user
  // Triggers the cropper to be displayed
  const handleImageSelected = (file) => {
    setEditing(true);
    const reader = new FileReader();
    reader.addEventListener('load', function () {
      setSelectedImage(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'image/*': ['.jpg', '.jpeg', '.png', '.webp'],
      },
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          handleImageSelected(acceptedFiles[0]);
        }
      },
    });

  // Handles the "confirm" button press after the user has
  // cropped the image
  const handleImageCropped = async (blob) => {
    // upload blob and get imageUrl back to use
    setLoading(true);
    const fileName = `${(Math.random() * 1e16).toString(36)}.jpg`;
    const signedUrl = await getSignedUrl(fileName);
    const file = new File([blob], fileName);
    await uploadFile(signedUrl, file);
    const path = `users/${data.uid}/${fileName}`;

    // Create an event object and call `onChange`
    onChange({
      target: {
        name,
        value: path,
      },
    });

    setLoading(false);
    setEditing(false);
  };

  return (
    <div>
      <div>
        <div>
          {!editing && !value && !readOnly && (
            <>
              <input
                id="file"
                type="file"
                {...getInputProps()}
                style={{ display: 'none' }}
              />
              <PlaceholderWrapper
                {...getRootProps({ isFocused, isDragAccept, isDragReject })}
              >
                <PlaceholderIcon />
              </PlaceholderWrapper>
              <PlaceholderText>
                Drop image or <Underlined htmlFor="file">browse</Underlined>
              </PlaceholderText>
            </>
          )}
        </div>
        {editing && selectedImage && (
          <ImageCropper
            src={selectedImage}
            loading={loading}
            onConfirm={handleImageCropped}
            onCancel={() => {
              setSelectedImage(null);
              setEditing(false);
            }}
          />
        )}
        {!editing && value && (
          <ImagePreviewContainer>
            <ImagePreview>
              <Imgix
                width={90}
                height={90}
                src={value}
                onClick={() => setShowImageModal(true)}
                fluid
              />
            </ImagePreview>
            {!readOnly && (
              <DeleteButton
                onClick={() => {
                  onChange({
                    target: {
                      name,
                      value: null,
                    },
                  });
                }}
                type="button"
              >
                <DeleteIcon />
              </DeleteButton>
            )}
          </ImagePreviewContainer>
        )}
        {!editing && showImageModal && value && (
          <ImageModal
            src={value}
            show={showImageModal}
            onHide={() => setShowImageModal(false)}
          />
        )}
      </div>
    </div>
  );
};
