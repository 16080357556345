export const formValuesToUpdateReviewDto = (formValues) => {
  return {
    title: formValues.title,
    body: formValues.body,
    starRating: parseInt(formValues.starRating, 10),
  };
};

export const reviewsApiResponseToFormValues = (review) => {
  return {
    title: review.title,
    body: review.body,
    starRating: review.starRating,
    userName: review.user.name,
    productName: review.product.name,
    createdAt: review.createdAt,
  };
};
