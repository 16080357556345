import React, { useState } from 'react';
import { Formik } from 'formik';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { CompanyForm, validateForm } from '../components/CompanyForm';
import { createCompany } from '../api/companies.api';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { PageFrame } from '../../common/components/PageFrame';

export const AddCompanyPage = () => {
  const history = useHistory();
  const [error, setError] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await createCompany(values);
      await mutate('/portal/companies');
      history.push('/companies/list');
    } catch (e) {
      setError({
        title: 'An error occurred saving this company',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Company">
      <Formik
        initialValues={{}}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <CompanyForm />
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
