import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import useSWR from 'swr';
import { stringify } from 'query-string';
import xor from 'lodash/xor';
import { fetcher } from '../api/fetcher';

export const DataSearch = ({
  id,
  label,
  value = [],
  onChange,
  url,
  readOnly,
}) => {
  const [query, setQuery] = useState('');
  const {
    data,
    error,
    isValidating: loading,
  } = useSWR(
    url
      ? `${url}?${stringify({ limit: 500, ...(query !== '' && { query }) })}`
      : null,
    fetcher
  );
  const { data: listData } = useSWR(
    url ? `${url}?id=${value.join(',')}&limit=500` : null,
    fetcher
  );
  const searchInputRef = useRef();

  const options = data?.filter((item) => !value?.includes(item.id));

  const handleSelect = (itemId) => {
    onChange([...value, itemId]);
  };

  const handleDeselect = (itemId) => {
    onChange(xor(value, [itemId]));
  };

  return (
    <Form.Group as={Row} controlId={id}>
      <Form.Label column sm="2">
        {label}
      </Form.Label>
      <Col sm="10">
        {loading && <p>Loading...</p>}
        {error && <p>Error! {error}</p>}
        {data && value.length === 0 ? (
          <div>None selected. Search and add items from the list below.</div>
        ) : (
          <div>
            {listData?.map((item) => {
              return (
                <Button
                  key={item.id}
                  name={item.id}
                  variant="secondary"
                  type="button"
                  onClick={() => handleDeselect(item.id)}
                  className="mr-2 mb-2 border-orange-br bg-orange-br"
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
        )}
        {!readOnly && (
          <>
            <div className="flex items-center justify-center mt-4 mb-2">
              <Form.Control
                type="text"
                placeholder="Search for an item..."
                className="h-9"
                ref={searchInputRef}
                onKeyDown={(e) => {
                  const keyCode = e.keyCode ? e.keyCode : e.which;
                  if (keyCode === 13) {
                    e.preventDefault();
                    setQuery(searchInputRef.current?.value);
                  }
                }}
              />
              <div>
                <Button
                  variant="primary"
                  type="button"
                  className="ml-1 h-9 border-orange-br bg-orange-br"
                  onClick={(e) => {
                    e.preventDefault();
                    setQuery(searchInputRef.current?.value);
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
            <Form.Control as="select" multiple>
              {options?.map((option) => {
                return (
                  <option
                    key={option.id}
                    value={option.id}
                    onClick={() => handleSelect(option.id)}
                  >
                    {option.name}
                  </option>
                );
              })}
            </Form.Control>
          </>
        )}
      </Col>
    </Form.Group>
  );
};
