import React, { createContext } from 'react';
import { useUser } from 'reactfire';
import usePromise from 'react-promise-suspense';
import { getFirebaseApp } from '../../../lib/firebaseApp';
import { appConfig } from '../app.config';
import axios from 'axios';
import { UserAdminIds, UserRoles } from '../common.const';
import { getAuth } from 'firebase/auth';

export const UserContext = createContext({
  role: null,
  user: null,
  companyId: null,
  loading: true,
  isAdmin: false,
  isUserAdmin: false,
});

const getUser = async (uid) => {
  if (!uid) return {};
  const auth = getAuth(getFirebaseApp());
  const token = await auth.currentUser?.getIdToken();
  const baseUrl = appConfig.api.endpoint;
  return axios({
    method: 'get',
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    url: `${baseUrl}/portal/me`,
  }).then((res) => res.data);
};

export const UserProvider = ({ children }) => {
  const { data: firebaseUser } = useUser({ suspense: true });
  const data = usePromise(getUser, [firebaseUser?.uid]);
  const { role, ...user } = data;

  const isAdmin = role === UserRoles.Admin;
  const isProd = appConfig.appEnv === 'prod';
  const isUserAdmin = isProd ? UserAdminIds.includes(user.id) : true;

  return (
    <UserContext.Provider
      value={{ role, user, companyId: data.companyId, isAdmin, isUserAdmin }}
    >
      {children}
    </UserContext.Provider>
  );
};
