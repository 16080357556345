import useSWR from 'swr';

import { forwardRef, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../common/context/userContext';
import { useFormContext } from 'react-hook-form';
import { Combobox } from '@headlessui/react';
import {
  ClearIcon,
  ExplainerText,
  InputError,
  InputWrapper,
  TextInput,
} from './styles';
import { fetcher } from '../../../common/api/fetcher';
import styled, { css } from 'styled-components';
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as Clear } from '../../../common/svg/clear.svg';
import { ReactComponent as Right } from './right.svg';

const OptionsContainer = styled.div`
  width: 500px;
  border: 1px solid #ed6921;
  border-radius: 5px;
  margin-top: 4px;
  overflow: hidden;
  
  ${({ noOptions }) =>
    noOptions &&
    css`
      display: none;
    `}}
`;

const ExistingCompaniesLabel = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a4a6af;
  margin: 10px 8px 6px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Option = styled.div`
  height: 48px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;
  flex-grow: 1;

  &:hover {
    background: #fdf2ec;
    cursor: pointer;
  }
`;

const MenuLink = styled.a`
  position: relative;
  width: 78px;
  height: 48px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  /* BW dark grey */
  color: #454a5c;

  & > span {
    display: block;
    border-bottom: 1px solid #454a5c;
  }

  & > svg {
    margin-left: 4px;
  }

  &:before {
    content: ' ';
    position: absolute;
    height: 32px;
    width: 1px;
    background-color: #d9dbdd;
    left: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: #fad2bc;
    color: #454a5c;
    text-decoration: none;

    &:before {
      background-color: #fad2bc;
    }
  }
`;

const AddCompanyButton = styled.button`
  background: #fad2bc;
  border-radius: 0px 0px 5px 5px;
  height: 40px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddCompanyButtonText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
  margin-left: 4px;
`;

const deriveUrl = (isAdminCheckRequired, isAdmin, apiPath) => {
  if (!apiPath) {
    return null;
  }
  if (isAdminCheckRequired && isAdmin) {
    return `/portal/${apiPath}`;
  } else if (!isAdminCheckRequired) {
    return `/portal/${apiPath}`;
  } else {
    return null;
  }
};

export const DrinksMenuFinder = forwardRef(
  ({ name, value, onChange, error }, ref) => {
    const { isAdmin } = useContext(UserContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [localDrinksMenus, setLocalDrinksMenus] = useState([]);
    const { getValues } = useFormContext();
    const company = getValues('company');

    const { data: drinksMenus, isValidating } = useSWR(
      deriveUrl(
        false,
        isAdmin,
        company ? `companies/${company.id}/drinks-menus` : null
      ),
      fetcher,
      { suspense: false }
    );

    useEffect(() => {
      if (drinksMenus) {
        setLocalDrinksMenus(drinksMenus);
      }
    }, [drinksMenus]);

    const matchingDrinksMenus = localDrinksMenus?.filter((drinksMenu) =>
      drinksMenu.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const queryMatchesExistingEntity = localDrinksMenus?.some(
      (drinksMenu) =>
        drinksMenu.name.toLowerCase() === searchQuery.toLowerCase()
    );

    const noOptions =
      !isValidating && matchingDrinksMenus?.length === 0 && !searchQuery;

    let placeholder;

    if (company) {
      if (company.id === null) {
        // if it's a new company
        placeholder = 'Create a drinks menu';
      } else {
        // it's not a new company

        // the drinks menus have been fetched, but there aren't any
        if (matchingDrinksMenus?.length === 0 && !isValidating) {
          placeholder = 'Create a drinks menu';
        } else {
          // The drinks menus have been fetched and there are some
          placeholder = `Select a drinks menu`;
        }
      }
    } else {
      // no company selected
      placeholder = 'Select a company to view menus';
    }

    const disabled = !company;

    return (
      <>
        <Combobox
          name={name}
          disabled={disabled}
          value={value}
          onChange={(drinksMenu) => {
            onChange(drinksMenu);
            setSearchQuery('');
          }}
        >
          <InputWrapper>
            {error && <InputError>{error.message}</InputError>}
            <Combobox.Button as="div">
              <Combobox.Input
                placeholder={placeholder}
                onChange={(event) => setSearchQuery(event.target.value)}
                displayValue={(drinksMenu) => drinksMenu?.name}
                as={TextInput}
                disabled={disabled}
                $disabled={disabled}
                $error={error}
              />
              {value && <ClearIcon as={Clear} onClick={() => onChange('')} />}
            </Combobox.Button>
          </InputWrapper>
          <Combobox.Options as={OptionsContainer} noOptions={noOptions}>
            {matchingDrinksMenus?.length > 0 && (
              <ExistingCompaniesLabel>
                Existing drinks menus ({localDrinksMenus?.length})
              </ExistingCompaniesLabel>
            )}
            {matchingDrinksMenus?.map((drinksMenu) => (
              <OptionWrapper>
                <Combobox.Option
                  as={Option}
                  key={drinksMenu.id}
                  value={drinksMenu}
                >
                  {drinksMenu.name}
                </Combobox.Option>
                <MenuLink
                  href={`/drinks-menus/edit/${drinksMenu.id}`}
                  target="_blank"
                >
                  <span>View</span> <Right />
                </MenuLink>
              </OptionWrapper>
            ))}
            {!queryMatchesExistingEntity && searchQuery && (
              <Combobox.Option
                value={{ id: null, name: searchQuery }}
                as={AddCompanyButton}
                type="button"
              >
                <AddIcon />
                <AddCompanyButtonText>Add as a new menu</AddCompanyButtonText>
              </Combobox.Option>
            )}
          </Combobox.Options>
        </Combobox>
        <ExplainerText
          style={{ maxWidth: 460, marginTop: 10, marginBottom: 24 }}
        >
          Select an existing drinks menu or add a new one. You can add products
          to a new menu later.
        </ExplainerText>
      </>
    );
  }
);
