import useSWR, { mutate } from 'swr';
import { Formik } from 'formik';
import { fetcher } from '../../common/api/fetcher';
import { useHistory, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { ProductForm, validateForm } from '../components/ProductForm';
import { productsApiResponseToFormValues } from '../api/products.dto';
import { archiveProduct, updateProduct } from '../api/products.api';
import { UserContext } from '../../common/context/userContext';
import { PageFrame } from '../../common/components/PageFrame';

export const EditProductPage = () => {
  const history = useHistory();
  const { id: productId } = useParams();
  const {
    data: product,
    error,
    isValidating: loading,
  } = useSWR(`/portal/products/${productId}`, fetcher);
  const { companyId, isAdmin } = useContext(UserContext);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateProduct(productId, values);
      await Promise.all([
        mutate(`/portal/products/${productId}`),
        mutate(`/portal/products`),
      ]);
      history.push('/products/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this product',
        msg: 'Please try again',
      });
    }
  };

  const handleArchive = async () => {
    try {
      await archiveProduct(productId);
      await mutate(`/portal/products`);
      history.push('/products/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred archiving this product',
        msg: 'Please try again',
      });
      throw e;
    }
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading product',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Product">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {product && (
        <Formik
          initialValues={{
            ...(!isAdmin && { company: { id: companyId } }),
            ...productsApiResponseToFormValues(product),
          }}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <ProductForm onArchive={handleArchive} />
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
