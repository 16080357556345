import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Menu } from '@headlessui/react';

import { ReactComponent as MenuIcon } from './menu.svg';
import { ReactComponent as CreatedAt } from './created-at.svg';
import { ReactComponent as UpdatedAt } from './updated-at.svg';
import { ReactComponent as Products } from './products.svg';
import { ReactComponent as MoreVertical } from './more-vertical.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';

import { Link } from 'react-router-dom';
import { UserContext } from '../../../common/context/userContext';
import Imgix from 'react-imgix';
import { placeToPlaceFormValues } from '../../api/sites.dto';

export const largeScreenTemplateColumns = `
    auto minmax(32px, auto) minmax(0, 150px) minmax(0, 150px) minmax(0, 150px)
`;

export const smallScreenTemplateColumns = `
    auto minmax(32px, auto) minmax(0, 150px) minmax(0, 150px)
`;

const Card = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #ecedee;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: ${largeScreenTemplateColumns};
  grid-column-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-areas: 'leftSection createdAt updatedAt products';
  align-items: center;
  min-width: 0;
  position: relative;

  // at below 1300px, ditch the last column
  @media (max-width: 1300px) {
    grid-template-columns: ${smallScreenTemplateColumns};
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  min-width: 0;
`;

const ImageWrapper = styled.div`
  filter: drop-shadow(0px 0.5px 1.5px rgba(69, 74, 92, 0.25));
  border-radius: 5px;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`;

const LightGreyText = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a4a6af;
  margin-bottom: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
`;

const CardTitle = styled.button`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #454a5c;
  margin-bottom: 0;
  display: block;
  background: none;
  border: none;
  padding: 0;
  text-align: left;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 450px;

  &:hover {
    text-decoration: none;
    color: #ed6921;
  }
`;

const Details = styled.div`
  max-width: 100%;
  overflow: hidden;

  > * {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DetailsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

const Flag = styled.span`
  width: 19px;
  height: 14px;
  filter: drop-shadow(0.2px 0.5px 2px rgba(69, 74, 92, 0.25));
  border-radius: 3px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const AddressText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d808d;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 550px;
`;

const MenuLink = styled(Link)`
  &:hover {
    text-decoration: none;

    ${AddressText} {
      color: #454a5c;
    }
  }
`;

const MenuWrapper = styled.div`
  margin-left: 1px;
`;

const MetaDataItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const ProductItem = styled(MetaDataItem)`
  // at below 1300px, hide
  @media (max-width: 1300px) {
    display: none;
  }
`;

const MetaDataText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2px;
  gap: 2px;
  min-width: 0;
`;

const MetaDataValue = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MetaDataLabel = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  color: #a4a6af;
  display: block;
`;

const MetaDataIcon = styled.div`
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ActionsMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ActionsMenuButton = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a4a6af;

  box-shadow: 0 0.5px 1.5px rgba(69, 74, 92, 0.25);
  border-radius: 0 9px 0 3px;

  &:hover {
    background-color: #fdf2ec;
    color: #ed6921;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: #fdf2ec;
      color: #ed6921;
    `}
`;

const ActionsMenu = styled.div`
  width: 86px;
  border: 1px solid #ecedee;
  background: white;
  position: relative;
  right: -1px;
  top: 1px;

  border-radius: 3px 0 0 3px;
  overflow: hidden;
`;

const ActionsMenuItem = styled.button`
  width: 100%;
  display: flex;
  padding: 8px 8px;
  gap: 4px;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;

  border-bottom: 1px solid #ecedee;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: #fdf2ec;
    color: #ed6921;
  }
`;

// format the createdAt date to match the format "21st Jan 23"
const getOrdinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

const formatDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const year = dateObj.getFullYear().toString().substr(-2);

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

export const PlaceResultItem = ({ place, onEdit, onDelete }) => {
  const { isAdmin } = useContext(UserContext);
  let topTitle = isAdmin
    ? place.company?.name
    : place.placeTypes.map(({ name }) => name).join(', ');

  // get the owner name if exists
  const ownerName = place.owner ? place.owner.name : null;

  if (place.company?.name === 'User Suggested') {
    topTitle = `${topTitle}${ownerName ? `: ${place.owner.name}` : ``}`;
  }

  const countryCode = place.country?.code?.toLowerCase();

  const handleEdit = () => {
    onEdit(placeToPlaceFormValues(place));
  };

  const handleDelete = () => {
    onDelete(place);
  };

  return (
    <Card>
      <LeftSection>
        <ImageWrapper>
          <Imgix width={90} height={90} src={place.imageUrl} />
        </ImageWrapper>
        <Details>
          <TextWrapper>
            <LightGreyText>{topTitle}</LightGreyText>
            <CardTitle type="button" onClick={handleEdit}>
              {place.name}
            </CardTitle>
          </TextWrapper>
          <DetailsBlock>
            {countryCode && <Flag className={`fi fi-${countryCode}`}></Flag>}
            <AddressText>{place.address}</AddressText>
          </DetailsBlock>
          {place.drinksMenu && (
            <MenuLink to={`/drinks-menus/edit/${place.drinksMenu.id}`}>
              <DetailsBlock>
                <MenuWrapper>
                  <MenuIcon />
                </MenuWrapper>
                <AddressText>{place.drinksMenu.name}</AddressText>
              </DetailsBlock>
            </MenuLink>
          )}
        </Details>
      </LeftSection>
      <div />
      <MetaDataItem>
        <MetaDataIcon>
          <CreatedAt />
        </MetaDataIcon>
        <MetaDataText>
          <MetaDataLabel>Created</MetaDataLabel>
          <MetaDataValue>{formatDate(place.createdAt)}</MetaDataValue>
        </MetaDataText>
      </MetaDataItem>
      <MetaDataItem>
        <MetaDataIcon>
          <UpdatedAt />
        </MetaDataIcon>
        <MetaDataText>
          <MetaDataLabel>Updated</MetaDataLabel>
          <MetaDataValue>{formatDate(place.updatedAt)}</MetaDataValue>
        </MetaDataText>
      </MetaDataItem>
      <ProductItem>
        <MetaDataIcon>
          <Products />
        </MetaDataIcon>
        <MetaDataText>
          <MetaDataLabel>Products</MetaDataLabel>
          <MetaDataValue>
            {place.drinksMenu?.productsCount ?? '-'}
          </MetaDataValue>
        </MetaDataText>
      </ProductItem>

      <Menu>
        {({ open }) => (
          <ActionsMenuWrapper>
            <Menu.Button isOpen={open} as={ActionsMenuButton}>
              <MoreVertical />
            </Menu.Button>
            <Menu.Items as={ActionsMenu}>
              <Menu.Item
                as={ActionsMenuItem}
                onClick={handleEdit}
                type="button"
              >
                <EditIcon />
                Edit
              </Menu.Item>
              <Menu.Item
                as={ActionsMenuItem}
                onClick={handleDelete}
                type="button"
              >
                <DeleteIcon />
                Delete
              </Menu.Item>
            </Menu.Items>
          </ActionsMenuWrapper>
        )}
      </Menu>
    </Card>
  );
};
