import React, { Suspense, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { deleteReview, updateReview } from '../api/reviews.api';
import { Formik } from 'formik';
import { ReviewForm, validateForm } from '../components/ReviewForm';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { reviewsApiResponseToFormValues } from '../api/reviews.dto';
import { PageFrame } from '../../common/components/PageFrame';

export const EditReviewPage = () => {
  const history = useHistory();
  const { id: reviewId } = useParams();
  const {
    data: review,
    error,
    isValidating: loading,
  } = useSWR(`/portal/reviews/${reviewId}`, fetcher);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateReview(reviewId, values);
      await mutate(`/portal/reviews/${reviewId}`);
      await mutate(`/portal/reviews`);
      history.goBack();
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this location',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteReview(reviewId);
      await mutate(`/portal/reviews`);
      history.goBack();
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this location',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Edit Review">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {review && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={reviewsApiResponseToFormValues(review)}
          validate={validateForm}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <ReviewForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
