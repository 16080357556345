import React, { Suspense, useState } from 'react';
import { BrandsDataList } from '../components/BrandsDataList';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { useUpdateTitle } from '../../common/hooks/useUpdateTitle';
import { PageFrame } from '../../common/components/PageFrame';
import { AddButton } from '../../common/components/AddButton';
import { SearchBar } from '../../common/components/SearchBar';

export const BrandsListPage = () => {
  useUpdateTitle('Brands');
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame
      title="Brands"
      topBarContent={
        <>
          <SearchBar placeholder="Search brands" onChange={setQuery} />
          <AddButton text="Add Brand" to="/brands/add" />
        </>
      }
    >
      <ListSearchAndLimit
        limit={limit}
        setLimit={setLimit}
        onSearch={setQuery}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <BrandsDataList
          offset={offset}
          limit={limit}
          query={query}
          setOffset={setOffset}
        />
      </Suspense>
    </PageFrame>
  );
};
