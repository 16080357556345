import { AddressSearchInput } from '../AddressSearchInput/AddressSearchInput';
import styled from 'styled-components';
import { useLoadScript } from '@react-google-maps/api';
import { appConfig } from '../../../common/app.config';
import { ExplainerText, FormGrid, LabelText } from './styles';
import { convertGooglePlaceToAddress } from './utils';

const EnterManuallyWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
`;

const EnterManually = styled.button`
  background: none;
  border: none;
  padding: 0 0 2px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454a5c;
  border-bottom: 1px solid #454a5c;
  cursor: pointer;
`;

const libraries = ['places'];

export const AddressFinder = ({
  onSelectAddress,
  searchTerm,
  onSearchTermChange,
  searchResults,
  onSearchResultsChange,
}) => {
  useLoadScript({
    googleMapsApiKey: appConfig.googleMaps.apiKey,
    libraries,
  });

  const handleGooglePlacesSearch = (searchTerm) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      // Setup request
      const request = {
        query: searchTerm,
      };

      // Make the request
      service.textSearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Limit to 6 results
          const limitedResults = results
            .slice(0, 6)
            .map(convertGooglePlaceToAddress);
          onSearchResultsChange(limitedResults);
          resolve(limitedResults);
        } else {
          reject(status);
        }
      });
    });
  };

  return (
    <FormGrid>
      <LabelText>Address finder *</LabelText>
      <div>
        <AddressSearchInput
          onSearch={handleGooglePlacesSearch}
          onSelectAddress={onSelectAddress}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          searchResults={searchResults}
          onSearchResultsChange={onSearchResultsChange}
        />
        <ExplainerText>
          Use the ‘address finder’ above to select your place from Google Maps &
          we’ll pre-fill information for you.
        </ExplainerText>

        <EnterManuallyWrapper>
          or <EnterManually>Enter address manually</EnterManually>
        </EnterManuallyWrapper>
      </div>
    </FormGrid>
  );
};
