import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './styles.css';

export const TextInput = ({
  label,
  name,
  value,
  onChange,
  error,
  ...props
}) => {
  return (
    <div>
      <label htmlFor={name} className="bw-form-label">
        {label}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className="bw-form-input"
        {...props}
      />
      {error && <p className="text-xs text-red-600 mt-2">{error}</p>}
    </div>
  );
};
