import styled, { css } from 'styled-components';
import { ReactComponent as Tick } from './tick.svg';
import useSWR from 'swr';
import { fetcher } from '../../../common/api/fetcher';
import { forwardRef } from 'react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Label = styled.label`
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a4a6af;
  border-radius: 5px;
  margin: 0;

  border: 1px solid #d9dbdd;
  padding: 8px;
  gap: 6px;

  ${HiddenCheckbox}:focus + & {
    border: 1px solid #ed6921;
    cursor: pointer;
  }

  &:hover {
    border: 1px solid #ed6921;
    cursor: pointer;
  }

  ${({ checked }) =>
    checked &&
    css`
      border: 1px solid #ed6921;
      background-color: #fdf2ec;
      color: #ed6921;
    `}
`;

export const StyledCheckbox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: ${(props) => (props.checked ? '#ed6921' : '#fff')};
  border: 1px solid ${(props) => (props.checked ? '#ed6921' : '#D9DBDD')};
  border-radius: 3px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const PlaceTypesSelector = forwardRef(
  ({ value, onChange, error }, ref) => {
    const { data: placeTypes } = useSWR('/place-types', fetcher);

    const options =
      placeTypes?.map((placeType) => ({
        label: placeType.name,
        value: placeType.slug,
      })) ?? [];

    return (
      <div>
        <Wrapper>
          {options.map((option) => {
            const checked = value.includes(option.value);

            return (
              <Label key={option.value} checked={checked}>
                <CheckboxContainer>
                  <HiddenCheckbox
                    checked={checked}
                    onChange={() => {
                      if (checked) {
                        const newValue = value.filter(
                          (i) => i !== option.value
                        );
                        onChange(newValue);
                      } else {
                        const newValue = [...value, option.value];
                        onChange(newValue);
                      }
                    }}
                  />
                  <StyledCheckbox checked={checked}>
                    <Icon as={Tick} />
                  </StyledCheckbox>
                </CheckboxContainer>
                {option.label}
              </Label>
            );
          })}
        </Wrapper>
      </div>
    );
  }
);
