import React from 'react';
import logo from '../../../img/logo192br.png';
import { PageFrame } from '../components/PageFrame';

export const HomePage = () => {
  return (
    <PageFrame title="Drinks Cupboard">
      <header className="login-header">
        <img
          src={logo}
          className="w-64 h-auto mx-auto mb-2"
          alt="Better Without"
        />
        <h1 className="font-bold text-center text-2xl">Drinks Cupboard</h1>
      </header>
    </PageFrame>
  );
};
