import InputGroup from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/Form';
import React from 'react';

export const ListSearchAndLimit = ({ limit, setLimit }) => {
  return (
    <div className="md:text-right md:flex md:justify-content-end justify-content-end items-center pl-2 pr-2 pb-2">
      <InputGroup>
        <span>Show</span>
        <FormControl
          as="select"
          type="select"
          size="sm"
          defaultValue={limit}
          onChange={({ target }) => setLimit(target.value)}
        >
          <option>2</option>
          <option>10</option>
          <option>20</option>
          <option>50</option>
          <option>100</option>
          <option>1000</option>
        </FormControl>
      </InputGroup>
    </div>
  );
};
