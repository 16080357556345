import { useAuth } from 'reactfire';
import { useEffect } from 'react';
import { signOut } from 'firebase/auth';

export const LogoutPage = () => {
  const auth = useAuth();

  useEffect(() => {
    signOut(auth);
  }, []);

  return null;
};
