import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, useFormikContext } from 'formik';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { ProductsSelector } from '../../products/components/ProductsSelector';
import omit from 'lodash/omit';

export const RetailersProductList = () => {
  const { values, setFieldValue } = useFormikContext();
  const productIds = Object.keys(values?.stockedProducts || {});

  return (
    <div>
      {!productIds?.length ? (
        <Form.Control
          readOnly={true}
          type="text"
          plaintext
          defaultValue="None"
        />
      ) : (
        <Table striped bordered>
          <thead>
            <tr>
              <th>Product</th>
              <th>Website Link to Product</th>
              <th width="15" />
            </tr>
          </thead>
          <tbody>
            {Object.keys(values.stockedProducts).map((id) => {
              const product = values.stockedProducts[id];
              return (
                <tr key={id}>
                  <td>{product.name}</td>
                  <td>
                    <Field name={`stockedProducts.${id}.url`}>
                      {({ field, meta }) => (
                        <Form.Control
                          as="input"
                          type="text"
                          {...field}
                          isInvalid={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </td>
                  <td width="15">
                    <Button
                      variant="outline-danger"
                      type="button"
                      className="border-orange-br bg-orange-br text-white"
                      onClick={() => {
                        setFieldValue(
                          'stockedProducts',
                          omit(values?.stockedProducts, [id])
                        );
                      }}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <ProductsSelector
        url="/portal/retailers/products"
        onSelect={(product) => {
          setFieldValue('stockedProducts', {
            ...values?.stockedProducts,
            [product.id]: {
              name: product.name,
            },
          });
        }}
      />
    </div>
  );
};
