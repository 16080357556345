import React, { useEffect, useRef } from 'react';
import { useFilter } from '../../context/filterContext';
import { Portal } from '../../../common/components/Portal';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const Filter = ({ filterId, renderContent, position }) => {
  const { activeFilter, setActiveFilter } = useFilter();

  const filterStyle = {
    position: 'absolute',
    top: position.top,
    left: position.left,
    zIndex: 2,
  };

  const filterRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setActiveFilter]);

  if (activeFilter !== filterId) {
    return null;
  }

  return (
    <Portal>
      <Overlay onClick={() => setActiveFilter(null)} />
      <div ref={filterRef} style={filterStyle}>
        {renderContent()}
      </div>
    </Portal>
  );
};
