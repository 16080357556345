import React, { Suspense, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ProductReviewsDataList } from '../components/ProductReviewsDataList';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { PageFrame } from '../../common/components/PageFrame';

export const ProductReviewsListPage = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame title="Reviews">
      <ListSearchAndLimit
        limit={limit}
        setLimit={setLimit}
        onSearch={() => {}}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <ProductReviewsDataList offset={offset} limit={limit} />
      </Suspense>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        className="border-orange-br bg-orange-br"
        variant="secondary"
        type="button"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </PageFrame>
  );
};
