import { Dialog } from '@headlessui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as Close } from './close.svg';
import { useContext, useReducer, useState } from 'react';
import { PrimaryButton } from '../../../common/components/PrimaryButton';
import { addFormSteps, editFormSteps, FormStepsMap } from './FormSteps';
import { ReactComponent as Warning } from './warning.svg';
import {
  BackButton,
  ButtonFlex,
  Center,
  CloseButton,
  Content,
  ContentArea,
  ErrorText,
  Footer,
  Header,
  LinkButton,
  Overlay,
  Scrollable,
  Title,
  TitleWrapper,
} from './styles';
import {
  addressFinderInitialState,
  addressFinderReducer,
} from './addressFinder.state';
import { noop } from 'lodash/util';
import { useHistory } from 'react-router-dom';
import { StepIcon } from './StepIcon';
import { useHandleAddressSelect } from './hooks/useHandleAddressSelect';
import styled from 'styled-components';
import { useFormSteps } from './hooks/useFormSteps';
import { UserContext } from '../../../common/context/userContext';
import { UserRoles } from '../../../common/common.const';

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const SubItem = ({ step, onClick }) => {
  switch (step) {
    case FormStepsMap.REMINDER:
      return <LinkButton onClick={onClick}>I'll do this later</LinkButton>;
    default:
      return null;
  }
};

export const AddOrEditPlaceModal = ({ isOpen, isEditMode, onClose, place }) => {
  const { role, user } = useContext(UserContext);
  const defaultValues = isEditMode
    ? place
    : {
        placeTypes: [],
      };

  if (role !== UserRoles.Admin && !defaultValues.company) {
    defaultValues.company = user.company;
  }

  const history = useHistory();
  const methods = useForm({
    defaultValues: defaultValues,
  });
  const {
    formState: { errors },
    setValue,
  } = methods;

  const [state, dispatch] = useReducer(
    addressFinderReducer,
    addressFinderInitialState
  );

  const initialStep = isEditMode ? editFormSteps[0] : addFormSteps[0];
  const steps = isEditMode ? editFormSteps : addFormSteps;

  const { currentStep, goToNextStep, goToPreviousStep, resetSteps } =
    useFormSteps(initialStep, steps);
  const [savedPlace, setSavedPlace] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const handleClose = () => {
    setErrorMessage(null);
    resetSteps();
    setSavedPlace(null);
    dispatch({
      type: 'reset',
    });
    onClose();
  };

  const handleSubClose = () => {
    onClose();
    window.location.reload();
  };

  const handleNext = () => {
    setErrorMessage(null);
    goToNextStep();
  };

  const handleBack = () => {
    // if the current step is the first step, close the modal
    if (currentStep.id === steps[0].id) {
      handleClose();
      return;
    }

    setErrorMessage(null);
    goToPreviousStep();
  };

  const onSubmit = async (values) => {
    if (Object.keys(errors).length > 0) {
      // There are errors, do not proceed to next step.
      return;
    }

    if (currentStep.handleSubmit) {
      await currentStep.handleSubmit({
        placeId: place?.id,
        goToNextStep,
        setError: setErrorMessage,
        savedPlace,
        setSavedPlace,
        history,
        values,
        onClose,
      });
    }
  };

  const handleSearchTermChange = (searchTerm) => {
    dispatch({
      type: 'setSearchTerm',
      payload: searchTerm,
    });
  };

  const handleSearchResultsChange = (searchResults) => {
    dispatch({
      type: 'setSearchResults',
      payload: searchResults,
    });
  };

  const handleAddressSelect = useHandleAddressSelect(setValue, handleNext);
  const FormStepComponent = currentStep.component;

  const getErrorMessage = () => {
    if (currentStep.id === FormStepsMap.PLACE_TYPES) {
      if (errors.placeTypes) {
        return 'Must select a type';
      }
    }
    if (errorMessage) {
      return errorMessage;
    }
    return null;
  };

  return (
    <Dialog as="div" open={isOpen} onClose={noop}>
      <Overlay aria-hidden="true" />
      <Scrollable>
        <Center>
          <Content as={Dialog.Panel}>
            <Header>
              <Dialog.Title as="div">
                <TitleWrapper>
                  <StepIcon step={currentStep.id} />
                  <Title>{currentStep.stepTitle}</Title>
                </TitleWrapper>
              </Dialog.Title>

              <CloseButton onClick={handleClose}>
                <Close />
              </CloseButton>
            </Header>
            <ContentArea>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {currentStep.component && (
                    <FormStepComponent
                      onSelectAddress={handleAddressSelect}
                      searchTerm={state.searchTerm}
                      onSearchTermChange={handleSearchTermChange}
                      searchResults={state.searchResults}
                      onSearchResultsChange={handleSearchResultsChange}
                      place={savedPlace}
                    />
                  )}
                </form>
              </FormProvider>
            </ContentArea>
            {currentStep && currentStep.id !== FormStepsMap.ADDRESS_FINDER && (
              <Footer>
                <div>
                  {currentStep.backButtonText && (
                    <BackButton onClick={handleBack}>
                      {currentStep.backButtonText}
                    </BackButton>
                  )}
                </div>

                <ButtonFlex>
                  <SubItem step={currentStep.id} onClick={handleSubClose} />
                  {getErrorMessage() && (
                    <ErrorContainer>
                      <Warning />
                      <ErrorText>{getErrorMessage()}</ErrorText>
                    </ErrorContainer>
                  )}
                  <PrimaryButton
                    text={currentStep.nextButtonText}
                    onClick={methods.handleSubmit(onSubmit)}
                  />
                </ButtonFlex>
              </Footer>
            )}
          </Content>
        </Center>
      </Scrollable>
    </Dialog>
  );
};
