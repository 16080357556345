import clsx from 'classnames';
import React from 'react';

export const NewsProductTypeSelector = ({ options, value, onChange }) => {
  const handleClickOption = (drinkType) => {
    if (value.includes(drinkType)) {
      onChange(value.filter((type) => type !== drinkType));
    } else {
      onChange([...value, drinkType]);
    }
  };

  return (
    <div className="flex items-start flex-wrap">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          className="whitespace-nowrap mb-2 mr-2"
          onClick={() => handleClickOption(option.value)}
        >
          <div
            className={clsx(
              'rounded-full bg-gray-light px-3 py-1.5 text-sm font-medium text-sm',
              {
                'bg-orange-br text-white': value.includes(option.value),
              }
            )}
          >
            {option.label}
          </div>
        </button>
      ))}
    </div>
  );
};
