import styled from 'styled-components';

import { ReactComponent as SortAscending } from './PlaceResultItem/sort-ascending.svg';
import { ReactComponent as SortDescending } from './PlaceResultItem/sort-descending.svg';
import {
  largeScreenTemplateColumns,
  smallScreenTemplateColumns,
} from './PlaceResultItem/PlaceResultItem';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${largeScreenTemplateColumns};
  grid-column-gap: 16px;
  grid-template-rows: 1fr;
  padding-left: 10px;
  max-width: 1244px;
  margin: -16px auto 4px;

  @media (max-width: 1300px) {
    grid-template-columns: ${smallScreenTemplateColumns};
  }
`;

const ResultsCount = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d808d;
`;

const SortingButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    stroke: ${({ selected }) => (selected ? '#7D808D' : '#D9DBDD')};
  }

  &:hover {
    svg {
      stroke: #7d808d;
    }
  }

  @media (max-width: 1300px) {
    &:last-of-type {
      display: none;
    }
  }
`;

export const TableCountAndSort = ({ totalCount, sort, onSortChange }) => {
  const sortDirection = sort.order;
  const sortColumn = sort.field;

  const handleSort = (column) => {
    if (column === sortColumn) {
      onSortChange(sortColumn, sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      onSortChange(column, 'desc');
    }
  };

  return (
    <Container>
      <ResultsCount>{totalCount} results</ResultsCount>
      <div />
      <SortingButton
        selected={sortColumn === 'createdAt'}
        type="button"
        onClick={() => handleSort('createdAt')}
      >
        {sortDirection === 'desc' && sortColumn === 'createdAt' ? (
          <SortDescending />
        ) : (
          <SortAscending />
        )}
      </SortingButton>
      <SortingButton
        selected={sortColumn === 'updatedAt'}
        type="button"
        onClick={() => handleSort('updatedAt')}
      >
        {sortDirection === 'desc' && sortColumn === 'updatedAt' ? (
          <SortDescending />
        ) : (
          <SortAscending />
        )}
      </SortingButton>
      <SortingButton
        selected={sortColumn === 'productsCount'}
        type="button"
        onClick={() => handleSort('productsCount')}
      >
        {sortDirection === 'desc' && sortColumn === 'productsCount' ? (
          <SortDescending />
        ) : (
          <SortAscending />
        )}
      </SortingButton>
    </Container>
  );
};
