import React from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import format from 'date-fns/format';
import { NoResults } from '../../common/components/NoResults';

export const PushNotificationsDataList = ({
  offset,
  limit,
  query,
  setOffset,
}) => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/push-notifications?${stringify({
      offset,
      limit,
      ...(query !== '' && { query }),
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching brands, please try again"
        onClose={revalidate}
      />
    );
  }

  if (data?.length === 0) {
    return <NoResults />;
  }

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <td>Headline</td>
            <td>Text</td>
            <td>Brand</td>
            <td>Created At</td>
            <td>Send At</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {data.map((notification) => {
            return (
              <tr
                key={notification.id}
                onClick={() =>
                  history.push(`/push-notifications/edit/${notification.id}`)
                }
              >
                <td>{notification.headline}</td>
                <td>{notification.body}</td>
                <td>{notification.brand?.name}</td>
                <td>{format(new Date(notification.createdAt), 'PPpp')}</td>
                <td>{format(new Date(notification.sendAt), 'PPpp')}</td>
                <td>{notification.status}</td>
                <td>Edit</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </div>
  );
};
