import { useHistory, useParams } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { approveProductEdit, denyProductEdit } from '../api/edits.api';
import { productsApiResponseToFormValues } from '../../products/api/products.dto';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { ProductEditForm } from '../components/ProductEditForm';
import { PageFrame } from '../../common/components/PageFrame';

export const ViewProductEditPage = () => {
  const history = useHistory();
  const { id: productEditId } = useParams();
  const { data: productEdit, error } = useSWR(
    `/portal/edits/products/${productEditId}`,
    fetcher
  );
  const [errorBox, setErrorBox] = useState(noError);
  const [loadingValue, setLoadingValue] = useState(null);

  const handleApprove = async () => {
    setLoadingValue('approve');
    await approveProductEdit(productEditId);
    await mutate(`/portal/edits/products`);
    await mutate(`/portal/edits`);
    history.push('/edits/list');
  };

  const handleDeny = async () => {
    setLoadingValue('deny');
    await denyProductEdit(productEditId);
    await mutate(`/portal/edits/products`);
    await mutate(`/portal/edits`);
    history.push('/edits/list');
  };

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading product',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="View Product Edit">
      {!productEdit && <LoadingSpinner />}
      {productEdit && (
        <Suspense fallback={LoadingSpinner}>
          <ProductEditForm
            onApprove={handleApprove}
            onDeny={handleDeny}
            value={productsApiResponseToFormValues(productEdit)}
            loadingValue={loadingValue}
          />
        </Suspense>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
