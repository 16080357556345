import React from 'react';
import clsx from 'classnames';
import { Label } from './Label';

export const Radio = ({ label, name, value, onChange, options }) => {
  return (
    <div>
      <Label>{label}</Label>
      <div className="relative">
        {options.map((option) => (
          <label
            key={option.value}
            className={clsx(
              'cursor-pointer rounded-full bg-gray-light text-black px-3 py-1.5 text-sm font-medium text-sm min-w-24 text-center mr-2',
              {
                ['bg-orange-br text-white']: value === option.value,
              }
            )}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={onChange}
              className="absolute h-0 w-0 cursor-pointer"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};
