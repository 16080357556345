import React from 'react';
import logo from '../../../img/logo_light.png';
import chartExample from '../../../img/chart-example.png';

export const DashboardPage = () => {
  return (
    <div className="p-4 w-full">
      <header className="login-header">
        <img src={logo} className="login-logo" alt="Better Without" />
        <h1 className="">Dashboard</h1>
      </header>
      <img
        src={chartExample}
        width="100%"
        alt="Example Chart"
        className="chart-example"
      />
    </div>
  );
};
