import React from 'react';
import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import DataInput from '../../common/components/DataInput';
import Button from 'react-bootstrap/Button';
import ImageSelector from '../../common/components/ImageSelector';
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export const validateForm = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'You must provide a name';
  }
  return errors;
};

export const CompanyForm = ({ onDelete }) => {
  const { setFieldValue, handleSubmit, isSubmitting } = useFormikContext();
  const history = useHistory();

  return (
    <Form className="detailsTable" onSubmit={handleSubmit}>
      <Field name="name">
        {({ field, meta }) => {
          return (
            <>
              <DataInput
                id={field.name}
                label="Name"
                placeholder=""
                {...field}
              />
              {meta.error && (
                <div className="text-red-500 text-xs mt-2 mb-3">
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="imageUrl">
        {({ field }) => {
          return (
            <ImageSelector
              label="Logo"
              value={field.value}
              onChange={(imageUrl) => {
                setFieldValue('imageUrl', imageUrl, false);
              }}
              onDelete={() => {
                setFieldValue('imageUrl', null, false);
              }}
            />
          );
        }}
      </Field>

      <Button variant="primary" type="submit" className="mr-3 border-orange-br bg-orange-br">
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>

      <Button
        variant="primary"
        type="button"
        onClick={history.goBack}
        className="mr-3 border-orange-br bg-orange-br"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button variant="primary" type="button" className="border-orange-br bg-orange-br" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Form>
  );
};
