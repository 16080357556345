import React, { useState } from 'react';
import { useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import DataInput from '../../common/components/DataInput';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { updatePassword } from '../api/auth.api';
import { PageFrame } from '../../common/components/PageFrame';

export const ChangePasswordPage = () => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const history = useHistory();
  const [oldpw, setOldpw] = useState('');
  const [newpw, setNewpw] = useState('');
  const [newpw2, setNewpw2] = useState('');
  const [errMsg, setErrMsg] = useState(false);
  const [busy, setBusy] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setBusy(true);

    try {
      await updatePassword(auth, oldpw, newpw);
      setErrMsg('Password changed');
      setBusy(false);
    } catch (e) {
      setErrMsg(e.message);
      setBusy(false);
    }
  };

  function pwOK() {
    return newpw !== newpw2 || newpw === '';
  }

  return (
    <PageFrame title="Change Password">
      <Form className="mt-4 mb-0 max-w-2xl" onSubmit={handleSubmit}>
        <DataInput
          id="oldpw"
          label="Old"
          placeholder="Old Password"
          inputType="password"
          autoComplete="current-password"
          defaultValue={newpw}
          onChange={(e) => setOldpw(e.target.value)}
        />
        <DataInput
          id="newpw"
          label="New"
          placeholder="New Password"
          inputType="password"
          autoComplete="old-password"
          defaultValue={newpw}
          onChange={(e) => setNewpw(e.target.value)}
        />
        <DataInput
          id="newpw2"
          label="Confirm"
          placeholder="Confirm Password"
          inputType="password"
          autoComplete="old-password"
          defaultValue={newpw2}
          onChange={(e) => setNewpw2(e.target.value)}
        />
        <br />
        {busy && (
          <div>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}

        {!busy && (
          <div>
            <br />
            {newpw !== newpw2 && (
              <div className="errmsg">Passwords don't match</div>
            )}
            <Button
              variant="primary"
              type="submit"
              className="marginR border-orange-br bg-orange-br"
              disabled={pwOK()}
            >
              Change Password
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={history.goBack}
              className="marginR border-orange-br bg-orange-br"
            >
              Cancel
            </Button>
            {errMsg !== '' && <div className="text-red-500 my-2">{errMsg}</div>}
          </div>
        )}
      </Form>
    </PageFrame>
  );
};
