import React from 'react';
import clsx from 'classnames';
import xor from 'lodash/xor';
import { PushNotificationsCountrySelectorChip } from '../../pushNotifications/components/PushNotificationsCountrySelectorChip';
import { TypeAheadInput } from '../../../components/forms/TypeAheadInput';

const CountrySelector = ({ options = [], value = [], onChange }) => {
  const handleSelect = (countryId) => {
    onChange(xor(value, [countryId]));
  };

  const handleReset = () => {
    onChange([]);
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <button
          type="button"
          className={clsx('rounded-full px-2 py-1.5 font-medium text-sm w-28', {
            'bg-gray-light text-black': value?.length,
            'bg-orange-br text-white': !value?.length,
          })}
          onClick={handleReset}
        >
          All
        </button>
        <div className="ml-2 w-full">
          <TypeAheadInput
            placeholder="Search countries..."
            options={options}
            onSelectOption={handleSelect}
            selectedOptions={value}
          />
        </div>
      </div>
      <div className="mt-4 flex">
        {value?.map((countryId) => {
          const country = options.find(({ value }) => value === countryId);
          return (
            <div key={countryId} className="mr-2">
              <PushNotificationsCountrySelectorChip
                value={country.value}
                label={country.label}
                onClick={() => handleSelect(country.value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CountrySelector;
