import { UserContext } from '../context/userContext';
import { useContext } from 'react';

export const RoleBasedComponent = ({ requiredRoles, children }) => {
  const { role } = useContext(UserContext);

  if (requiredRoles.includes(role)) {
    return children;
  }

  return null;
};
