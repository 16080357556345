import { client } from '../../common/api/client';
import {
  giveawayFormValuesToCreateGiveawaysDto,
  giveawayFormValuesToUpdateGiveawaysDto,
} from './giveaways.dto';

export const createGiveaway = (values) => {
  const createGiveawayDto = giveawayFormValuesToCreateGiveawaysDto(values);
  return client.post('/portal/giveaways', createGiveawayDto);
};

export const updateGiveaway = (id, values) => {
  const updateGiveawayDto = giveawayFormValuesToUpdateGiveawaysDto(values);
  return client.patch(`/portal/giveaways/${id}`, updateGiveawayDto);
};

export const deleteGiveaway = (id) => {
  return client.delete(`/portal/giveaways/${id}`);
};