import React from 'react';
import useSWR from 'swr';
import Form from 'react-bootstrap/Form';
import DataSelect from '../../common/components/DataSelect';
import DataInput from '../../common/components/DataInput';
import { SiteAddressAutocompleteInput } from '../../places/components/SiteAddressAutocompleteInput';
import ImageSelector from '../../common/components/ImageSelector';
import Button from 'react-bootstrap/Button';
import { fetcher } from '../../common/api/fetcher';
import Spinner from 'react-bootstrap/Spinner';

export const BrandEditForm = ({ value, onApprove, onDeny, loadingValue }) => {
  const { data: companies } = useSWR(
    `/portal/companies?id=${value.companyId}`,
    fetcher
  );

  return (
    <Form className="detailsTable">
      <DataSelect
        label="Company"
        type="select"
        buttons={false}
        onChange={() => {}}
        options={[
          { value: '', label: 'Select a company' },
          ...(companies || [])?.map((company) => ({
            value: company.id,
            label: company.name,
          })),
        ]}
        value={value.companyId}
        readOnly
      />
      <DataInput label="Name" value={value.name} readOnly />
      <DataInput label="Email" value={value.email} readOnly />
      <SiteAddressAutocompleteInput
        label="Address"
        value={value.address}
        readOnly
      />
      <DataInput value={value.bio} label="Bio" as="textarea" readOnly />
      <DataInput label="Founded" value={value.foundedYear} readOnly />
      <DataInput label="Founder" value={value.founder} readOnly />
      <DataInput label="Phone number" value={value.phoneNumber} readOnly />
      <DataInput label="Website" value={value.url} readOnly />
      <ImageSelector label="Logo" value={value.imageUrl} readOnly />

      <Button
        variant="primary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        onClick={onDeny}
      >
        {loadingValue === 'deny' ? (
          <Spinner animation="border" size="sm" />
        ) : (
          'Deny'
        )}
      </Button>

      <Button
        variant="primary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        onClick={onApprove}
      >
        {loadingValue === 'approve' ? (
          <Spinner animation="border" size="sm" />
        ) : (
          'Approve'
        )}
      </Button>
    </Form>
  );
};
