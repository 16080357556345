import React from 'react';
import './styles.css';

export const Select = ({
  name,
  label,
  value,
  onChange,
  children,
  error,
  ...props
}) => {
  return (
    <div>
      <label className="bw-form-label" htmlFor={name}>
        {label}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className="bw-form-select"
        {...props}
      >
        {children}
      </select>
      {error && <p className="text-xs text-red-600 mt-2">{error}</p>}
    </div>
  );
};
