import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ScrollToTop } from 'react-router-scroll-to-top';
import { LoginPage } from './modules/auth/pages/LoginPage';
import { HomePage } from './modules/common/pages/HomePage';
import { ChangePasswordPage } from './modules/auth/pages/ChangePasswordPage';
import { useSigninCheck } from 'reactfire';
import { DashboardPage } from './modules/common/pages/DashboardPage';
import { PushNotificationsListPage } from './modules/pushNotifications/pages/PushNotificationsListPage';
import { PlacesListPage } from './modules/places/pages/PlacesListPage';
import { AddPlacePage } from './modules/places/pages/AddPlacePage';
import { EditPlacePage } from './modules/places/pages/EditPlacePage';
import { CompaniesListPage } from './modules/companies/pages/CompaniesListPage';
import { EditCompanyPage } from './modules/companies/pages/EditCompanyPage';
import { AddCompanyPage } from './modules/companies/pages/AddCompanyPage';
import { AddDrinksMenuPage } from './modules/drinksMenu/pages/AddDrinksMenuPage';
import { EditDrinksMenuPage } from './modules/drinksMenu/pages/EditDrinksMenuPage';
import { BrandsListPage } from './modules/brands/pages/BrandsListPage';
import { AddBrandPage } from './modules/brands/pages/AddBrandPage';
import { EditBrandPage } from './modules/brands/pages/EditBrandPage';
import { UsersListPage } from './modules/users/pages/UsersListPage';
import { EditUserPage } from './modules/users/pages/EditUserPage';
import { AddUserPage } from './modules/users/pages/AddUserPage';
import { ProductsListPage } from './modules/products/pages/ProductsListPage';
import { EditProductPage } from './modules/products/pages/EditProductPage';
import { AddProductPage } from './modules/products/pages/AddProductPage';
import { RetailersListPage } from './modules/retailers/pages/RetailersListPage';
import { EditRetailerPage } from './modules/retailers/pages/EditRetailerPage';
import { NewsListPage } from './modules/news/pages/NewsListPage';
import { ProductReviewsListPage } from './modules/reviews/pages/ProductReviewsListPage';
import { LogoutPage } from './modules/auth/pages/LogoutPage';
import { EditsListPage } from './modules/edits/pages/EditsListPage';
import { DrinksMenusListPage } from './modules/drinksMenu/pages/DrinksMenusListPage';
import { ViewBrandEditPage } from './modules/edits/pages/ViewBrandEditPage';
import { ViewProductEditPage } from './modules/edits/pages/ViewProductEditPage';
import { ViewPlaceEditPage } from './modules/edits/pages/ViewPlaceEditPage';
import { EditNewsPage } from './modules/news/pages/EditNewsPage';
import { AddNewsPage } from './modules/news/pages/AddNewsPage';
import { UserReviewsListPage } from './modules/reviews/pages/UserReviewsListPage';
import { EditReviewPage } from './modules/reviews/pages/EditReviewPage';
import { AddRetailerPage } from './modules/retailers/pages/AddRetailerPage';
import { EditPushNotificationPage } from './modules/pushNotifications/pages/EditPushNotificationPage';
import { AddPushNotificationPage } from './modules/pushNotifications/pages/AddPushNotificationPage';

import { AppFrame } from './modules/common/components/AppFrame';
import { UserProvider } from './modules/common/context/userContext';
import { PrivateRoute } from './modules/common/components/PrivateRoute';
import { UserAdminIds, UserRoles } from './modules/common/common.const';
import { GiveawaysListPage } from './modules/giveaways/pages/GiveawaysListPage';
import { AddGiveawayPage } from './modules/giveaways/pages/AddGiveawayPage';
import { EditGiveawayPage } from './modules/giveaways/pages/EditGiveawayPage';
import { GiveawayEntriesListPage } from './modules/giveaways/pages/GiveawayEntriesListPage';
import { SkeletonAppFrame } from './modules/common/components/SkeletonAppFrame';

export default function Routes() {
  const { status, data: signInCheckResult } = useSigninCheck({
    suspense: true,
  });

  if (status === 'loading') {
    return <SkeletonAppFrame />;
  }

  if (signInCheckResult.signedIn === true) {
    return (
      <Suspense fallback={<SkeletonAppFrame />}>
        <UserProvider>
          <Router>
            <ScrollToTop />
            <Route exact path="/logout" component={LogoutPage} />
            <Switch>
              <AppFrame>
                <PrivateRoute exact path="/">
                  <HomePage />
                </PrivateRoute>
                <PrivateRoute exact path="/dashboard">
                  <DashboardPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/brands/add"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.BrandManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <AddBrandPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/brands/edit/:id"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.BrandManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <EditBrandPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/brands/list"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.BrandManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <BrandsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/products/add"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.BrandManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <AddProductPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/products/edit/:id"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.BrandManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <EditProductPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/products/list"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.BrandManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <ProductsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/retailers/edit/:id"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.RetailManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <EditRetailerPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/retailers/add"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.RetailManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <AddRetailerPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/retailers/list"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.RetailManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <RetailersListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/places/edit/:id"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.LocationsManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <EditPlacePage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/places/add"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.LocationsManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <AddPlacePage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/places/list"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.LocationsManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <PlacesListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/drinks-menus/list"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.LocationsManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <DrinksMenusListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/drinks-menus/edit/:id"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.LocationsManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <EditDrinksMenuPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/drinks-menus/add"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.LocationsManager,
                    UserRoles.CompanyManager,
                  ]}
                >
                  <AddDrinksMenuPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/companies/edit/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <EditCompanyPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/companies/add"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <AddCompanyPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/companies/list"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <CompaniesListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/users/edit/:id"
                  allowedRoles={[UserRoles.Admin]}
                  allowedIds={UserAdminIds}
                >
                  <EditUserPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/users/list"
                  allowedRoles={[UserRoles.Admin]}
                  allowedIds={UserAdminIds}
                >
                  <UsersListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/users/add"
                  allowedRoles={[UserRoles.Admin]}
                  allowedIds={UserAdminIds}
                >
                  <AddUserPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/news/edit/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <EditNewsPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/news/add"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <AddNewsPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/news/list"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <NewsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/reviews/user/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <UserReviewsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/reviews/product/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <ProductReviewsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/reviews/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <EditReviewPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/edits/list"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <EditsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/edits/brands/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <ViewBrandEditPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/edits/products/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <ViewProductEditPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/edits/places/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <ViewPlaceEditPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/push-notifications/list"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <PushNotificationsListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/push-notifications/edit/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <EditPushNotificationPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/push-notifications/add/"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <AddPushNotificationPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/giveaways/list"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <GiveawaysListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/giveaways/add"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <AddGiveawayPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/giveaways/edit/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <EditGiveawayPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/giveaways/entries/:id"
                  allowedRoles={[UserRoles.Admin]}
                >
                  <GiveawayEntriesListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/change-password"
                  allowedRoles={[
                    UserRoles.Admin,
                    UserRoles.CompanyManager,
                    UserRoles.RetailManager,
                    UserRoles.BrandManager,
                    UserRoles.LocationsManager,
                  ]}
                >
                  <ChangePasswordPage />
                </PrivateRoute>
              </AppFrame>
            </Switch>
          </Router>
        </UserProvider>
      </Suspense>
    );
  } else {
    return (
      <Router>
        <Route path="/login" component={LoginPage} />
        <Redirect to="/login" />
      </Router>
    );
  }
}
