import React, { useState } from 'react';
import { fetcher } from '../../../common/api/fetcher';
import useSWR from 'swr';
import styled, { css } from 'styled-components';

import { ReactComponent as SearchIcon } from '../../../common/svg/search.svg';
import { ReactComponent as ClearIcon } from '../../../common/svg/clear.svg';

const Container = styled.div`
  width: 224px;
  background: #ffffff;
  border: 1px solid #ecedee;
  box-shadow: 0.2px 0.2px 2px rgba(69, 74, 92, 0.15);
  border-radius: 5px;
  padding: 8px 10px;
`;

const SearchLabel = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #7d808d;
  display: block;
  margin-bottom: 6px;
`;

const SearchWrapper = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 28px;
  background: #ffffff;
  border: 1px solid #d9dbdd;
  border-radius: 5px;
  padding-left: 31px;
  padding-right: 31px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #454a5c;

  &::placeholder {
    color: #a4a6af;
  }

  &:focus {
    outline: none;
    border: 1px solid #ed6921;
    box-shadow: 0 0 5px rgba(237, 105, 33, 0.4);
  }
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

const ResultsList = styled.ul`
  width: 100%;
  height: 100%;
  max-height: 112px;
  border-radius: 5px;
  padding: 0;
  background: #ffffff;
  margin: 8px 0 0;
  overflow: scroll;

  ${({ hasResults }) => css`
    border: ${hasResults ? '1px solid #ed6921' : '1px solid #d9dbdd'};
  `}
`;

const ResultItem = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #454a5c;
  padding: 0;
  width: 100%;

  &:hover {
    background: #fdf2ec;
  }
`;

const NoResultsItem = styled(ResultItem)`
  color: #a4a6af;
  text-align: center;
  padding: 8px 0;

  &:hover {
    background: transparent;
    cursor: default;
  }
`;

const ResultLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  padding: 8px 10px;

  input {
    margin-right: 8px;
  }
`;

const ResultTruncated = styled.span`
  display: inline-block;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Checkbox = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: ${({ isChecked }) =>
    isChecked ? '#ED6921' : 'transparent'};
  border: 1px solid #ed6921;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  font-size: 8px;
  margin-right: 6px;
  flex-shrink: 0;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    transform: rotate(45deg);
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  }
`;

export const SearchAndSelectFilter = ({
  appliedFilters,
  onChange,
  url,
  property,
  label,
  placeholder,
  noResultsText = 'No results found',
}) => {
  const [searchText, setSearchText] = useState('');

  const { data: searchResults, error } = useSWR(
    searchText.length > 0 ? `${url}?q=${searchText}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCheckboxChange = (filter) => {
    const index = appliedFilters.findIndex(
      (item) => item.value === filter.value && item.property === filter.property
    );

    if (index === -1) {
      onChange([...appliedFilters, filter]);
    } else {
      onChange(appliedFilters.filter((_, i) => i !== index));
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const hasResponse = Boolean(searchResults);
  const hasResults = hasResponse && searchResults.length > 0;

  return (
    <Container>
      <SearchLabel htmlFor="searchInput">{label}</SearchLabel>
      <SearchWrapper>
        <SearchIcon />
        <SearchInput
          id="searchInput"
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder={placeholder}
        />
        {searchText.length > 0 && (
          <ClearButton onClick={() => setSearchText('')}>
            <ClearIcon />
          </ClearButton>
        )}
      </SearchWrapper>

      {hasResponse && (
        <ResultsList hasResults={hasResults}>
          {hasResults ? (
            searchResults.map((result) => {
              const isChecked = appliedFilters.some(
                (filter) =>
                  filter.value === result.id && filter.property === property
              );

              return (
                <ResultItem key={result.id}>
                  <ResultLabel>
                    <Checkbox isChecked={isChecked} />
                    <input
                      type="checkbox"
                      style={{ display: 'none' }}
                      checked={isChecked}
                      onChange={() =>
                        handleCheckboxChange({
                          value: result.id,
                          label: result.name,
                          property,
                        })
                      }
                    />
                    <ResultTruncated>{result.name}</ResultTruncated>
                  </ResultLabel>
                </ResultItem>
              );
            })
          ) : (
            <NoResultsItem>{noResultsText}</NoResultsItem>
          )}
        </ResultsList>
      )}
    </Container>
  );
};
