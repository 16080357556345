import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const SkeletonWrapper = styled.div`
  // Add styles to mimic the layout and size of PlaceResultItem component
  // You can use a background color with a lighter shade to represent the loading state
  // Example:
  background: #ffffff;
  border: 1px solid #ecedee;
  width: 100%;
  height: 110px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
`;

const SkeletonImage = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ddd;
  border-radius: 4px;
`;

const SkeletonContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 16px;
`;

const SkeletonLine = styled.div`
  height: 16px;
  background-color: #ddd;
  border-radius: 4px;
`;

// export const PlaceResultSkeleton = () => (
//   <SkeletonWrapper>
//     <SkeletonImage />
//     <SkeletonContent>
//       <SkeletonLine style={{ width: '50%' }} />
//       <SkeletonLine style={{ width: '80%' }} />
//       <SkeletonLine style={{ width: '30%' }} />
//     </SkeletonContent>
//   </SkeletonWrapper>
// );

export const PlaceResultSkeleton = () => (
  <SkeletonWrapper>
    <ContentLoader height={102} width="100%" speed={3}>
      <rect x="10" y="10" rx="5" ry="5" width="90" height="90" />
      <rect x="110" y="10" rx="2" ry="2" width="85" height="12" />
      <rect x="110" y="32" rx="2" ry="2" width="185" height="14" />
      <rect x="110" y="54" rx="2" ry="2" width="340" height="18" />
      <rect x="110" y="82" rx="2" ry="2" width="130" height="12" />
    </ContentLoader>
  </SkeletonWrapper>
);
