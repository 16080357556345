export const userApiResponseToFormValues = (user) => {
  return {
    name: user.name,
    email: user.email,
    dob: user.dob,
    gender: user.gender,
    imageUrl: user.imageUrl,
    role: user.role,
    company: user.company,
    countryId: user.countryId,
    bio: user.bio,
    interests: user.interests,
    home: user.home,
    favouriteProductTypes: user.favouriteProductTypes.map(({ id }) => id),
    favouriteProducts: user.favouriteProducts.map(({ id }) => id),
    brands: user.brands.map(({ id }) => id),
    firstLogin: user.firstLogin,
    lastLogin: user.lastLogin,
    loginCount: user.loginCount,
  };
};

export const formValuesToUpdateUserDto = (user) => {
  return {
    name: user.name,
    email: user.email,
    dob: user.dob,
    gender: user.gender,
    imageUrl: user.imageUrl,
    companyId: user.company.id,
    countryId: user.countryId,
    role: user.role,
    bio: user.bio,
    interests: user.interests,
    home: user.home,
    favouriteProductTypes: user.favouriteProductTypes,
    favouriteProducts: user.favouriteProducts,
  };
};

export const formValuesToCreateUserDto = (user) => {
  return {
    name: user.name,
    email: user.email,
    password: user.password,
    companyId: user.company.id,
    countryId: user.countryId,
    role: user.role,
    dob: user.dob?.toString().slice(0, 24),
    gender: user.gender,
    imageUrl: user.imageUrl,
    bio: user.bio,
    interests: user.interests,
    home: user.home,
    favouriteProductTypes: user.favouriteProductTypes,
    favouriteProducts: user.favouriteProducts,
  };
};
