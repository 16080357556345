import React from 'react';
import useSWR from 'swr';
import Form from 'react-bootstrap/Form';
import DataInput from '../../common/components/DataInput';
import DataSelect from '../../common/components/DataSelect';
import { fetcher } from '../../common/api/fetcher';
import Row from 'react-bootstrap/Row';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Col from 'react-bootstrap/Col';
import ImageSelector from '../../common/components/ImageSelector';
import { DataSearch } from '../../common/components/DataSearch';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export const ProductEditForm = ({ value, onApprove, onDeny, loadingValue }) => {
  const { data: productTypeVariants } = useSWR(
    `/product-type-variants?productTypeId=${value.productTypeId}`,
    fetcher,
    { suspense: true }
  );
  const { data: productTypes } = useSWR('/product-types', fetcher, {
    suspense: true,
  });
  const { data: brands } = useSWR(
    `/portal/brands?id=${value.brandId}`,
    fetcher,
    {
      suspense: true,
    }
  );
  const { data: dietTypes } = useSWR('/diet-types', fetcher, {
    suspense: true,
  });
  const { data: countries } = useSWR('/countries', fetcher, { suspense: true });
  const { data: foodTypes } = useSWR('/food-types', fetcher, {
    suspense: true,
  });
  const { data: companies } = useSWR(
    `/portal/companies?id=${value.companyId}`,
    fetcher,
    { suspense: true }
  );

  return (
    <Form className="detailsTable">
      <DataSelect
        label="Company"
        type="select"
        buttons={false}
        onChange={() => {}}
        options={[
          { value: '', label: 'Select a company' },
          ...(companies || [])?.map((company) => ({
            value: company.id,
            label: company.name,
          })),
        ]}
        value={value.companyId}
        readOnly
      />
      <DataInput
        label="Name"
        placeholder="Product name"
        value={value.name}
        readOnly
      />
      <DataSelect
        label="Brand"
        type="select"
        buttons={false}
        options={[
          { value: '', label: 'Select a brand' },
          ...(brands || [])?.map((brand) => ({
            value: brand.id,
            label: brand.name,
          })),
        ]}
        value={value.brandId}
        readOnly
      />
      <DataSelect
        label="Product type"
        type="select"
        buttons={false}
        options={[
          { value: '', label: 'Select a product type' },
          ...(productTypes || [])?.map((productType) => ({
            value: productType.id,
            label: productType.name,
          })),
        ]}
        value={value.productTypeId}
        readOnly
      />
      <DataSelect
        label="Available on Draught"
        type="select"
        options={[
          { label: 'No', value: false, Type: 'boolean' },
          { label: 'Yes', value: true, Type: 'boolean' },
        ]}
        value={value.availableOnDraught}
        readOnly
      />
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Taste and style
        </Form.Label>
        <Col sm="10">
          <ToggleButtonGroup
            style={{ flexWrap: 'wrap' }}
            type="checkbox"
            value={value.productTypeVariants}
          >
            {productTypeVariants?.map((productTypeVariant) => (
              <ToggleButton
                key={productTypeVariant.id}
                variant="outline-secondary"
                value={productTypeVariant.id}
              >
                {productTypeVariant.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Suitable for
        </Form.Label>
        <Col sm="10">
          <ToggleButtonGroup
            style={{ flexWrap: 'wrap' }}
            type="checkbox"
            value={value.suitableFor}
          >
            {dietTypes?.map((dietType) => (
              <ToggleButton
                key={dietType.id}
                variant="outline-secondary"
                value={dietType.id}
              >
                {dietType.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
      </Form.Group>
      <DataSelect
        label="Country"
        type="select"
        buttons={false}
        options={[
          { value: '', label: 'Select a country' },
          ...(countries || [])?.map((country) => ({
            value: country.id,
            label: country.name,
          })),
        ]}
        value={value.countryId}
        readOnly
      />
      <DataInput
        label="Description"
        as="textarea"
        placeholder=""
        value={value.description}
        readOnly
      />
      <DataInput
        label="Calories per 100ml"
        placeholder="100"
        value={value.caloriesPerServing}
        readOnly
      />
      <DataInput
        label="Product size in ml"
        placeholder="300"
        value={value.servingMl}
        readOnly
      />
      <DataInput label="ABV %" placeholder="0" value={value.abv} readOnly />
      <ImageSelector label="Image" value={value.imageUrl} readOnly />
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Food pairing
        </Form.Label>
        <Col sm="10">
          <ToggleButtonGroup
            className="flex-wrap"
            type="checkbox"
            value={value.foodPairings}
          >
            {foodTypes?.map((foodType) => (
              <ToggleButton
                key={foodType.id}
                variant="outline-secondary"
                value={foodType.id}
              >
                {foodType.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
      </Form.Group>
      <DataInput
        label="Ingredients"
        as="textarea"
        placeholder=""
        value={value.ingredients}
        readOnly
      />
      <DataInput
        label="Awards"
        as="textarea"
        placeholder=""
        value={value.awards}
        readOnly
      />
      <DataInput
        label="Other"
        as="textarea"
        placeholder=""
        value={value.other}
        readOnly
      />
      <DataSearch
        label="Online Stockists"
        url="/portal/retailers"
        value={value.suggestedStockists}
        onChange={() => {}}
        readOnly
      />

      <Button variant="primary" type="button" className="mr-3 border-orange-br bg-orange-br" onClick={onDeny}>
        {loadingValue === 'deny' ? (
          <Spinner animation="border" size="sm" />
        ) : (
          'Deny'
        )}
      </Button>

      <Button
        variant="primary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        onClick={onApprove}
      >
        {loadingValue === 'approve' ? (
          <Spinner animation="border" size="sm" />
        ) : (
          'Approve'
        )}
      </Button>
    </Form>
  );
};
