import { initializeApp } from 'firebase/app';
import { appConfig } from '../modules/common/app.config';

let firebaseApp;
export const getFirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(appConfig.firebase);
  }
  return firebaseApp;
};
