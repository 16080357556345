import React from 'react';
import Form from 'react-bootstrap/Form';
import omit from 'lodash/omit';
import { Field, useFormikContext } from 'formik';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import { DrinksMenuProductSelector } from './DrinksMenuProductSelector';

export const DrinksMenuProductList = () => {
  const { values, setFieldValue } = useFormikContext();
  const productIds = Object.keys(values?.products);
  const { data } = useSWR(
    productIds
      ? `/portal/drinks-menus/products?id=${productIds.join(',')}`
      : null,
    fetcher
  );

  return (
    <div>
      {!productIds?.length ? (
        <Form.Control
          readOnly={true}
          type="text"
          plaintext
          defaultValue="None"
        />
      ) : (
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Available on Draught?</th>
              <th width="15" />
            </tr>
          </thead>
          <tbody>
            {data?.map((product) => {
              return (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>
                    <Field name={`products.${product.id}.price`}>
                      {({ field, meta }) => (
                        <Form.Control
                          as="input"
                          type="number"
                          step="any"
                          style={{ width: '110px' }}
                          {...field}
                          isInvalid={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </td>
                  <td>
                    {product?.availableOnDraught && (
                      <Field
                        type="checkbox"
                        name={`products.${product.id}.servedOnDraught`}
                      >
                        {({ field }) => (
                          <Form.Control as="input" type="checkbox" {...field} />
                        )}
                      </Field>
                    )}
                  </td>
                  <td width="15">
                    <Button
                      variant="outline-danger"
                      type="button"
                      className="border-orange-br bg-orange-br text-white"
                      onClick={() => {
                        setFieldValue(
                          'products',
                          omit(values?.products, [product.id])
                        );
                      }}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <DrinksMenuProductSelector
        onSelect={(product) => {
          setFieldValue('products', { ...values?.products, [product.id]: {} });
        }}
      />
    </div>
  );
};
