import { client } from '../../common/api/client';
import {
  formValuesToCreateCompanyDto,
  formValuesToUpdateCompanyDto,
} from './companies.dto';

export const updateCompany = (id, values) => {
  const updateCompanyDto = formValuesToUpdateCompanyDto(values);
  return client.patch(`/portal/companies/${id}`, updateCompanyDto);
};

export const createCompany = (values) => {
  const createCompanyDto = formValuesToCreateCompanyDto(values);
  return client.post('/portal/companies', createCompanyDto);
};

export const deleteCompany = (id) => {
  return client.delete(`/portal/companies/${id}`);
};
