import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { fetcher } from '../../common/api/fetcher';
import React, { Suspense, useEffect, useState } from 'react';
import { Formik } from 'formik';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { BrandForm, validateForm } from '../components/BrandForm';
import { brandsApiResponseToFormValues } from '../api/brands.dto';
import { deleteBrand, updateBrand } from '../api/brands.api';
import useScript from 'react-script-hook';
import { appConfig } from '../../common/app.config';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

export const EditBrandPage = () => {
  const history = useHistory();
  const { id: brandId } = useParams();
  const [scriptLoading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${appConfig.googleMaps.apiKey}&libraries=places`,
    checkForExisting: true,
  });
  const {
    data,
    error,
    isValidating: loading,
  } = useSWR(!scriptLoading ? `/portal/brands/${brandId}` : null, fetcher);
  const [brand, setBrand] = useState(null);
  const [errorBox, setErrorBox] = useState(noError);

  const handleSubmit = async (values) => {
    try {
      await updateBrand(brandId, values);
      await mutate(`/portal/brands`);
      history.push('/brands/list');
    } catch (e) {
      console.log(e);
      setErrorBox({
        title: 'An error occurred saving this brand',
        msg: 'Please try again',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteBrand(brandId);
      await mutate(`/portal/brands/${brandId}`);
      await mutate(`/portal/brands`);
    } catch (e) {
      if (e.response.status === 409) {
        setErrorBox({
          title: 'An error occurred deleting this brand',
          msg: 'This brand has associated products, please delete them and try again',
        });
        return;
      }

      setErrorBox({
        title: 'An error occurred deleting this brand',
        msg: 'Please try again',
      });
    }
  };

  useEffect(() => {
    const run = async () => {
      const _brand = await brandsApiResponseToFormValues(data);
      setBrand(_brand);
    };
    if (data) {
      run();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorBox({
        title: 'Error loading location',
        msg: 'Please try again',
      });
    }
  }, [error]);

  return (
    <PageFrame title="Edit Brand">
      {loading && <p>Loading...</p>}
      {error && <p>Error! {error}</p>}
      {brand && (
        <Formik
          initialValues={brand}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <BrandForm onDelete={handleDelete} />
          </Suspense>
        </Formik>
      )}
      <ErrorBox
        title={errorBox.title}
        msg={errorBox.msg}
        onClose={() => setErrorBox(noError)}
      />
    </PageFrame>
  );
};
