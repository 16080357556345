import React, { Suspense, useState } from 'react';
import { UserReviewsDataList } from '../components/UserReviewsDataList';
import { ListSearchAndLimit } from '../../common/components/ListSearchAndLimit';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

export const UserReviewsListPage = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  return (
    <PageFrame title="User Reviews">
      <ListSearchAndLimit
        limit={limit}
        setLimit={setLimit}
        onSearch={() => {}}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <UserReviewsDataList
          offset={offset}
          limit={limit}
          setOffset={setOffset}
        />
      </Suspense>
    </PageFrame>
  );
};
