import React, { Suspense, useState } from 'react';
import classNames from 'classnames';
import useSWR from 'swr';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import { BrandEditsDataList } from '../components/BrandEditsDataList';
import { ProductEditsDataList } from '../components/ProductEditsDataList';
import { PlaceEditsDataList } from '../components/PlaceEditsDataList';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { PageFrame } from '../../common/components/PageFrame';

const tabs = [
  {
    name: 'Brands',
    key: 'brands',
    href: '/edits/brands',
  },
  {
    name: 'Products',
    key: 'products',
    href: '/edits/products',
  },
  {
    name: 'Places',
    key: 'places',
    href: '/edits/places',
  },
];

export const EditsListPage = () => {
  const [currentTab, setCurrentTab] = useState('brands');
  const {
    data: countData,
    error: countError,
    revalidate,
  } = useSWR(`/portal/edits`, fetcher, {
    suspense: true,
  });

  if (countError) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching brands, please try again"
        onClose={revalidate}
      />
    );
  }

  return (
    <PageFrame title="Approvals">
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
            defaultValue={tabs.find((tab) => tab.key === currentTab).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setCurrentTab(tab.key)}
                  className={classNames(
                    tab.key === currentTab
                      ? 'border-orange-br text-orange-br'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                    'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.key === currentTab ? 'page' : undefined}
                >
                  {tab.name}
                  {countData ? (
                    <span
                      className={classNames(
                        tab.key === currentTab
                          ? 'bg-gray-100 text-gray-600'
                          : 'bg-gray-100 text-gray-900',
                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                      )}
                    >
                      {`${countData[tab.key]}`}
                    </span>
                  ) : null}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <Suspense fallback={<LoadingSpinner />}>
        {currentTab === 'brands' && <BrandEditsDataList />}
        {currentTab === 'products' && <ProductEditsDataList />}
        {currentTab === 'places' && <PlaceEditsDataList />}
      </Suspense>
    </PageFrame>
  );
};
