import { useHistory, useLocation } from 'react-router-dom';

export function useQuery() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const set = (key, value) => {
    query.set(key, value);
    history.push(`${location.pathname}?${query.toString()}`);
  };

  const remove = (key) => {
    query.delete(key);
    history.push(`${location.pathname}?${query.toString()}`);
  };

  const get = (key) => query.get(key);

  return { set, get, remove };
}
