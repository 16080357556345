import { useEffect, useState } from 'react';

export const useFormSteps = (initialStep, stepsConfig) => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  const goToNextStep = () => {
    const currentIndex = stepsConfig.findIndex(
      (step) => step.id === currentStep.id
    );
    if (currentIndex < stepsConfig.length - 1) {
      setCurrentStep(stepsConfig[currentIndex + 1]);
    }
  };

  const goToPreviousStep = () => {
    const currentIndex = stepsConfig.findIndex(
      (step) => step.id === currentStep.id
    );
    if (currentIndex > 0) {
      setCurrentStep(stepsConfig[currentIndex - 1]);
    }
  };

  const resetSteps = () => {
    setCurrentStep(initialStep);
  };

  return {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    resetSteps,
  };
};
