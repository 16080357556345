import Table from 'react-bootstrap/Table';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { fetcher } from '../../common/api/fetcher';
import ErrorBox from '../../common/components/ErrorBox';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';
import { NoResults } from '../../common/components/NoResults';

export const DrinksMenuDataList = ({ offset, limit, query, setOffset }) => {
  const history = useHistory();
  const { data, error, revalidate } = useSWR(
    `/portal/drinks-menus?${stringify({
      offset,
      limit,
      ...(query !== '' && { query }),
    })}`,
    fetcher,
    { suspense: true }
  );

  if (error) {
    return (
      <ErrorBox
        title="Error"
        msg="There was an error fetching drinks menus, please try again"
        onClose={revalidate}
      />
    );
  }

  if (data?.length === 0) {
    return <NoResults />;
  }

  return (
    <div>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>
              <strong>Menu name</strong>
            </td>
            <td>
              <strong>Last updated</strong>
            </td>
          </tr>
          {data.map((item) => (
            <tr key={item.id} className="cursor-pointer">
              <td onClick={() => history.push(`/drinks-menus/edit/${item.id}`)}>
                {item.name}
              </td>
              <td>
                <p>{format(new Date(item.updatedAt), 'dd-MM-yyyy')}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant="secondary"
        type="button"
        className="mr-3 border-orange-br bg-orange-br"
        disabled={offset === 0}
        onClick={() => setOffset(offset - limit)}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        type="button"
        className="border-orange-br bg-orange-br"
        onClick={() => setOffset(offset + limit)}
      >
        Next
      </Button>
    </div>
  );
};
