import React, { useContext, useState } from 'react';
import ErrorBox, { noError } from '../../common/components/ErrorBox';
import { Formik } from 'formik';
import { ProductForm, validateForm } from '../components/ProductForm';
import { createProduct } from '../api/products.api';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../common/context/userContext';
import { PageFrame } from '../../common/components/PageFrame';

export const AddProductPage = () => {
  const history = useHistory();
  const [error, setError] = useState(noError);
  const { companyId, isAdmin } = useContext(UserContext);

  const handleSubmit = async (values) => {
    try {
      await createProduct(values);
      await mutate('/portal/products');
      history.push('/products/list');
    } catch (e) {
      console.log(e);
      setError({
        title: 'An error occurred saving this product',
        msg: 'Please try again',
      });
    }
  };

  return (
    <PageFrame title="Add Product">
      <Formik
        initialValues={{ ...(!isAdmin && { company: { id: companyId } }) }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        <ProductForm />
      </Formik>
      <ErrorBox
        title={error.title}
        msg={error.msg}
        onClose={() => setError(noError)}
      />
    </PageFrame>
  );
};
